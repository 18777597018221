import  style from "./customButton.module.css"
import "../../dashboardIndex.css"


const CustomButton = ({children, onClick, icon}) => {
  return (
    <div className={`${style.customButton} transitionAnimation`}>
        {icon}
        <button style={{textTransform:"capitalize"}} onClick={onClick}>{children}</button>
    </div>
  )
}

export default CustomButton