import PackageWrap from "./PackageWrap";
import img7 from "../../images/img7.jpg";
import img8 from "../../images/gallery-8.jpg";
import img9 from "../../images/El-Moez-Street.jpg";
import img6 from "../../images/img6.jpg";
import img14 from "../../images/img14.jpg";
import karnak from "../../images/karnak.jpeg";
import luxorTrip from "../../images/Luxor-Trips-in-Egypt.jpg";
import camelTrip from "../../images/camel-trip.jpg";
import Saqqara from "../../images/Saqqara.jpg";
import img12 from "../../images/khan.jpg";
import { useQuery } from "@tanstack/react-query";
import { api_url } from "../../constants/base_url";
import axios from "axios";
import Loading from "../shared/Loading/Loading";
import { useParams } from "react-router-dom";

const PackageSection = () => {

  const { destinationId } = useParams();

  const { data, isLoading } = useQuery({
    queryKey: ["destinationTours",destinationId], // same key
    queryFn: () =>
      axios.get(`${api_url}tour${destinationId?`?destination_id=${destinationId}`:""}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
  });

  return (
    <div className="package-section">
      <div className="container">
        <div className="package-inner packages">
          <div className="row">
            {isLoading ? (
              <div className="col-lg-4 col-md-6">
                <Loading />
              </div>
            ) : (
              data?.data?.data?.data.map((pkg) => (
                <div className="col-lg-4 col-md-6" key={pkg.id}>
                  <PackageWrap {...pkg} />
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageSection;
