import style from "./pageHeader.module.css"

const PageHeader = ({children, description}) => {
  return (
    <>
    <h2 className={style.header}>{children}</h2>
    <p>{description}</p>
    </>
  )
}

export default PageHeader