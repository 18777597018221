import React, { useEffect, useRef, useState } from 'react'
import SectionHeading from '../../../../components/sectionheading';
import CustomButton from '../../../../components/shared/customButton';
import { useHomeBlog } from '../../../../context/HomeBlogContext';
import icon5 from "../../../../images/icon5.png";
import CounterItem from '../../../../components/AboutComponents/CounterItem';
import { Link } from 'react-router-dom';

const CallbackSection = () => {
  const {lang,blog, addSection, updateSection} = useHomeBlog()
  const [content, setContent] = useState([])
  const [title, setTitle] = useState("")

  useEffect(() => {
    if(blog?.sub_card_4){
      setContent(blog?.sub_card_4?.content?.split("-.-"))
      setTitle(blog?.sub_card_4?.title)
    }else{
      setContent([])
      setTitle("")
    }
  },[blog, lang])
  const updateCallbackSection = () => {
    if(blog){
      updateSection({
        blogLanguageId: lang.id,
        index: 4,
        content: content.join("-.-"),
        title: title,
        _method: "PUT"
      })
    }
  }
  const addCallbackSections = () => {
    addSection({
      blogLanguageId: lang.id,
      title: "Callback for more",
      content: ["GO TRAVEL. DISCOVER. REMEMBER US!!",
        "We are committed to curate and flawlessly execute travel experiences that allow travelers to explore the world with ease and create memories that last a lifetime",
        "500K+",
        "Satisfied Clients",
        "250K+",
        "Satisfied Clients",
        "15K+",
        "Satisfied Clients",
        "10K+",
        "Satisfied Clients",
        "Our 24/7 Emergency Phone Services",
        "Call",
        "+201033973047"
      ].join("-.-"),
    })
  }
  return (
    <div style={{width:"100%", outline:"4px dashed #D51C29", marginBottom:"20px", padding:"20px"}}>
            {blog?.sub_card_4?<>
              <div className="callback-inner" style={{backgroundColor:"#0791BE", marginBottom:"40px"}}>
                <div className="section-heading section-heading-white">
                  <input className="dash-style" style={{backgroundColor:"transparent", border:"none", outline:"none", textTransform:"uppercase", fontSize:"18px", width:"100%"}} value={title} onChange={(e) => {
                    setTitle(e.target.value)
                  }}/>
                  <input type='text' 
                    style={{backgroundColor:"transparent", border:"none", outline:"none", color:"#fff", fontSize:"48px", fontWeight:"900", display:"block", width:"100%"}} 
                    value={content[0]}
                    onChange={(e) => {
                      const newContent = [...content]
                      newContent[0] = e.target.value
                      setContent(newContent)
                    }}
                    />
                  <textarea style={{backgroundColor:"transparent", border:"none", outline:"none", fontSize:"16px", color:"white"}}
                    value={content[1]}
                    onChange={(e) => {
                      const newContent = [...content]
                      newContent[1] = e.target.value
                      setContent(newContent)
                    }}
                    />
                </div>
                <div className="callback-counter-wrap">
                  {Array(4).fill(0).map((_,i) => <div>
                    <input type='text' value={content[i*2+2]} style={{backgroundColor:"transparent", border:"none", outline:"none", color:"#fff", fontSize:"48px", fontWeight:"900", display:"block", width:"100%"}} onChange={(e) => {
                      const newContent = [...content]
                      newContent[i*2+2] = e.target.value
                      setContent(newContent)
                    }}/>
                    <input type='text' value={content[i*2+3]} style={{backgroundColor:"transparent", border:"none", outline:"none", color:"#fff", fontSize:"16px", display:"block", width:"100%"}} onChange={(e) => {
                      const newContent = [...content]
                      newContent[i*2+3] = e.target.value
                      setContent(newContent)
                    }}/>
                  </div>)}
                </div>
                <div className="support-area">
              <div className="support-icon">
                <img src={icon5} alt="" />
              </div>
              <div className="support-content" style={{minWidth:"500px"}}>
                <input type='text' value={content[10]} style={{backgroundColor:"transparent", border:"none", outline:"none", color:"#101f46", fontSize:"16px", fontWeight:"700", display:"block", width:"100%"}}
                  onChange={(e) => {
                    const newContent = [...content]
                    newContent[10] = e.target.value
                    setContent(newContent)
                  }}
                />
                    <input type='text' value={content[11]} style={{backgroundColor:"transparent", border:"none", outline:"none", color:"#d51c29", fontSize:"32px", fontWeight:"900", display:"block", width:"100%"}} 
                      onChange={(e) => {
                        const newContent = [...content]
                        newContent[11] = e.target.value
                        setContent(newContent)
                      }}
                    />
                    <input type='text' value={content[12]} style={{backgroundColor:"transparent", border:"none", outline:"none", color:"#d51c29", fontSize:"32px", fontWeight:"900", display:"block", width:"100%"}} 
                      onChange={(e) => {
                        const newContent = [...content]
                        newContent[12] = e.target.value
                        setContent(newContent)
                      }}
                    />
                  </div>
                </div>
              </div>
              <input
                type="submit"
                name="travel-search"
                value="Save"
                style={{margin:"auto"}}
                onClick={updateCallbackSection}
              />
            </>:<input
                  type="submit"
                  name="travel-search"
                  value="Add Callback Section"
                  style={{margin:"auto", display:"block",}}
                  onClick={addCallbackSections}
                />}
            
      </div>
  )
}

export default CallbackSection