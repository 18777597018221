import IneerBanner from "../components/IneerBanner";

export default function TermsAndConditions() {
  return (
    <>
      <IneerBanner bannerName={"Terms And Conditions"} />

      <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
        <h2>Effective Date: 1/1/2024</h2>

        <h3>1. Introduction</h3>
        <p>
          Welcome to Album Travel. These Terms and Conditions govern your use of
          our website and services. By accessing or using our site, you agree to
          comply with and be bound by these terms. If you do not agree, please
          do not use our services.
        </p>

        <h3>2. Services</h3>
        <p>
          Album Travel provides travel booking and related services. All
          bookings and transactions are subject to availability and
          confirmation.
        </p>

        <h3>3. Booking and Payment</h3>
        <p>
          <strong>Booking:</strong> To book a trip, you must provide accurate
          and complete information. You are responsible for verifying all
          details before confirming your booking.
        </p>
        <p>
          <strong>Payment:</strong> Full payment is required at the time of
          booking unless otherwise stated. We accept various forms of payment as
          specified on our website.
        </p>

        <h3>4. Cancellation and Refunds</h3>
        <p>
          <strong>Cancellation by You:</strong> If you need to cancel your
          booking, please contact us immediately. Cancellation policies and fees
          vary depending on the service and supplier. Please review our
          cancellation policy on our website or your booking confirmation.
        </p>
        <p>
          <strong>Cancellation by Us:</strong> We reserve the right to cancel
          your booking in case of unforeseen circumstances. In such cases, we
          will offer a full refund or an alternative arrangement.
        </p>

        <h3>5. Changes to Bookings</h3>
        <p>
          If you need to make changes to your booking, contact us as soon as
          possible. Changes are subject to availability and may incur additional
          fees.
        </p>

        <h3>6. Travel Documents</h3>
        <p>
          You are responsible for ensuring that you have valid travel documents,
          including passports, visas, and any other required documentation.
          Album Travel is not responsible for any issues arising from incomplete
          or invalid travel documents.
        </p>

        <h3>7. Liability</h3>
        <p>
          <strong>Limitation of Liability:</strong> Album Travel is not liable
          for any direct, indirect, incidental, or consequential damages arising
          from your use of our services or inability to use our services.
        </p>
        <p>
          <strong>Third-Party Suppliers:</strong> We work with third-party
          suppliers to provide travel services. Album Travel is not responsible
          for any actions or omissions of these suppliers.
        </p>

        <h3>8. User Conduct</h3>
        <p>By using our website, you agree not to:</p>
        <ul>
          <li>Violate any applicable laws or regulations.</li>
          <li>Engage in any fraudulent or deceptive activities.</li>
          <li>Interfere with the operation of our website or services.</li>
          <li>Post or transmit any harmful or offensive content.</li>
        </ul>

        <h3>9. Intellectual Property</h3>
        <p>
          All content on our website, including text, graphics, logos, and
          images, is the property of Album Travel or its licensors and is
          protected by intellectual property laws. You may not use, reproduce,
          or distribute any content without our permission.
        </p>

        <h3>10. Privacy Policy</h3>
        <p>
          Your use of our website is also governed by our Privacy Policy, which
          can be found{" "}
          <a
            href="link to privacy policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </p>

        <h3>11. Changes to Terms</h3>
        <p>
          We may update these Terms and Conditions from time to time. Any
          changes will be posted on this page with an updated effective date.
          Your continued use of our website and services constitutes acceptance
          of the updated terms.
        </p>

        <h3>Contact Us</h3>
        <p>
          If you have any questions or concerns about this Privacy Policy,
          please contact us:
        </p>
        <p>
          <strong>Phone:</strong> 1033973047
          <br />
          <strong>Email:</strong> info@albumtravel.com
          <br />
          <strong>Website:</strong>{" "}
          <a
            href="http://www.albumtravel.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.albumtravel.com
          </a>
        </p>

        <p>
          Thank you for trusting Album Travel with your travel plans and
          personal information. We are committed to ensuring your privacy and
          security.
        </p>
      </div>
    </>
  );
}
