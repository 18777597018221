import emptyImg from "../../images/empty.png"
import style from "./empty.module.css"

const Empty = ({ text }) => {
    return (
        <div className={style.container}>
            <img src={emptyImg} alt='empty' />
            <h1>{text}</h1>
        </div>
    )
}

export default Empty