const SectionHeading = ({ heading, subheading, description1, description2 }) => (
  <div className="section-heading">
    <div className="row align-items-end">
      <div className="col-lg-6">
        <h5 className="dash-style">{heading}</h5>
        <h2>{subheading}</h2>
      </div>
      <div className="col-lg-6">
        <div className="section-disc">
          <p>{description1}</p>
          <p>{description2}</p>
        </div>
      </div>
    </div>
  </div>
);

export default SectionHeading;
