import GallerySlider from "./GallerySlider"; // Assuming you create a GallerySlider component
import SingleTourContent from "./SingleTourContent";
import Sidebar from "./Sidebar";

const SingleTour = () => {
  return (
    <div className="single-tour-section">
      <div className="container mb-5">
        <div className="row">
          <div className="col-lg-8">
            {/* Content left side */}
            <SingleTourContent />
            <GallerySlider />
          </div>
          <div className="col-lg-4">
            {/* Sidebar right side */}
            <Sidebar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleTour;
