import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { api_url } from "../../constants/base_url";

function ImagesSlider({ images }) {
  const settings = {
    customPaging: function (i) {
      return (
        <a>
          <img
            src={`${api_url}${images[i]}`.replace("/api/", "/storage/")}
            alt={`thumbnail-${i}`}
            style={{ height: "70px", width: "100px", objectFit: "cover" }}
          />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="slider-container" style={{ width: "60vw", height: "75vh" }}>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} style={{ backgroundColor: `url(${image})` }}>
            <img
              src={`${api_url}${images}`.replace("/api/", "/storage/")}
              alt={`slide-${index}`}
              style={{ height: "65vh", width: "100%", objectFit: "cover" }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default ImagesSlider;
