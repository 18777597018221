import React, { useEffect, useState } from 'react'
import { useHomeBlog } from '../../../context/HomeBlogContext'
import { api_url } from '../../../constants/base_url';
import CarouselSection from './Sections/CarouselSection';
import ToursSection from './Sections/ToursSection';
import DestinationsSection from './Sections/DestinationsSection';
import SecondToursSection from './Sections/SecondToursSection';
import { FaTrashAlt } from "react-icons/fa";
import { IoMdAddCircle } from "react-icons/io";
import CallbackSection from './Sections/CallbackSection';


const DashboardHomeContent = () => {
  const {langs, lang, setLang, blog,addLang, deleteLang, allLanguages} = useHomeBlog()
  const [language, setLanguage] = useState("")
  const [carouselItems, setCarouselItems] = useState(null)
  useEffect(() => {
    if(blog){
      setCarouselItems(blog?.sub_card_1?.content?.split("-.-").map((item, index) => {return {content: item, image: `${api_url}${blog?.sub_card_1?.image?.[index]}`.replace("/api/", "/storage/")}}))
    }
  },[blog])
  console.log(blog?.sub_card_1)
  return (
    <div style={{padding:"20px"}}>
      <h1>Home Content</h1>
      <div style={{display:"flex", alignItems:"center",justifyContent:"space-between", gap:"10px", width:"100%", marginBottom:"20px"}}>
      <div style={{display:"flex",alignItems:"center", justifyContent:"center", gap:"15px", height:"40px", margin:"10px 0"}}>
      <select onChange={(e) => {
        setLang(JSON.parse(e.target.value))
      }}>
        {langs.map(lang => (
          <option key={lang.id} value={JSON.stringify(lang)}>{lang.name}</option>
        ))}
      </select>
      {lang?.name !== "en" && <button onClick={() => {
          deleteLang(lang.id)
        }} style={{width:"30px", height:"30px", border:"none", outline:"none", fontSize:"0.8rem", backgroundColor:"#d51c29", display:"flex", alignItems:"center", justifyContent:"center", color:"white", borderRadius:"6px"}}>
          <FaTrashAlt />
        </button>}
      </div>
        <div style={{display:"flex", alignItems:"center", justifyContent:"center", gap:"15px", height:"40px", margin:"10px 0"}}>
        <select onChange={(e) => {
            setLanguage(e.target.value)
          }}>
            {allLanguages?.filter(lang => !langs.some(l => l.name === lang)).map(lang => (
            <option key={lang} value={lang}>{lang}</option>
            ))}
          </select>
            <button disabled={!language} style={{width:"40px",height:"40px", border:"none", outline:"none", fontSize:"1.6rem", backgroundColor:"#d51c29", display:"flex", alignItems:"center", justifyContent:"center", color:"white", borderRadius:"12px"}} onClick={() => {
                addLang({language:language, description:language, content:language, title:language})
            }}><IoMdAddCircle /></button>
        </div>
      </div>
      
      <CarouselSection
        carouselItems={carouselItems}
        setCarouselItems={setCarouselItems}
      />
      {blog?.sub_card_1 && <ToursSection/>}
      {blog?.sub_card_2 && <DestinationsSection/>}
      {blog?.sub_card_1 && <SecondToursSection/>}
      {blog?.sub_card_3 && <CallbackSection/>}
    </div>
  )
}

export default DashboardHomeContent