import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FaTrashAlt } from "react-icons/fa";
import { notifyError, notifySuccess } from '../../../../App';
import axios from 'axios';
import { api_url } from '../../../../constants/base_url';


const DashboardLanguageItem = ({lang}) => {
  const queryClient = useQueryClient()
  const {mutate } = useMutation({
    mutationFn: (data) => axios.delete(`${api_url}language/${lang.name}`,data, {
      headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
  }),
    onSuccess: () => {
      notifySuccess("Language deleted successfully!")
      queryClient.invalidateQueries(["allLanguages"])
    },
    onError: () => {
      notifyError("Something went wrong")
    }
  })
  return (
    <div style={{display:"flex",alignItems:"center", justifyContent:"space-between", padding:"0 5px"}}>
        <p>{lang?.name}</p>
        {lang?.name !== "en" && <button onClick={() => {
          mutate()
        }} style={{width:"30px", height:"30px", border:"none", outline:"none", fontSize:"0.8rem", backgroundColor:"#d51c29", display:"flex", alignItems:"center", justifyContent:"center", color:"white", borderRadius:"6px"}}>
          <FaTrashAlt />
        </button>}
    </div>
  )
}

export default DashboardLanguageItem