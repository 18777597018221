import { useEffect, useState } from 'react';
import Form from '../../../../components/shared/Form/Form';
import { IoHourglassOutline } from "react-icons/io5";
import { IoEarth } from "react-icons/io5";
import { IoPeople } from "react-icons/io5";
import { IoCalendarOutline } from "react-icons/io5";
import style from "./Overview.module.css"
import EditOverviewCard from '../editOverviewCard/editOverviewCard';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { api_url } from '../../../../constants/base_url';
import { notifyError, notifySuccess } from '../../../../App';
import { useForm } from 'react-hook-form';
import Loading from '../../../../components/shared/Loading/Loading';
import { COLORS } from '../../../../constants/colors';

const Overview = ({lang, data,edit }) => {
  const [content,setContent]=useState([])
  const [overviewCard, setOverviewCard] = useState(null); // Store the matching object
  const [cardIndex, setCardIndex] = useState(null); // Store the index if found
    const {
        register,
        getValues,
        setValue,
        formState: { errors },
        handleSubmit,
      } = useForm();

     


    const { mutate,  isPending } = useMutation({
        mutationFn: (data) => {
          const url = overviewCard
          ? `${api_url}blogLanguage/updateSubCard`
          : `${api_url}blogLanguage/subCard`;
    
        return axios.post(url, data, {
          headers: {
            "Accept": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
      },
        onSuccess: (data) => {
            console.log("sub card add successful", data);
            notifySuccess("sub card add successful")
            refetch()
        },
        onError: (error) => {
            console.error("add card failed:", error);
            notifyError(error?.response?.data?.message);
        }
    });
    
      const { data:subCard, isLoading ,refetch} = useQuery({
        queryKey: ["subCard", lang],
        queryFn: () =>
          axios.get(`${api_url}blogLanguage/${lang}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }),
      })
      const submit = (data) => {
        const payload = {
            blogLanguageId: lang, // Include any additional data required
            title:"Overview",
            content: JSON.stringify({ ...getValues() }), 
            ...(overviewCard && { _method: "PUT" ,index:cardIndex}),
        };
        mutate(payload); 
      };

      
     
    
      useEffect(() => {
        if (subCard?.data?.data) {
          Object.entries(subCard.data.data).forEach(([key, value], index) => {
            if (key.startsWith('sub_') && value?.title === 'Overview') {
              setOverviewCard(value); // Set the matching object in state
              setCardIndex(key.slice(-1)); // Set the index in state
            }
          });
        }
      }, [subCard, isLoading]);
      

      useEffect(() => {
        if (overviewCard) {
          const parsedContent = JSON.parse(overviewCard.content);
          setContent(parsedContent);
    
          // Set form values using setValue
          Object.entries(parsedContent).forEach(([key, value]) => {
            setValue(key, value);
          });
        }
      }, [overviewCard, setValue]);
      
      return edit ? (
        <div className={style.editOverview}>
         <Form
            onSubmit={handleSubmit(submit)}
          >
            <div className={style.cardsContainer}>
              <div>
                <p>Schedule</p>
                <Form.TextController
                   name="scheduleDays"
                  register={register}
                  registername="scheduleDays"
                  // value={content?.scheduleDays}
                  errorMessage={errors?.ScheduleDays && errors?.ScheduleDays?.message}
                  validationRules={{
                    required: 'Schedule Days is required',
                  }}
                  placeholder="Schedule Days"
                />
                <Form.TextController
                  name="scheduleNights"
                  register={register}
                  registername="scheduleNights"
                  // value={content?.scheduleNights}
                  errorMessage={errors?.ScheduleDays && errors?.ScheduleDays?.message}
                  validationRules={{
                    required: 'Schedule Nights is required',
                  }}
                  placeholder="Schedule Nights"
                />
              </div>
              <div>
                <p>Countries</p>
                <Form.TextController
                  name="numberOfCountries"
                  register={register}
                  registername="numberOfCountries"
                  // value={content?.numberOfCountries}
                  errorMessage={errors?.numberOfCountries && errors?.numberOfCountries?.message}
                  validationRules={{
                    required: 'number Of Countries  is required',
                  }}
                  placeholder="number Of Countries"
                />
                <Form.TextController
                  name="numberOfCities"
                  register={register}
                  registername="numberOfCities"
                  // value={content?.numberOfCities}
                  errorMessage={errors?.ScheduleDays && errors?.ScheduleDays?.message}
                  validationRules={{
                    required: 'number Of Cities is required',
                  }}
                  placeholder="Number of Cities"
                />
              </div>
              <div>
                <p>Type</p>
                <Form.TextController
                  name="groupType"
                  register={register}
                  registername="groupType"
                  // value={content?.groupType}
                  errorMessage={errors?.groupType && errors?.groupType?.message}
                  validationRules={{
                    required: 'group Type is required',
                  }}
                  placeholder="Group Type"
                />
                <Form.TextController
                  name="numberOfPersons"
                  register={register}
                  registername="numberOfPersons"
                  // value={content?.numberOfPersons}
                  errorMessage={errors?.numberOfPersons && errors?.numberOfPersons?.message}
                  validationRules={{
                    required: 'number Of Persons is required',
                  }}
                  placeholder="Number of Persons"
                />
              </div>
              <div>
                <p>Run</p>
                <Form.TextController
                  name="tourRunFrom"
                  register={register}
                  registername="tourRunFrom"
                  // value={content?.tourRunFrom}
                  errorMessage={errors?.tourRunFrom && errors?.tourRunFrom?.message}
                  validationRules={{
                    required: 'tour Run From is required',
                  }}
                  placeholder="Tour Run From"
                />
                <Form.TextController
                  name="tourRunTo"
                  register={register}
                  registername="tourRunTo"
                  // value={content?.tourRunTo}
                  errorMessage={errors?.tourRunTo && errors?.tourRunTo?.message}
                  validationRules={{
                    required: 'tour Run To is required',
                  }}
                  placeholder="Tour Run To"
                />
              </div>
            </div>
            <Form.TextareaController
              name="overviewDescription"
                  register={register}
                  registername="overviewDescription"
                  // value={content?.overviewDescription}
                  errorMessage={errors?.overviewDescription && errors?.overviewDescription?.message}
                  validationRules={{
                    required: 'overview Description is required',
                  }}
              placeholder="Overview Description"
            />
            <Form.ButtonController type="submit" disabled={isPending}>
              {isPending ? <Loading color={COLORS.DANGER}/> : 'Save Changes'}
            </Form.ButtonController>
          </Form>
        </div>
      ) : (
        <>
          <div className={style.cardsContainer}>
            <EditOverviewCard
              icon={<IoHourglassOutline />}
              text="Schedule"
              subText={(content?.scheduleDays||"")+" days"}
              details={(content?.scheduleNights||"") +' Nights'}
            />
            <EditOverviewCard
              icon={<IoEarth />}
              text="Countries"
              subText={(content?.numberOfCountries||"") +" Country"}
              details={(content?.numberOfCities||"") +" Cities"}
            />
            <EditOverviewCard
              icon={<IoPeople />}
              text="Type"
              subText="Small group tour"
              details={content?.numberOfPersons||""}
            />
            <EditOverviewCard
              icon={<IoCalendarOutline />}
              text="Run"
              subText={content?.tourRunFrom||""}
              details={content?.tourRunTo||""}
            />
          </div>
          <p>
           {content?.overviewDescription||""}
          </p>
        </>
      );
    };
    


export default Overview

