import { useQuery } from "@tanstack/react-query";
import { useLanguage } from "../../context/LanguageContext";
import { api_url } from "../../constants/base_url";
import axios from "axios";

const LangFilter = () => {
  const { setLanguage } = useLanguage();

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
  };

  const { data, isLoading } = useQuery({
    queryKey: ["language"],
    queryFn: () =>
      axios.get(`${api_url}language`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
    onSuccess: (response) => {
      console.log("Data fetched successfully:", response.data);
    },
    onError: (err) => {
      console.error("Error fetching data:", err);
    },
  });
  return (
    <select
      name="travel-destination"
      onChange={handleLanguageChange}
      style={{
        border: "1px solid red",
        borderRadius: "5px",
        padding: "0.7rem 0.8rem",
        cursor: "pointer",
        appearance: "none",
        fontSize: "1.2rem",
        width: "100%", // Full width on mobile

        // Responsive styles
        maxWidth: "100%",
        margin: "0 auto",
        "@media (min-width: 768px)": {
          // Tablet screen
          fontSize: "1.1rem",
          padding: "0.6rem 0.8rem",
          width: "80%",
        },
        "@media (min-width: 1024px)": {
          // Desktop screen
          fontSize: "1.2rem",
          padding: "0.8rem 1rem",
          width: "50%",
        },
      }}
    >
      <option value="all" disabled selected>
        All Languages
      </option>

      {data?.data?.data?.map((item) => (
        <option key={item.name} value={item.name} style={{ color: "black" }}>
          {item.name}
        </option>
      ))}
    </select>
  );
};

export default LangFilter;
