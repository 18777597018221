import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Pagination from "./../../components/pagination/pagination";
import "../../dashboardIndex.css";
import toursStyle from "./dashboardTours.module.css";

import ToursCard from "../../components/toursCard/toursCard";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { api_url } from "../../../constants/base_url";
import { useForm } from "react-hook-form";
import { notifyError, notifySuccess } from "../../../App";
import Modal from "../../../components/shared/Modal/Modal";
import Form from "../../../components/shared/Form/Form";
import Loading from "../../../components/shared/Loading/Loading";
import EmptyNoItems from "../../components/emptyNoItems/emptyNoItems";
import { useSearch } from "../../../context/SearchContext";

const DashboardTours = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["tour"],
    queryFn: () =>
      axios.get(`${api_url}tour?page=${currentPage}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
    onSuccess: (response) => {
      console.log("Data fetched successfully:", response.data);
    },
    onError: (err) => {
      console.error("Error fetching data:", err);
    },
  });

  let totalPages = data?.data?.last_page || 1;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // console.log("Tour Data:", data?.data?.data)
  const {searchResults, _ } = useSearch();
  useEffect(()=>{
    if (searchResults.length>0) {
      totalPages = searchResults?.data?.last_page
    }
  },[searchResults])

  return (
    <main className="mainContainer">
      <div className={toursStyle.addTour}>
        <AddTour refetch={refetch} />
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
      {isLoading ? (
        <div className={toursStyle.loading}>
          <Loading />
        </div>
      ) :(searchResults?.data?.data.length==0 || data?.data?.data?.data?.length === 0) ? (
        <EmptyNoItems text={"No Tours Yet!"} />
      ) :searchResults.length!==0?  
      <div className={toursStyle.packagesContainer}>
      {searchResults.data.data?.map((pack) => (
        <ToursCard
          refetch={refetch}
          key={pack.id}
          id={pack.id}
          image={`${api_url}${pack.image}`.replace("/api/", "")}
          description={pack.description}
          // price={pack.price}
          // rating={pack.rating}
          review={pack.number_of_reviews}
          people={pack.number_of_people}
          tour_code={pack.tour_code}
          HasOffer={pack.HasOffer}

          // day={pack.day}
          // night={pack.night}
          // location={pack.location}
        >
          {pack.title}
        </ToursCard>
      ))}
    </div>
      : (
        <div className={toursStyle.packagesContainer}>
          {data?.data?.data?.data?.map((pack) => (
            <ToursCard
              refetch={refetch}
              key={pack.id}
              id={pack.id}
              image={`${api_url}${pack.image}`.replace("/api/", "")}
              description={pack.description}
              // price={pack.price}
              // rating={pack.rating}
              review={pack.number_of_reviews}
              people={pack.number_of_people}
              tour_code={pack.tour_code}
              HasOffer={pack.HasOffer}
              day={pack.duration_days}
              // night={pack.night}
              // location={pack.location}
            >
              {pack.title}
            </ToursCard>
          ))}
        </div>
      )}
      <Outlet />
    </main>
  );
};

export default DashboardTours;

const AddTour = ({ refetch }) => {
  const [openAddTourBox, setOpenAddTourBox] = useState(false);
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const {
    mutate: addTour,
    isLoading,
  } = useMutation({
    mutationFn: (data) => {
      return axios.post(`${api_url}tour`, data, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
    },
    onSuccess: (data) => {
      console.log("Tour add successful", data);
      setOpenAddTourBox(false);
      notifySuccess("Tour Successfully Added");
      refetch();
    },
    onError: (error) => {
      console.error("add tour failed:", error);
      notifyError(error?.response?.data?.message);
    },
  });

  const { data } = useQuery({
    queryKey: ["destinations"],
    queryFn: () =>
      axios.get(`${api_url}destinations`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
    onSuccess: (response) => {
      console.log("Data fetched successfully:", response.data);
    },
    onError: (err) => {
      console.error("Error fetching data:", err);
    },
  });


 





  function submit(data) {
    console.log(data);
  
    // Step 1: Send request to the 'blog' endpoint with the title
    axios.post(`${api_url}blog`, { title: data.title }, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    .then((response) => {
      const blogId = response?.data?.data?._id; // Catch the blog_id from response
      console.log("Blog created with ID:", blogId);
  
      // Step 2: Prepare FormData for the 'tour' request
      const formData = new FormData();
      formData.append("destinationId", data?.selectedDestination);
      formData.append("image", data.image);
      formData.append("title", data.title);  
      formData.append("description", data.description);
      formData.append("tour_code", data.code);
      formData.append("number_of_people", data.number_of_people);
      formData.append("availability_days", data.availability_days);
      formData.append("duration_days", data.duration_days);
      formData.append("blogId", blogId); 

      formData.append("number_of_reviews", 2);
  
      // Step 3: Send the 'tour' request with FormData
      addTour(formData);
    })
    .catch((error) => {
      console.error("Error creating blog:", error);
      notifyError(error?.response?.data?.message || "Failed to create blog");
    });
  }

  return (
    <>
      <button
        className="button-primary"
        onClick={() => setOpenAddTourBox(true)}
      >
        Add Tour
      </button>
      <Modal isAppear={openAddTourBox} onClose={() => setOpenAddTourBox(false)} >
        <Form
          title={"Add Tour"}
          onSubmit={handleSubmit(submit)}
          className={toursStyle.form}
        >
          <Form.TextController
            register={register}
            registername="title"
            placeholder={"Tour title"}
            errorMessage={errors?.title && errors?.title?.message}
            validationRules={{
              required: "Tour title is required",
            }}
          />

          <Form.TextareaController
            register={register}
            registername="description"
            placeholder={"Tour Description"}
            errorMessage={errors?.description && errors?.description?.message}
            validationRules={{
              required: "Tour description is required",
            }}
          />
          <Form.TextController
            register={register}
            registername="number_of_people"
            placeholder={"number of people "}
            errorMessage={errors?.code && errors?.code?.message}
            validationRules={{
              required: "number of people is required",
            }}
          />
          <Form.TextController
            register={register}
            registername="availability_days"
            placeholder={"availability days "}
            errorMessage={errors?.code && errors?.code?.message}
            validationRules={{
              required: "availability days is required",
            }}
          />
          <Form.TextController
            register={register}
            registername="duration_days"
            placeholder={"duration days "}
            errorMessage={errors?.code && errors?.code?.message}
            validationRules={{
              required: "duration days is required",
            }}
          />
          <Form.TextController
            register={register}
            registername="code"
            placeholder={"Tour code"}
            errorMessage={errors?.code && errors?.code?.message}
            validationRules={{
              required: "Tour code is required",
            }}
          />
          
          <select {...register("selectedDestination")}>
            {data?.data?.data.map((item) => (
              <option key={item?.id} value={item?.id}>
                {item?.name}
              </option>
            ))}
          </select>
          <Form.DragAndDropController
            register={register}
            registername="image"
            setValue={setValue}
            errors={errors}
          />
          <Form.ButtonController type="submit" isLoading={isLoading}>
            Add
          </Form.ButtonController>
        </Form>
      </Modal>
    </>
  );
};
