import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { api_url } from '../../../../constants/base_url'
import { notifySuccess, notifyError } from '../../../../App'
import { useForm } from 'react-hook-form'
import Form from '../../../../components/shared/Form/Form'
import { zodResolver } from '@hookform/resolvers/zod'
import { updateUserSchema } from '../../../../Validations/user/updateUser.schema'
import ErrorText from '../../../../components/shared/ErrorText'

const EditUserForm = ({user, setOpen}) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
      }  = useForm({
        resolver: zodResolver(updateUserSchema),
        defaultValues: user
      })

    
    const queryClient = useQueryClient()
    const {mutate, isPending} = useMutation({
        mutationFn: (data) => axios.put(`${api_url}users/${user.id}`,data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        }),
        onSuccess: () => {
            notifySuccess("User updated successfully!")
            queryClient.invalidateQueries(["allUsers"])
            setOpen(false)
        },onError: () => {
            notifyError("Something went wrong")
        }
    })

    const submit = (data) => {
        const newData = {}
        Object.keys(data).forEach(key => {
            if(data[key] !== user[key]){
                newData[key] = data[key]
            }
        })
        mutate({...newData, id:user.id})
    }
  return (
    <Form title={`Update ${user?.name} Data`} onSubmit={handleSubmit(submit)}>
        <Form.TextController
            placeholder="Name"
            register={register}
            registername="name"
        />
        {errors.name && <ErrorText>
            {errors.name.message}
            </ErrorText>}
        <Form.TextController
            placeholder="Email"
            register={register}
            registername="email"
        />
        {errors.email && <ErrorText>
            {errors.email.message}
            </ErrorText>}
        <Form.TextController
            placeholder="Phone"
            register={register}
            registername="phone"
        />
        {errors.phone && <ErrorText>
            {errors.phone.message}
            </ErrorText>}
            <Form.ButtonController type="submit" disabled={isPending}>Save</Form.ButtonController>
    </Form>
  )
}

export default EditUserForm