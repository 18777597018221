import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import style from "./MyReservations.module.css";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { api_url } from "../../constants/base_url";
import { MdClose } from "react-icons/md";

const MyReservations = () => {
  const { id } = useParams();
  const [paymentURL, setPaymentURL] = useState("");
  const [paymentPayload, setPaymentPayload] = useState({});
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const { data: reservation } = useQuery({
    queryKey: ["reservation", id],
    queryFn: () =>
      axios.get(`${api_url}reservation/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
  });
  const { data: tour } = useQuery({
    queryKey: ["tour", reservation?.data?.data?.tour_id],
    queryFn: () =>
      axios.get(`${api_url}tour/${reservation?.data?.data?.tour_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
    enabled: !!reservation?.data?.data?.tour_id,
  });
  const { data: paymentInfo } = useQuery({
    queryKey: ["paymentInfo", id],
    queryFn: () =>
      axios.get(`${api_url}payment/info`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          Accept: "application/json",
        },
        params: {
          reservation_id: id,
        },
      }),
  });

  useEffect(() => {
    if (paymentInfo?.data?.data?.payment_url) {
      setPaymentURL(paymentInfo?.data?.data?.payment_url);
    }
  }, [paymentInfo]);

  console.log(paymentInfo?.data?.data?.payment_url, "paymentInfo");

  const { mutate, isLoading } = useMutation({
    mutationFn: (data) =>
      axios.post(`${api_url}payment/payReservation`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          Accept: "application/json",
        },
      }),
    onSuccess: (data) => {
      setPaymentPayload(data?.data?.data);
      setOpenConfirmation(true);
    },
  });
  return (
    <>
      {openConfirmation && paymentURL && (
        <div className={style.confirmation}>
          <div className={style.confirmationBox}>
            <button
              onClick={() => {
                setOpenConfirmation(false);
              }}
              className={style.close}
            >
              <MdClose size={24} />
            </button>
            <h2>Confirm Reservation</h2>
            <p>Are you sure you want to pay this reservation?</p>
            <p>
              Total: {paymentPayload?.amount} {paymentPayload?.currency}
            </p>
            <form
              action={paymentInfo?.data?.data?.payment_url}
              method="post"
              // onSubmit={(e) => {
              //   e.preventDefault();
              //   const formData = new FormData(e.target);
              //   axios
              //     .post(paymentURL, formData, {
              //       headers: {
              //         // signature: paymentPayload?.signature
              //       },
              //     })
              //     .then((response) => {
              //       // handle success
              //       console.log(response.data);
              //     })
              //     .catch((error) => {
              //       // handle error
              //       console.error(error);
              //     });
              // }}
            >
              {Object.entries(paymentPayload).map(([key, value]) => (
                <input key={key} type="hidden" name={key} value={value} />
              ))}
              <button type="submit" className={style.payBtn}>
                Pay
              </button>
            </form>
          </div>
        </div>
      )}
      <div className={style.banner}></div>
      <div className={style.content}>
        <h1>Your reservation data</h1>
        <p>Reservation Date: {reservation?.data?.data?.reservation_date}</p>
        <p>Title: {tour?.data?.data?.title}</p>
        <img
          src={`${api_url.slice(0, -5)}${tour?.data?.data?.image}`}
          alt={tour?.data?.data?.title}
          width={500}
        />
        <p>Description: {tour?.data?.data?.description}</p>
        <p>Days: {tour?.data?.data?.duration_days}</p>
        <p>Total Price: {reservation?.data?.data?.total_price}</p>
        <button
          onClick={() => {
            mutate({
              reservation_id: +id,
            });
          }}
        >
          Pay
        </button>
      </div>
    </>
  );
};

export default MyReservations;
