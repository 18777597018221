import { useEffect, useState } from "react";
import style from "./IncludeExclude.module.css";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { api_url } from "../../../../constants/base_url";
import { notifyError, notifySuccess } from "../../../../App";
import Loading from "../../../../components/shared/Loading/Loading";
import { COLORS } from "../../../../constants/colors";

const IncludeExclude = ({ edit, lang }) => {
  const [cardData, setCardData] = useState(null); // Store the matching object
  const [cardIndex, setCardIndex] = useState(null);

  const [included, setIncluded] = useState([]);
  const [excluded, setExcluded] = useState([]);
  const [NB, setNB] = useState([]);

  const [newItem, setNewItem] = useState({
    included: "",
    excluded: "",
    NB: "",
  });

  useEffect(() => {
    if (cardData) {
      setIncluded(JSON.parse(cardData?.content)?.included);
      setExcluded(JSON.parse(cardData?.content)?.excluded);
      setNB(JSON.parse(cardData?.content)?.NB);
    }
  }, [cardData]);

  const { mutate, isPending } = useMutation({
    mutationFn: (data) => {
      const url = cardData
        ? `${api_url}blogLanguage/updateSubCard`
        : `${api_url}blogLanguage/subCard`;

      return axios.post(url, data, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
    },
    onSuccess: (data) => {
      console.log("sub card add successful", data);
      notifySuccess("sub card add successful");
      refetch();
    },
    onError: (error) => {
      console.error("add card failed:", error);
      notifyError(error?.response?.data?.message);
    },
  });

  const {
    data: subCard,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["subCard", lang],
    queryFn: () =>
      axios.get(`${api_url}blogLanguage/${lang}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
  });

  useEffect(() => {
    if (subCard?.data?.data) {
      Object.entries(subCard.data.data).forEach(([key, value], index) => {
        if (key.startsWith("sub_") && value?.title === "include&exclude") {
          setCardData(value); // Set the matching object in state
          setCardIndex(key.slice(-1)); // Set the index in state
        }
      });
    }
  }, [subCard, isLoading]);

  const handleAddItem = (type) => {
    if (newItem[type].trim() === "") return; // Prevent adding empty items
    switch (type) {
      case "included":
        setIncluded([...included, newItem.included]);
        break;
      case "excluded":
        setExcluded([...excluded, newItem.excluded]);
        break;
      case "NB":
        setNB([...NB, newItem.NB]);
        break;
      default:
        break;
    }
    setNewItem({ ...newItem, [type]: "" }); // Clear the input
  };
  const handleDeleteItem = (type, index) => {
    switch (type) {
      case "included":
        setIncluded(included.filter((_, i) => i !== index));
        break;
      case "excluded":
        setExcluded(excluded.filter((_, i) => i !== index));
        break;
      case "NB":
        setNB(NB.filter((_, i) => i !== index));
        break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    const payload = {
      title: "include&exclude",
      blogLanguageId: lang,
      content: JSON.stringify({ included, excluded, NB }),
      ...(cardData && { _method: "PUT", index: cardIndex }),
    };
    mutate(payload);
  };

  return edit ? (
    <>
      <div className={style.includeExclude}>
        <div className={style.include}>
          <h3>Included</h3>
          <ul>
            {included.map((item, index) => (
              <li
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <p style={{ margin: "0" }}>{item}</p>
                <button
                  className="button-primary"
                  style={{ padding: "5px" }}
                  onClick={() => handleDeleteItem("included", index)}
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
          <div className={style.addInList}>
            <input
              type="text"
              value={newItem.included}
              onChange={(e) =>
                setNewItem({ ...newItem, included: e.target.value })
              }
              placeholder="Add Included Item"
            />
            <button
              type="button"
              className="button-primary"
              onClick={() => handleAddItem("included")}
            >
              Add
            </button>
          </div>
        </div>
        <div className={style.exclude}>
          <h3>Exclude</h3>
          <ul>
            {excluded.map((item, index) => (
              <li
                key={index}
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <p style={{ margin: "0" }}>{item}</p>
                <button
                  className="button-primary"
                  style={{ padding: "5px" }}
                  onClick={() => handleDeleteItem("excluded", index)}
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
          <div className={style.addInList}>
            <input
              type="text"
              value={newItem.excluded}
              onChange={(e) =>
                setNewItem({ ...newItem, excluded: e.target.value })
              }
              placeholder="Add Excluded Item"
            />
            <button
              type="button"
              className="button-primary"
              onClick={() => handleAddItem("excluded")}
            >
              Add
            </button>
          </div>
        </div>
      </div>
      <div className={style.nbSection}>
        <h3>N.B.:</h3>
        <ul>
          {NB.map((item, index) => (
            <li key={index}>
              <p>{item}</p>

              <button
                className="button-primary"
                style={{ padding: "10px" }}
                onClick={() => handleDeleteItem("NB", index)}
              >
                Delete
              </button>
            </li>
          ))}
        </ul>
        <div className={style.addInList}>
          <input
            type="text"
            value={newItem.NB}
            onChange={(e) => setNewItem({ ...newItem, NB: e.target.value })}
            placeholder="Add NB Item"
          />
          <button
            type="button"
            className="button-primary"
            onClick={() => handleAddItem("NB")}
          >
            Add
          </button>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "8px",
          }}
        >
          <button
            type="button"
            className="button-primary"
            onClick={handleSubmit}
            disabled={isPending}
          >
            {isPending ? <Loading color={COLORS.DANGER} /> : "Submit"}
          </button>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className={style.includeExclude}>
        <div className={style.include}>
          <h3>Included</h3>
          <ul>
            {included.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
        <div className={style.exclude}>
          <h3>Exclude</h3>
          <ul>
            {excluded.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className={style.nbSection}>
        <h3>N.B.:</h3>
        <ul>
          {NB.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default IncludeExclude;
