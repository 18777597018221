import { useState } from 'react';
import toursStyles from "./toursCard.module.css"
import "../../dashboardIndex.css"
import { BsTrashFill } from 'react-icons/bs';
import { BsPencilFill } from 'react-icons/bs';
import { BsClock } from "react-icons/bs";
import { BsPeopleFill } from "react-icons/bs";
import { PiMapPinFill } from "react-icons/pi";
import { Link } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { api_url } from '../../../constants/base_url';
import axios from 'axios';
import { notifyError, notifySuccess } from '../../../App';
import Modal from '../../../components/shared/Modal/Modal';
import Form from '../../../components/shared/Form/Form';
import CustomCoppedInput from '../../../components/CustomCoppedInput/CustomCoppedInput';


const ToursCard = ({ children, id, image, HasOffer, starting, ending, rating, review, day, night, people, location, description, refetch,tour_code }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleDescription = () => {
        setIsExpanded(!isExpanded);
    };

    const truncatedDescription = description.length > 80 ? description.slice(0, 80) + "..." : description;
    return (
        <div className={toursStyles.card}>
            <div className={toursStyles.imageSection} style={{ backgroundImage: `url(${image})` }}>
                <div className={toursStyles.price}>Code : {tour_code} </div>
                {HasOffer?(
                <div className={toursStyles.offer}> Discount % </div>
                ):null}
                <div className={toursStyles.meta}>
                    <span>
                        <BsClock />
                        {day}D/{night}N
                    </span>
                    <span>
                        <BsPeopleFill />
                        People:{people}
                    </span>
                    {/* <span>
                        <PiMapPinFill />
                        {location}
                    </span> */}
                </div>
            </div>
            <div className={toursStyles.contentSection}>
                <h4>{children}</h4>
                {/* <div className={toursStyles.startingEnding}>
                    <p><span>Starting Point: </span>{starting}.</p>
                    <p><span>Ending Point: </span>{ending}.</p>
                </div> */}

                <div className={toursStyles.ratingStart} title="Rated 5 out of 5">
                    <span style={{ width: rating }}></span>
                </div>
                <p>{isExpanded ? description : truncatedDescription}
                    {description.length > 100 && (
                        <span onClick={toggleDescription} className={toursStyles.toggleButton}>
                            {isExpanded ? ' See less' : ' See more'}
                        </span>
                    )}</p>
                <span>
                    <p className={toursStyles.review}>{review} reviews</p>
                        <CustomCoppedInput value={tour_code}/>
                    <div className={toursStyles.actionSection}>
                        <Link className='transitionAnimation' to={`/admin/tours/${id}`}><BsPencilFill /> Edit</Link>
                        <DeleteTour country={"tour"} id={id} refetch={refetch} />
                    </div>
                </span>
            </div>
        </div>
    )
}

export default ToursCard


const DeleteTour = ({ id, country, refetch }) => {
    const [deleteBox, setDeleteBox] = useState(false)
    const { mutate: deleteTour, isLoading } = useMutation({
        mutationFn: () => {
            return axios.post(`${api_url}tour/${id}`, { _method: "DELETE" }, {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            });
        },
        onSuccess: (data) => {
            console.log("Tour Deleted successful", data);
            setDeleteBox(false);
            notifySuccess(`${country} Deleted Successfully`);
            refetch();
        },
        onError: (error) => {
            console.error("Delete tour failed:", error);
            notifyError(error?.response?.data?.message);
        }
    });

    return (
        <>
            <span className='transitionAnimation' onClick={() => setDeleteBox(true)}><BsTrashFill /> Delete</span>
            <Modal isAppear={deleteBox} onClose={() => setDeleteBox(false)}>
                <Form className={toursStyles.form}>
                    <h2 style={{ textAlign: "center" }}>{`Delete ${country} ?`}</h2>
                    <div className={toursStyles.subDiv}>
                        <Form.ButtonController onClick={deleteTour} isLoading={isLoading} main red>Delete</Form.ButtonController>
                        <Form.ButtonController onClick={() => setDeleteBox(false)} sub red>Cancel</Form.ButtonController>
                    </div>
                </Form>
            </Modal>
        </>
    )
}
