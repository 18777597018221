import { useState } from 'react';
import { COLORS } from '../../constants/colors';
import { FaCheck } from "react-icons/fa6";
import { IoIosCopy } from "react-icons/io";
import style from "./CustomCoppedInput.module.css"


const CustomCoppedInput = ({ value = "201c44e3-c032-451e-a6d7-067aad0d3605" }) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(value).then(() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 1000);
        });
    };

    return (
        <div className={style.inputContainer}>
            <div
                className={style.copyIcon}
                onClick={handleCopyClick}
                style={{ color: isCopied ? COLORS.SUCCESS : undefined }}
            >
                {isCopied ? <FaCheck/> : <IoIosCopy />}
            </div>
        </div>
    );
};

export default CustomCoppedInput;
