import { z } from "zod";

export const inquireSchema = z.object({
    destination_id: z.string().nonempty('You must select a destination'),
    contact_number: z.string()
      .nonempty('Contact number cannot be empty')
      .regex(/^\d+$/, 'Contact number must be numeric'),
    email: z.string()
      .nonempty('Email cannot be empty')
      .email('Invalid email format'),
      from_date: z.string().nonempty('You must pick a start date'),
      to_date: z.string().nonempty('You must pick an end date'),
    nationality: z.string('You must select a nationality').nonempty('You must select a nationality'),
    adult_count: z.number().nonnegative('Adults number cannot be less than 0'),
    baby_count: z.number().nonnegative('Baby number cannot be less than 0'),
    child_count: z.number().nonnegative('Child number cannot be less than 0'),
  });