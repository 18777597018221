// HomeSliderSection.js
// import React from "react";
import SliderItem from "./slideritem";
import imageslide1 from "../../images/slider-banner-1.jpg";
import imageslide2 from "../../images/imgslider2.jpg";
// import imageslide2 from "../../images/slider-banner-2.jpg";
import Slider from "react-slick";
import { useState } from "react";
import Modal from "../shared/Modal/Modal";
import { BookingForm } from "../../pages/TourDetails/TourDetails";
import { api_url } from "../../constants/base_url";
import axios from "axios";
import { useQuery, useMutation } from "@tanstack/react-query";
import { notifyError, notifySuccess } from "../../App";
function HomeSliderSection() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDestination, setSelectedDestination] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  const { data, error, isLoading } = useQuery({
    queryKey: ["destinations"],
    queryFn: () =>
      axios.get(`${api_url}destinations`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
    onSuccess: (response) => {
      console.log("Data fetched successfully:", response.data);
    },
    onError: (err) => {
      console.error("Error fetching data:", err);
    },
  });

    const {mutate, isPending} = useMutation({
      mutationFn: (data) => axios.post(`${api_url}inquiries`, data, {
        headers: {
          "Accept": "application/json",
        }
      }),
      onSuccess: () => {
        notifySuccess("We have received your inquire, we will be in touch with you soon")
        setIsOpen(false)
      },
      onError: () => {
        notifyError("Something went wrong, please try again")
      }
    })

    const submit = (data) => {
      mutate(data)
    }

  const handleDestinationChange = (event) => {
    setSelectedDestination(event.target.value);
  };
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  return (
    <>
      <section className="home-slider-section" style={{position:"relative"}}>
        <Slider {...settings} className="home-slider">
          <SliderItem
            backgroundImage={imageslide2}
            title="TRAVELLING AROUND THE WORLD"
          />
          <SliderItem
            backgroundImage={imageslide1}
            title="TRAVELLING AROUND THE WORLD"
          />
        </Slider>
        <div className="fixed-form-content">
        {isOpen && (
          <Modal isAppear onClose={() => setIsOpen(false)}>
            <BookingForm isPending={isPending} title="TRAVELLING AROUND THE WORLD" inquire={submit} inquireData={{from_date: selectedDate, destination_id:selectedDestination}}>

            {<select
                  name="travel-destination"
                  value={selectedDestination}
                  onChange={handleDestinationChange}
                >
                        
                   {isLoading ? (
                      <option>Loading destinations...</option>
                    ) : error ? (
                      <option>Error loading destinations</option>
                    ) : (<>
                    
                        <option
                          value=""
                        >
                          Select your destination
                        </option>
                        {data?.data?.data?.map((destination) => (
                        <option
                          key={destination.id}
                          value={destination.id}
                        >
                          {destination.name}
                        </option>
                      ))}
                    </>
                      
                    )}
                </select>}
            </BookingForm>
          </Modal>
        )}

        <div className="trip-search-section" style={{position:"absolute", bottom:"10%", width:"100%"}}>
          <div className="container-fluid">
            <div className="trip-search-inner d-flex justify-content-center">
              <div className="input-group col-md-5">
                <select
                  name="travel-destination"
                  value={selectedDestination}
                  onChange={handleDestinationChange}
                >
                   {isLoading ? (
                      <option>Loading destinations...</option>
                    ) : error ? (
                      <option>Error loading destinations</option>
                    ) : (
                      <>
                    
                        <option
                          value=""
                          disabled
                        >
                          Select your destination
                        </option>
                        {data?.data?.data?.map((destination) => (
                        <option
                          key={destination.id}
                          value={destination.id}
                        >
                          {destination.name}
                        </option>
                      ))}
                    </>
                    )}
                </select>
              </div>
              <div className="input-group col-md-5">
                <input
                  className="input-date-picker"
                  type="date"
                  name="s"
                  placeholder="when?"
                  value={selectedDate}
                  onChange={handleDateChange}
                />
              </div>

              <div className="input-group col-md-2">
                <input
                  type="submit"
                  name="travel-search"
                  value="INQUIRE"
                  onClick={() => setIsOpen(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>
    </>
  );
}

export default HomeSliderSection;
