import { Link } from 'react-router-dom';

const CustomButton = ({children,href, disabled}) => {
    return (
        <>
            <div class="btn-wrap text-center">
                <Link to={href} class="button-primary">{children}</Link>
            </div>
        </>
    )
}

export default CustomButton