import style from "./editPriceCard.module.css"

const EditPricesCard = ({ priceCard, edit }) => {
    const { name,  } = priceCard;
    return (
        <>
            <div className={style.priceCard}>
                <h3>{name}</h3>
                <div>
                    <div className={style.pricesSection}>
                        <h4>Prices</h4>
                        <div className={style.iterationsContainer}>
                            {
                                
                                    <IterationsSection iteration={priceCard} />
                            }
                        </div>
                    </div>
                    {/* <div className={style.hotelsSection}>
                        <h4>Hotels</h4>
                        {
                            hotels.map((hotel) => (
                                <HotelCard hotel={hotel} />
                            ))
                        }
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default EditPricesCard


const IterationsSection = ({ iteration }) => {
    const { available_from, available_to } = iteration
    return (
        <div className={style.iterationsSection}>
            <h5>{available_from} - {available_to}</h5>
            <div className={style.detailsContainer}>
                    <IterationDetails detail={iteration} />
            </div>
        </div>
    )
}


const IterationDetails = ({ detail }) => {
    const { price_per_child, price_per_adult,special_offer } = detail
    const getPrice =function(originalPrice,special_offer){
        
        let newPrice=0
        if (special_offer>0) {
             newPrice = originalPrice* ((100-special_offer)/100)
             newPrice=  newPrice.toFixed(2)
        }else{
            newPrice= originalPrice
        }
        return newPrice
    }
    return (
        <>
        
        <div className={style.detailsCard}>
            <p>price per adult : {getPrice(price_per_adult,special_offer)}</p>
            <p>Price per child : {getPrice(price_per_child,special_offer)}</p>
        </div>
            {special_offer? <p style={{color:"red", fontSize:"22px", fontWeight:"bold"}}>Special offer : {special_offer}%</p>:null}
        </>
    )
}


// const HotelCard = ({ hotel }) => {
//     const { image, name, link } = hotel
//     return (
//         <div className={style.hotelCard}>
//             <img src={image} alt="" />
//             <a href={link}>{name}</a>
//         </div>
//     )
// }




