import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { api_url } from '../../../../constants/base_url';
import { notifyError, notifySuccess } from '../../../../App';
import { FaPlus } from 'react-icons/fa';
import Modal from '../../../../components/shared/Modal/Modal';
import Form from '../../../../components/shared/Form/Form';
import style from "../dashboardBlogPage.module.css"

const AddLang = ({ blogID, refetch, inEmpty }) => {
    const [openAddLangBox, setOpenAddLangBox] = useState(false);

    const {
        register,
        setValue,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const {data:languages } = useQuery({
        queryKey:["allLanguages"],
        queryFn:() => axios.get(`${api_url}language`,{
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        })
    })


    const { mutate: addLang, isLoading } = useMutation({
        mutationFn: (data) => {
            return axios.post(`${api_url}blogLanguage`, data, {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            });
        },
        onSuccess: (data) => {
            console.log("Lang add successful", data);
            setOpenAddLangBox(false);
            notifySuccess("Lang Successfully Added");
            refetch();
        },
        onError: (error) => {
            console.error("add lang failed:", error);
            notifyError(error?.response?.data?.message);
        }
    });

    function submit(data) {
        const formData = new FormData();
        formData.append("blogId", blogID);
        formData.append("language", data.language);
        formData.append("content", data.description);
        formData.append("images[0]", data.image);
        addLang(formData)
        
    }

    return (
        <>
            {inEmpty ?
                <button className='button-secondary' type='button' onClick={() => setOpenAddLangBox(true)}>Add Language</button>
                :
                <span><FaPlus onClick={() => setOpenAddLangBox(true)} /></span>
            }
            <Modal isAppear={openAddLangBox} onClose={() => setOpenAddLangBox(false)}>
                <Form
                    title={"Add Language"}
                    onSubmit={handleSubmit(submit)}
                    className={style.form}
                >
                    {/* <Form.TextController
                        register={register}
                        registername="language"
                        placeholder={"Blog Language"}
                        errorMessage={errors?.language && errors?.language?.message}
                        validationRules={{
                            required: 'Blog Language is required',
                        }}
                    /> */}
                    <Form.SelectController placeholder={"Select language"} options={languages?.data?.data?.map(item => item.name) || []} onChange={(e) => {
                        setValue("language", e.target.value)
                        
                    }} registername={"language"} validationRules={{
                        required: 'Blog language is required',
                    }} errorMessage={errors?.language && errors?.language?.message} register={register}/>
                    
                    <Form.TextareaController
                        register={register}
                        registername="description"
                        placeholder={"Blog Description"}
                        errorMessage={errors?.description && errors?.description?.message}
                        validationRules={{
                            required: 'Blog description is required',
                        }}
                    />
                    <Form.DragAndDropController
                        register={register}
                        registername="image"
                        setValue={setValue}
                        errors={errors}
                    />
                    <Form.ButtonController type='submit' isLoading={isLoading}>Add</Form.ButtonController>
                </Form>
            </Modal>
        </>
    )
}

export default AddLang