import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import "./../../dashboardIndex.css";
import toursStyle from "./../dashboardTours/dashboardTours.module.css";

import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useForm } from "react-hook-form";
import { notifyError, notifySuccess } from "../../../App";
import Modal from "../../../components/shared/Modal/Modal";
import Form from "../../../components/shared/Form/Form";
import Loading from "../../../components/shared/Loading/Loading";
import EmptyNoItems from "../../components/emptyNoItems/emptyNoItems";
import { useSearch } from "../../../context/SearchContext";
import Pagination from "../../components/pagination/pagination";
import { api_url } from "../../../constants/base_url";
import ToursCard from "../../components/toursCard/toursCard";

const SpecialOffersPage = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["tour"],
    queryFn: () =>
      axios.get(`${api_url}tour?page=${currentPage}&HasOffer=1`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
    onSuccess: (response) => {
      console.log("Data fetched successfully:", response.data);
    },
    onError: (err) => {
      console.error("Error fetching data:", err);
    },
  });

  let totalPages = data?.data?.last_page || 1;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // console.log("Tour Data:", data?.data?.data)
  const {searchResults, _ } = useSearch();
  useEffect(()=>{
    if (searchResults.length>0) {
      totalPages = searchResults?.data?.last_page
    }
  },[searchResults])

  return (
    <main className="mainContainer">
      <div className={toursStyle.addTour}>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
      {isLoading ? (
        <div className={toursStyle.loading}>
          <Loading />
        </div>
      ) :(searchResults?.data?.data.length==0 || data?.data?.data?.data?.length === 0) ? (
        <EmptyNoItems text={"No Tours Yet!"} />
      ) :searchResults.length!==0?  
      <div className={toursStyle.packagesContainer}>
      {searchResults.data.data?.map((pack) => (
        <ToursCard
          refetch={refetch}
          key={pack.id}
          id={pack.id}
          image={`${api_url}${pack.image}`.replace("/api/", "")}
          description={pack.description}
          // price={pack.price}
          // rating={pack.rating}
          review={pack.number_of_reviews}
          people={pack.number_of_people}
          tour_code={pack.tour_code}
          // day={pack.day}
          // night={pack.night}
          // location={pack.location}
        >
          {pack.title}
        </ToursCard>
      ))}
    </div>
      : (
        <div className={toursStyle.packagesContainer}>
          {data?.data?.data?.data?.map((pack) => (
            <ToursCard
              refetch={refetch}
              key={pack.id}
              id={pack.id}
              image={`${api_url}${pack.image}`.replace("/api/", "")}
              description={pack.description}
              // price={pack.price}
              // rating={pack.rating}
              review={pack.number_of_reviews}
              people={pack.number_of_people}
              tour_code={pack.tour_code}
              HasOffer={pack.HasOffer}
              // day={pack.day}
              // night={pack.night}
              // location={pack.location}
            >
              {pack.title}
            </ToursCard>
          ))}
        </div>
      )}
      <Outlet />
    </main>
  );
};

export default SpecialOffersPage;