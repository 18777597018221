import { Link } from "react-router-dom";

const ContactDetails = () => {
  return (
    <div className="contact-detail-wrap">
      <h3>Need help ?? Feel free to contact us !</h3>
      <p>
        connect and explore the beauty of the Middle East. Initially established
        to cater to the travelers in the Far East region, we have successfully
        expanded our travel packages to encompass the global market.
      </p>
      <p>
        Our exceptional attention to detail, unrivaled expertise, and unwavering
        commitment to customer satisfaction make us the preferred choice for
        those seeking extraordinary journeys.
      </p>
      <div className="details-list">
        <ul>
          <li>
            <span className="icon">
              <i className="fas fa-map-signs"></i>
            </span>
            <div className="details-content">
              <h4>Location Address</h4>
              <span>
                25B First Gate, Hadayek Al-Ahram, Second Floor, Flat 07, Giza,
                Egypt
              </span>
            </div>
          </li>
          <li>
            <span className="icon">
              <i className="fas fa-envelope-open-text"></i>
            </span>
            <div className="details-content">
              <h4>Email Address</h4>
              <span>info@albumtravel.com</span>
            </div>
          </li>
          <li>
            <span className="icon">
              <i className="fas fa-phone-volume"></i>
            </span>
            <div className="details-content">
              <h4>Phone Number</h4>
              <span>
                Telephone: +2 010 0842 7156 / Mobile: +2 010 3397 3047
              </span>
            </div>
          </li>
        </ul>
      </div>
      <div className="contct-social social-links">
        <h3>Follow us on social media..</h3>
        <ul>
          <li>
            <Link to="https://www.facebook.com/albumtravelegypt?mibextid=ZbWKwL">
              <i className="fab fa-facebook-f" aria-hidden="true"></i>
            </Link>
          </li>
          <li>
            <Link to="https://www.pinterest.com/Albumtravel/">
              <i className="fab fa-pinterest" aria-hidden="true"></i>
            </Link>
          </li>
          <li>
            <Link to="https://www.tiktok.com/@albumtravel11?_t=8nY0e4IvM7k&_r=1">
              <svg
                className="fab fa-tiktok"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="20"
                fill="currentColor"
                class="bi bi-tiktok"
                viewBox="0 0 16 16"
              >
                <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z" />
              </svg>
            </Link>
          </li>
          <li>
            <Link to="https://www.instagram.com/albumtravelegypt?igsh=MWtmamk2aGRpMTVqYg==">
              <i className="fab fa-instagram" aria-hidden="true"></i>
            </Link>
          </li>
          <li>
            <Link to="https://www.tripadvisor.com/Profile/albumtravel">
              <i class="fab fa-tripadvisor" aria-hidden="true"></i>
            </Link>
          </li>
          <li>
            <Link to="https://www.trustpilot.com/review/albumtravel.com">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20px"
                viewBox="0 0 25 25"
                role="img"
              >
                <title>Trustpilot icon</title>
                <path d="M12,17.964l5.214-1.321l2.179,6.714L12,17.964z M24,9.286h-9.179L12,0.643L9.179,9.286 H0l7.429,5.357l-2.821,8.643l7.429-5.357l4.571-3.286L24,9.286L24,9.286L24,9.286L24,9.286z" />
              </svg>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ContactDetails;
