import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { IoCloseCircle } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";
import { MdEdit } from "react-icons/md";

import img1 from "../../../images/Luxor-Trips-in-Egypt.jpg";
import img2 from "../../../images/Saqqara.jpg";
import img3 from "../../../images/slider-banner-2.jpg";
import img4 from "../../../images/karnak.jpeg";
import img5 from "../../../images/img7.jpg";
import img6 from "../../../images/camel-trip.jpg";
import img7 from "../../../images/img13.jpg";
import img8 from "../../../images/img2 - Copy.jpg";
import img9 from "../../../images/gallery-6.jpg";

import style from "./dashboardTourPage.module.css";
import IncludeExclude from "../../components/tourDetailsSections/IncludeExclude/IncludeExclude";
import EditItineraryCard from "../../components/tourDetailsSections/editItineraryCard/editItineraryCard";
import EditTourImages from "../../components/tourDetailsSections/editTourImages/editTourImages";
import Overview from "../../components/tourDetailsSections/Overview/Overview";
import EditPricesCard from "../../components/tourDetailsSections/editPriceCard/editPriceCard";
import Modal from "../../../components/shared/Modal/Modal";
import Form from "../../../components/shared/Form/Form";
import EmptyNoItems from "../../components/emptyNoItems/emptyNoItems";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { api_url } from "../../../constants/base_url";

import Empty from "../../../components/empty/empty";
import AddLang from "../dashboardBlogPage/lang/addLang";
import Loading from "../../../components/shared/Loading/Loading";
import LangCard from "./langCard/langCard";

import langCardBlog from "./../dashboardBlogPage/dashboardBlogPage.module.css";
import { useRecoilState } from "recoil";
import blogLang from "./../../atoms/blogLangAtom";
import { useForm } from "react-hook-form";
import { notifyError, notifySuccess } from "../../../App";
import { COLORS } from "../../../constants/colors";

const images = [img1, img2, img3, img4, img5, img6];
const itineraries = [
  {
    title: "4 Days - Aswan / Luxor Every Friday",
    days: [
      {
        label: "Day 1: Embark Dahabiya",
        programDetails: {
          details: [
            "Embark in Aswan at 12:00 PM",
            "Visit Philae Temple",
            "Dinner on board",
          ],
          summary: "Sailing at 17:00 PM and overnight Herdiab Island.",
        },
        meals: "Lunch and Dinner",
        visits: "Aswan, Philae Temple",
      },
      {
        label: "Day 2: Kom Ombo",
        programDetails: {
          details: [
            "Breakfast on board and sailing to Kom Ombo",
            "Visit Kom Ombo Temple",
            "Sailing to Edfu and overnight",
          ],
          summary: "Overnight at Edfu.",
        },
        meals: "Breakfast, Lunch, and Dinner",
        visits: "Kom Ombo Temple",
      },
      {
        label: "Day 3: Sailing to Edfu",
        programDetails: {
          details: [
            "Enjoy early breakfast on board while sailing to Edfu",
            "Visit Edfu Temple",
            "Sailing to Esna and crossing Esna Lock while having Lunch",
          ],
          summary: "Overnight at Luxor.",
        },
        meals: "Breakfast, Lunch, and Dinner",
        visits: "Kom Ombo Temple",
      },
      {
        label: "Day 4: Luxor - Final Departure",
        programDetails: {
          details: [
            "Breakfast on board",
            "Disembark, then get transferred to visit Valley of the Kings and Karnak Temple",
            "Transfer to Luxor Airport for your final departure",
          ],
          summary: "",
        },
        meals: "Breakfast",
        visits: "Valley of the Kings, Karnak Temple",
      },
    ],
    NB: "Itineraries may be changed without prior notice due to navigational circumstances, maybe in sequences but not in visits",
  },
  {
    title: "5 Days - Luxor / Aswan Every Monday",
    days: [
      {
        label: "Day 1: Embark Dahabiya",
        programDetails: {
          details: [
            "Embark in Aswan at 12:00 PM",
            "Visit Philae Temple",
            "Dinner on board",
          ],
          summary: "Sailing at 17:00 PM and overnight Herdiab Island.",
        },
        meals: "Lunch and Dinner",
        visits: "Aswan, Philae Temple",
      },
      {
        label: "Day 2: Kom Ombo",
        programDetails: {
          details: [
            "Breakfast on board and sailing to Kom Ombo",
            "Visit Kom Ombo Temple",
            "Sailing to Edfu and overnight",
          ],
          summary: "Overnight at Edfu.",
        },
        meals: "Breakfast, Lunch, and Dinner",
        visits: "Kom Ombo Temple",
      },
      {
        label: "Day 3: Sailing to Edfu",
        programDetails: {
          details: [
            "Enjoy early breakfast on board while sailing to Edfu",
            "Visit Edfu Temple",
            "Sailing to Esna and crossing Esna Lock while having Lunch",
          ],
          summary: "Overnight at Luxor.",
        },
        meals: "Breakfast, Lunch, and Dinner",
        visits: "Kom Ombo Temple",
      },
      {
        label: "Day 4: Luxor - Final Departure",
        programDetails: {
          details: [
            "Breakfast on board",
            "Disembark, then get transferred to visit Valley of the Kings and Karnak Temple",
            "Transfer to Luxor Airport for your final departure",
          ],
          summary: "",
        },
        meals: "Breakfast",
        visits: "Valley of the Kings, Karnak Temple",
      },
    ],
    NB: "Itineraries may be changed without prior notice due to navigational circumstances, maybe in sequences but not in visits",
  },
  {
    title: "8 Days - Luxor / Luxor Every Monday",
    days: [
      {
        label: "Day 1: Embark Dahabiya",
        programDetails: {
          details: [
            "Embark in Aswan at 12:00 PM",
            "Visit Philae Temple",
            "Dinner on board",
          ],
          summary: "Sailing at 17:00 PM and overnight Herdiab Island.",
        },
        meals: "Lunch and Dinner",
        visits: "Aswan, Philae Temple",
      },
      {
        label: "Day 2: Kom Ombo",
        programDetails: {
          details: [
            "Breakfast on board and sailing to Kom Ombo",
            "Visit Kom Ombo Temple",
            "Sailing to Edfu and overnight",
          ],
          summary: "Overnight at Edfu.",
        },
        meals: "Breakfast, Lunch, and Dinner",
        visits: "Kom Ombo Temple",
      },
      {
        label: "Day 3: Sailing to Edfu",
        programDetails: {
          details: [
            "Enjoy early breakfast on board while sailing to Edfu",
            "Visit Edfu Temple",
            "Sailing to Esna and crossing Esna Lock while having Lunch",
          ],
          summary: "Overnight at Luxor.",
        },
        meals: "Breakfast, Lunch, and Dinner",
        visits: "Kom Ombo Temple",
      },
      {
        label: "Day 4: Luxor - Final Departure",
        programDetails: {
          details: [
            "Breakfast on board",
            "Disembark, then get transferred to visit Valley of the Kings and Karnak Temple",
            "Transfer to Luxor Airport for your final departure",
          ],
          summary: "",
        },
        meals: "Breakfast",
        visits: "Valley of the Kings, Karnak Temple",
      },
    ],
    NB: "Itineraries may be changed without prior notice due to navigational circumstances, maybe in sequences but not in visits",
  },
];
const prices = [
  {
    title: "4-Day Itinerary",
    iteration: [
      {
        start: "may",
        end: "august",
        details: [
          {
            label: "Per Person in Double Room",
            price: "US$ 2674",
          },
          {
            label: "Per Person in Single Room",
            price: "US$ 4134",
          },
        ],
      },
      {
        start: "september",
        end: "april",
        details: [
          {
            label: "Per Person in Double Room",
            price: "US$ 3447",
          },
          {
            label: "Per Person in Single Room",
            price: "US$ 5267",
          },
        ],
      },
    ],
    hotels: [
      {
        image: img7,
        name: "Asharanda Nebyt Dahabiya Cruise",
        link: "https://www.tripadvisor.com/Attraction_Review-g294201-d472084-Reviews-Nile_River-Cairo_Cairo_Governorate.html",
        rating: "100%",
      },
    ],
  },
  {
    title: "5-Day Itinerary",
    iteration: [
      {
        start: "may",
        end: "august",
        details: [
          {
            label: "Per Person in Double Room",
            price: "US$ 3542",
          },
          {
            label: "Per Person in Single Room",
            price: "US$ 5486",
          },
        ],
      },
      {
        start: "september",
        end: "april",
        details: [
          {
            label: "Per Person in Double Room",
            price: "US$ 3650",
          },
          {
            label: "Per Person in Single Room",
            price: "US$ 5594",
          },
        ],
      },
    ],
    hotels: [
      {
        image: img8,
        name: "Asharanda Nebyt Dahabiya Cruise",
        link: "https://www.tripadvisor.com/Attraction_Review-g294201-d472084-Reviews-Nile_River-Cairo_Cairo_Governorate.html",
        rating: "40%",
      },
    ],
  },
  {
    title: "8-Day Itinerary",
    iteration: [
      {
        start: "may",
        end: "august",
        details: [
          {
            label: "Per Person in Double Cabin",
            price: "US$ 6135",
          },
          {
            label: "Per Person in Double Suite",
            price: "US$ 8970",
          },
          {
            label: "Per Person in Single Cabin",
            price: "US$ 9535",
          },
        ],
      },
      {
        start: "september",
        end: "april",
        details: [
          {
            label: "Per Person in Double Cabin",
            price: "US$ 7905",
          },
          {
            label: "Per Person in Double Suite",
            price: "US$ 11450",
          },
          {
            label: "Per Person in Single Cabin",
            price: "US$ 12155",
          },
        ],
      },
    ],
    hotels: [
      {
        image: img9,
        name: "Asharanda Nebyt Dahabiya Cruise",
        link: "https://www.tripadvisor.com/Attraction_Review-g294201-d472084-Reviews-Nile_River-Cairo_Cairo_Governorate.html",
        rating: "60%",
      },
    ],
  },
];

const DashboardTourPage = () => {
  const { tourId } = useParams();

  const [langAtom, setLangAtom] = useRecoilState(blogLang);
  const [tab, setTab] = useState(itineraries[0].title);
  const [currentItineraries, setCurrentItineraries] = useState(itineraries[0]);
  const [editTitle, setEditTitle] = useState(false);

  const [editOverview, setEditOverview] = useState(false);
  const [editIncludeExclude, setEditIncludeExclude] = useState(false);
  const [editItinerary, setEditItinerary] = useState(false);
  const [addItinerary, setAddItinerary] = useState(false);
  const [editCruise, setEditCruise] = useState(false);
  const [editCabin, setEditCabin] = useState(false);
  const [editPrice, setEditPrice] = useState(false);
  const [addPrice, setAddPrice] = useState(false);

  const [cardData, setCardData] = useState(null); // Store the matching object
  const [cardIndex, setCardIndex] = useState(null);
  const [cruiseData, setCruiseData] = useState({});
  const [cabinData, setCabinData] = useState([]);
  const [cabinIndex, setCabinIndex] = useState({});

  const {
    data,
    error,
    isLoading,
    refetch: refetchTour,
  } = useQuery({
    queryKey: ["tour", tourId],
    queryFn: () =>
      axios.get(`${api_url}tour/${tourId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
  });

  const {
    data: pricing,
    error: pricingError,
    isLoading: isPricingLoading,
    isError: isPricingError,
    refetch: pricingRefetch,
  } = useQuery({
    queryKey: ["pricing", tourId],
    queryFn: () =>
      axios.get(`${api_url}tourPricing/${tourId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
  });

  const [currentBlogLanguages, setCurrentBlogLanguages] = useState([]);
  const {
    data: lang,
    error: langError,
    isLoading: langLoading,
    isError: isLangError,
    refetch: langRefetch,
  } = useQuery({
    queryKey: ["blogCard"],
    queryFn: () =>
      axios.get(`${api_url}blogLanguage`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
    onSuccess: (response) => {
      console.log("All Languages:", response.data);
    },
    onError: (err) => {
      console.error("Error fetching data:", err);
    },
  });

  const {
    data: subCard,
    refetch,
    isLoading: isSubCardLoading,
    isSuccess,
  } = useQuery({
    queryKey: ["subCard", lang],
    queryFn: () =>
      axios.get(`${api_url}blogLanguage/${langAtom}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
    enabled: !!langAtom,
  });
  useEffect(() => {
    if (langAtom) {
      refetch();
    }
  }, [langAtom]);

  useEffect(() => {
    if (subCard?.data?.data && !isLoading) {
      Object.entries(subCard.data.data).forEach(([key, value], index) => {
        if (key.startsWith("sub_") && value?.title === "cruise") {
          setCardData(value); // Set the matching object in state
          setCardIndex(key.slice(-1)); // Set the index in state
        }
      });
      Object.entries(subCard.data.data).forEach(([key, value], index) => {
        if (key.startsWith("sub_") && value?.title === "cabin") {
          setCabinData(JSON.parse(value?.content)); // Set the matching object in state
          setCabinIndex(key.slice(-1)); // Set the index in state
        }
      });
    }
  }, [subCard?.data?.data, isLoading, isSuccess]);

  useEffect(() => {
    if (cardData) {
      setCruiseData(JSON.parse(cardData?.content));
    }
  }, [cardData]);

  useEffect(() => {
    if (langLoading) {
      // console.log("loading")
    } else {
      setCurrentBlogLanguages(
        lang?.data?.data?.filter(
          (item) => item.blogId === data?.data?.data?.blogId
        )
      );
    }
  }, [lang, langLoading, data?.data?.data?.blogId]);

  const [newTitle, setNewTitle] = useState(data?.data?.data?.title || "");
  useEffect(() => {
    setNewTitle(data?.data?.data?.title);
  }, [data?.data?.data?.title, isLoading]);

  const { mutate, isPending } = useMutation({
    mutationFn: (data) => {
      return axios.post(`${api_url}tour/${tourId}`, data, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
    },
    onSuccess: (data) => {
      console.log("Edit tour successful", data);
      notifySuccess("Edit tour successful");
      refetchTour();
      setEditTitle(false);
    },
    onError: (error) => {
      console.error("Edit tour failed:", error);
      notifyError(error?.response?.data?.message);
    },
  });

  const handleSave = () => {
    mutate({
      title: newTitle,
      _method: "PUT",
    }); // Send the new title via mutation
  };

  return (
    <>
      {langLoading ? (
        <div className={langCardBlog.loading}>
          <Loading color={COLORS.DANGER} />
        </div>
      ) : currentBlogLanguages.length === 0 ? (
        <div className={langCardBlog.empty}>
          <Empty text={"There is no Blog languages"} />
          <AddLang
            blogID={data?.data?.data?.blogId}
            refetch={langRefetch}
            inEmpty
          />
        </div>
      ) : !langAtom ? (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <AddLang
              blogID={data?.data?.data?.blogId}
              refetch={langRefetch}
              inEmpty
            />
          </div>
          <div className={langCardBlog.langCardsContainer}>
            {currentBlogLanguages.map((lang, index) => (
              <LangCard
                id={lang._id}
                index={index}
                refetch={langRefetch}
                lang={lang}
                title={data?.data?.data?.title}
                setLang={setLangAtom}
              >
                {lang.language}
              </LangCard>
            ))}
          </div>
        </>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            gap: 5,
          }}
        >
          <AddLang
            blogID={data?.data?.data?.blogId}
            refetch={langRefetch}
            inEmpty
          />
          <div style={{ height: "auto" }}>
            <button
              className={`button-primary`}
              type="button"
              onClick={() => setLangAtom(undefined)}
            >
              <MdEdit />
              choose Language
            </button>
          </div>
        </div>
      )}
      {langAtom && (
        <main className="mainContainer">
          <div className={style.container}>
            <h1>
              {editTitle ? (
                <input
                  type="text"
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                  placeholder="Enter tour name"
                />
              ) : (
                data?.data?.data?.title
              )}
              <span className={style.editContainer}>
                {editTitle ? (
                  <>
                    <FaCheck
                      title="Save Changes"
                      onClick={handleSave}
                      disabled={isPending}
                    />
                    <IoCloseCircle
                      title="Cancel Changes"
                      onClick={() => setEditTitle(false)}
                    />
                  </>
                ) : (
                  <MdEdit
                    title="Edit tour title"
                    onClick={() => setEditTitle(true)}
                  />
                )}
              </span>
            </h1>
            <div className={style.details}>
              <div className={style.leftSection}>
                <div className={style.images}>
                  <EditTourImages data={images} lang={langAtom} />
                </div>
                <div className={style.overview}>
                  <h2>
                    Overview{" "}
                    <span className={style.editContainer}>
                      {editOverview ? (
                        <IoCloseCircle
                          title="Cancel Changes"
                          onClick={() => setEditOverview(!editOverview)}
                        />
                      ) : (
                        <button
                          className={`button-primary ${style.edit}`}
                          type="button"
                          onClick={() => setEditOverview(!editOverview)}
                        >
                          <MdEdit />
                          Edit
                        </button>
                      )}
                    </span>
                  </h2>
                  <Overview edit={editOverview} data={data} lang={langAtom} />
                </div>
              </div>
            </div>
            <>
              <div className={style.editButton}>
                <span className={style.editContainer}>
                  {editIncludeExclude ? (
                    <IoCloseCircle
                      title="Cancel Changes"
                      onClick={() => setEditIncludeExclude(!editIncludeExclude)}
                    />
                  ) : (
                    <button
                      className={`button-primary ${style.edit}`}
                      type="button"
                      onClick={() => setEditIncludeExclude(!editIncludeExclude)}
                    >
                      <MdEdit />
                      Edit
                    </button>
                  )}
                </span>
              </div>
              <IncludeExclude lang={langAtom} edit={editIncludeExclude} />
            </>
            <div className={style.itinerary}>
              <h2>
                Itinerary{" "}
                <span className={style.editContainer}>
                  {editItinerary ? (
                    <IoCloseCircle
                      title="Cancel Changes"
                      onClick={() => setEditItinerary(!editItinerary)}
                    />
                  ) : (
                    <button
                      className={`button-primary ${style.edit}`}
                      type="button"
                      onClick={() => setEditItinerary(!editItinerary)}
                    >
                      <MdEdit />
                      Edit
                    </button>
                  )}
                </span>
              </h2>
              {/* <div className={style.tabs}>
                         <ul>
                             {itineraries?.map((itinerary, index) => (
                                 <li
                                     key={index}
                                     onClick={() => { setTab(itinerary.title); setCurrentItineraries(itinerary) }}
                                     className={(tab === itinerary.title) && style.active}
                                 >
                                     {itinerary.title} <IoClose />
                                 </li>
                             ))}
                             <div onClick={() => setAddItinerary(true)}><FaPlus /></div>
                         </ul>
                     </div> */}
              <div className={style.page}>
                <EditItineraryCard
                  tourId={tourId}
                  edit={editItinerary}
                  itinerary={currentItineraries}
                />
              </div>
            </div>
            <div className={style.cruise}>
              <h2>
                Cruise{" "}
                <span className={style.editContainer}>
                  <button
                    className={`button-primary ${style.edit}`}
                    type="button"
                    onClick={() => setEditCruise(!editCruise)}
                  >
                    <MdEdit />
                    Edit
                  </button>
                </span>
              </h2>
              <p>{!isSubCardLoading && cruiseData.description}</p>
              <h4>Number of Rooms</h4>
              {!isSubCardLoading &&
                (cruiseData?.NumOfRooms?.length ? (
                  <ul>
                    {cruiseData.NumOfRooms.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                ) : (
                  <Empty text={"There are no rooms available"} />
                ))}
            </div>
            <div className={style.cabin}>
              <h2>
                Cabin{" "}
                <span className={style.editContainer}>
                  <button
                    className={`button-primary ${style.edit}`}
                    type="button"
                    onClick={() => setEditCabin(!editCabin)}
                  >
                    <MdEdit />
                    Edit
                  </button>
                </span>
              </h2>
              {!isSubCardLoading &&
                (cabinData?.newCabin?.length ? (
                  <ul>
                    {cabinData.newCabin.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                ) : (
                  <Empty text={"There is no Cabin content"} />
                ))}
            </div>

            <div className={style.prices}>
              <h2>
                Prices{" "}
                <span className={style.editContainer}>
                  {editPrice ? (
                    <>
                      <button
                        className={`button-primary ${style.edit}`}
                        type="button"
                        onClick={() => setAddPrice(!addPrice)}
                      >
                        Add Card
                      </button>
                      <IoCloseCircle
                        title="Cancel Changes"
                        onClick={() => setEditPrice(!editPrice)}
                      />
                    </>
                  ) : (
                    <button
                      className={`button-primary ${style.edit}`}
                      type="button"
                      onClick={() => setEditPrice(!editPrice)}
                    >
                      <MdEdit />
                      Edit
                    </button>
                  )}
                </span>
              </h2>
              <div className={style.PricesCardsContainer}>
                {pricing?.data?.data?.data &&
                  pricing?.data?.data?.data?.map((priceCard) => (
                    <EditPricesCard edit={editPrice} priceCard={priceCard} />
                  ))}
              </div>
            </div>
          </div>
        </main>
      )}
      {addItinerary && (
        <Modal isAppear onClose={() => setAddItinerary(false)}>
          <AddItinerary />
        </Modal>
      )}
      {editCruise && (
        <Modal isAppear onClose={() => setEditCruise(false)}>
          <EditCruise
            cardData={cruiseData}
            cardIndex={cardIndex}
            subCard={subCard}
            refetch={refetch}
            lang={langAtom}
          />
        </Modal>
      )}
      {editCabin && (
        <Modal isAppear onClose={() => setEditCabin(false)}>
          <EditCabin
            cabin={cabinData}
            cardIndex={cabinIndex}
            refetch={refetch}
            lang={langAtom}
          />
        </Modal>
      )}
      {addPrice && (
        <Modal isAppear onClose={() => setAddPrice(false)}>
          <AddPriceCard
            card={prices}
            tourId={tourId}
            refetch={pricingRefetch}
          />
        </Modal>
      )}
    </>
  );
};

export default DashboardTourPage;

const AddItinerary = () => {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    const formData = {
      ...data, // Collect registered form values
    };
    console.log("Form Data:", formData);
  };

  return (
    <Form
      title="Add New Itinerary"
      className={style.addItineraryForm}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Form.TextController
        placeholder="Enter Itinerary Title"
        name="Itinerary_title"
        register={register}
        registername="Itinerary_title"
      />

      <h2>Itinerary Position</h2>
      <Form.TextController
        placeholder="Enter Itinerary lat"
        name="Itinerary_lat"
        register={register}
        registername="Itinerary_lat"
      />
      <Form.TextController
        placeholder="Enter Itinerary lng"
        name="Itinerary_lng"
        register={register}
        registername="Itinerary_lng"
      />
      <Form.ButtonController type="submit">Add</Form.ButtonController>
    </Form>
  );
};

const AddPriceCard = ({ tourId, refetch }) => {
  const [title, setTitle] = useState("");

  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const [label, setLabel] = useState("");
  const [price, setPrice] = useState("");

  const [cards, setCards] = useState([]);
  const [items, setItems] = useState([]);

  const [numberOfAdults, setNumberOfAdults] = useState("");
  const [numberOfChildren, setNumberOfChildren] = useState("");
  const [special_offer, setSpecial_offer] = useState("");

  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const handleDeleteItem = (indexToDelete) => {
    const updatedList = items.filter((_, index) => index !== indexToDelete);
    setItems(updatedList);
  };

  const handleAddItem = () => {
    if (label.trim() && price.trim()) {
      setItems([...items, { label, price }]);
      setLabel("");
      setPrice("");
    }
  };

  const handleAddCard = () => {
    if (start.trim() && end.trim()) {
      setCards([...cards, { start, end, items }]);
      console.log(cards);
      // setStart('');
      // setEnd('');
    }
  };

  const { mutate, isPending } = useMutation({
    mutationFn: (data) => {
      return axios.post(
        `${api_url}tourPricing?tour_id=${tourId}&number_of_adults=${numberOfAdults}&number_of_children=${numberOfChildren}&price_per_adult=${
          data?.price_per_adult
        }&price_per_child=${data?.price_per_child}&name=${
          data?.name
        }&available_from=${data?.available_from}&available_to=${
          data?.available_to
        }&special_offer=${data?.special_offer || 0}`,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
    },
    onSuccess: (data) => {
      console.log("pricing added successful", data);
      notifySuccess("pricing add successful");
      refetch();
    },
    onError: (error) => {
      console.error("add pricing failed:", error);
      notifyError(error?.response?.data?.message);
    },
  });

  const onSubmit = (data) => {
    const itemsObject = items.reduce((acc, item) => {
      acc[item.label] = item.price; // Set label as key and price as value
      return acc;
    }, {});
    const formData = {
      ...data, // Collect registered form values
      name: title, // Add title from state
      numberOfAdults,
      numberOfChildren,
      ...itemsObject,
      special_offer,
    };
    // console.log('Form Data:', formData);
    mutate(formData);
    // You can send this formData to the backend via an API call here
  };

  return (
    <Form
      title="Add New Tour Prices"
      className={style.addPricesForm}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Form.TextController
        placeholder="Card Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <h2>Prices</h2>
      <div className={style.pricesContainer}>
        <div className={style.tourDuration}>
          {/* <Form.SelectController placeholder="Starting"
                     options={months} value={start}
                      onChange={(e) => setStart(e.target.value)} 
                      /> */}
          <Form.DateTimeController
            label="start Date"
            register={register}
            registername="available_from"
          />
          {/* <p>-</p> */}
          {/* <Form.SelectController placeholder="Ending" options={months} value={end} onChange={(e) => setEnd(e.target.value)} /> */}
          <Form.DateTimeController
            label="Ending date"
            register={register}
            registername="available_to"
          />
        </div>
        <div className={style.pricesDetails}>
          {items.length ? (
            <ul>
              {items.map((item, index) => (
                <li key={index}>
                  <p>{item.label}</p>
                  <p>{item.price}</p>
                  <IoCloseCircle onClick={() => handleDeleteItem(index)} />
                </li>
              ))}
            </ul>
          ) : (
            <EmptyNoItems />
          )}
          <div>
            <select
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              className="dropdown"
              style={{ marginRight: "10px" }}
            >
              <option value="" disabled>
                Select Label
              </option>
              <option value="price_per_child">Price per Child</option>
              <option value="price_per_adult">Price per Adult</option>
            </select>

            <input
              type="text"
              placeholder="Price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />

            <button
              type="button"
              className="button-primary"
              onClick={handleAddItem}
            >
              Add Item
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            marginTop: "20px",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <input
            type="number"
            placeholder="Number of Adults"
            value={numberOfAdults}
            onChange={(e) => setNumberOfAdults(e.target.value)}
            style={{ width: "40%" }}
          />
          <input
            type="number"
            placeholder="Number of Children"
            value={numberOfChildren}
            onChange={(e) => setNumberOfChildren(e.target.value)}
            style={{ width: "40%" }}
          />
          <input
            type="number"
            placeholder="special offer"
            value={special_offer}
            onChange={(e) => setSpecial_offer(e.target.value)}
            style={{ width: "40%" }}
          />
        </div>
        {/* <button
                    type='button'
                    className='button-primary'
                    onClick={handleAddCard}
                    style={{ width: "100%", marginTop: "20px" }}
                >
                    Add
                </button> */}
      </div>
      {/* {cards.map((i)=><p>{i?.title}</p>)} */}
      <Form.ButtonController isLoading={isPending} type="submit">
        {isPending ? <Loading color={COLORS.DANGER} /> : "Add"}
      </Form.ButtonController>
    </Form>
  );
};

const EditCruise = ({ cardData, cardIndex, lang, subCard, refetch }) => {
  const [newCruise, setNewCruise] = useState(
    cardData
      ? { description: cardData.description, NumOfRooms: cardData.NumOfRooms }
      : { description: "", NumOfRooms: [] }
  );

  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const { mutate, isPending } = useMutation({
    mutationFn: (data) => {
      const url =
        cardData.length > 0
          ? `${api_url}blogLanguage/updateSubCard`
          : `${api_url}blogLanguage/subCard`;

      return axios.post(url, data, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
    },
    onSuccess: (data) => {
      console.log("sub card add successful", data);
      notifySuccess("sub card add successful");
      refetch();
    },
    onError: (error) => {
      console.error("add card failed:", error);
      notifyError(error?.response?.data?.message);
    },
  });

  const onSubmit = (data) => {
    const payload = {
      title: "cruise",
      blogLanguageId: lang,
      content: JSON.stringify({
        description: data.cruiseDescription,
        NumOfRooms: newCruise.NumOfRooms,
      }),
      ...(cardData.length > 0 && { _method: "PUT", index: cardIndex }),
    };
    mutate(payload);
  };

  return (
    <Form className={style.addCruiseForm} onSubmit={handleSubmit(onSubmit)}>
      <Form.TextareaController
        placeholder="Enter cruise description"
        value={newCruise.description}
        onChange={(e) =>
          setNewCruise({ ...newCruise, description: e.target.value })
        }
        name="cruiseDescription"
        register={register}
        registername="cruiseDescription"
      />
      <h2>Number of Rooms</h2>
      <Form.AddListController
        placeholder="Add rooms details"
        value={newCruise.NumOfRooms}
        onChange={(updatedCruise) =>
          setNewCruise({ ...newCruise, NumOfRooms: updatedCruise })
        }
        name="roomsDetails"
        register={register}
        registername="roomsDetails"
      />
      <Form.ButtonController isLoading={isPending} type="submit">
        {isPending ? <Loading color={COLORS.DANGER} /> : "Save"}
      </Form.ButtonController>
    </Form>
  );
};

const EditCabin = ({ cabin, cardIndex, lang, refetch }) => {
  const [newCabin, setNewCabin] = useState(
    cabin?.newCabin ? cabin?.newCabin : []
  );
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const { mutate, isPending } = useMutation({
    mutationFn: (data) => {
      const url =
        cabin.length > 0
          ? `${api_url}blogLanguage/updateSubCard`
          : `${api_url}blogLanguage/subCard`;

      return axios.post(url, data, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
    },
    onSuccess: (data) => {
      console.log("sub card add successful", data);
      notifySuccess("sub card add successful");
      refetch();
    },
    onError: (error) => {
      console.error("add card failed:", error);
      notifyError(error?.response?.data?.message);
    },
  });

  const onSubmit = (data) => {
    const payload = {
      title: "cabin",
      blogLanguageId: lang,
      content: JSON.stringify({
        newCabin: newCabin,
      }),
      ...(cabin.length > 0 && { _method: "PUT", index: cardIndex }),
    };
    mutate(payload);
  };

  return (
    <Form className={style.addCabinForm} onSubmit={handleSubmit(onSubmit)}>
      <Form.AddListController
        placeholder="Add Cabin"
        value={newCabin}
        onChange={(updatedCabin) => setNewCabin(updatedCabin)}
      />
      <Form.ButtonController isLoading={isPending} type="submit">
        {isPending ? <Loading color={COLORS.DANGER} /> : "Save"}
      </Form.ButtonController>
    </Form>
  );
};
