import { useState } from 'react';
import packageStyle from "./dashboardPackages.module.css"
import "../../dashboardIndex.css"
import Table from './../../components/customTable/table';
import PageHeader from './../../components/pageHeader/pageHeader';
import CustomButton from '../../components/customButton/customButton';
import { FaChevronLeft } from 'react-icons/fa6';
import { BsPencilSquare } from 'react-icons/bs';
import { BsTrashFill } from 'react-icons/bs';

const DashboardPackages = () => {
  const status = {
    ACTIVE: "active",
    PENDING: "pending",
    EXPIRED: "expired"
  }
  const [packages, _] = useState([
    {
      id: 1,
      name: "Singapore Holiday Tour",
      date: "12 may",
      destination: "Japan",
      status: status.ACTIVE,
    },
    {
      id: 2,
      name: "New Year's Eve in Paris",
      date: "12 may",
      destination: "Japan",
      status: status.ACTIVE,
    },
    {
      id: 3,
      name: "Paris Honeymoon Tour",
      date: "12 may",
      destination: "Japan",
      status: status.ACTIVE,
    },
    {
      id: 4,
      name: "Japan Holiday Tour",
      date: "12 may",
      destination: "Japan",
      status: status.ACTIVE,
    },
    {
      id: 5,
      name: "California Trip",
      date: "12 may",
      destination: "Japan",
      status: status.ACTIVE,
    },
    {
      id: 6,
      name: "Dubai Tour",
      date: "12 may",
      destination: "Japan",
      status: status.ACTIVE,
    },
    {
      id: 7,
      name: "Singapore Holiday Tour",
      date: "12 may",
      destination: "Japan",
      status: status.PENDING,
    },
    {
      id: 8,
      name: "New Year's Eve in Paris",
      date: "12 may",
      destination: "Japan",
      status: status.PENDING,
    },
    {
      id: 9,
      name: "Paris Honeymoon Tour",
      date: "12 may",
      destination: "Japan",
      status: status.PENDING,
    },
    {
      id: 10,
      name: "Japan Holiday Tour",
      date: "12 may",
      destination: "Japan",
      status: status.PENDING,
    },
    {
      id: 11,
      name: "California Trip",
      date: "12 may",
      destination: "Japan",
      status: status.PENDING,
    },
    {
      id: 12,
      name: "Dubai Tour",
      date: "12 may",
      destination: "Japan",
      status: status.PENDING,
    },
    {
      id: 13,
      name: "Singapore Holiday Tour",
      date: "12 may",
      destination: "Japan",
      status: status.EXPIRED,
    },
    {
      id: 14,
      name: "New Year's Eve in Paris",
      date: "12 may",
      destination: "Japan",
      status: status.EXPIRED,
    },
    {
      id: 15,
      name: "Paris Honeymoon Tour",
      date: "12 may",
      destination: "Japan",
      status: status.EXPIRED,
    },
    {
      id: 16,
      name: "Japan Holiday Tour",
      date: "12 may",
      destination: "Japan",
      status: status.EXPIRED,
    },
    {
      id: 17,
      name: "California Trip",
      date: "12 may",
      destination: "Japan",
      status: status.EXPIRED,
    },
    {
      id: 18,
      name: "Dubai Tour",
      date: "12 may",
      destination: "Japan",
      status: status.EXPIRED,
    },
  ]);

  return (
    <main className={packageStyle.mainContainer}>
      <PageHeader description="Airport Hotels The Right Way To Start A Short Break Holiday">Packages List</PageHeader>
      <div className={packageStyle.buttons}> {/* Updated to use styles */}
        <CustomButton>Add New</CustomButton>
        <CustomButton icon={<FaChevronLeft />}>Filter</CustomButton>
      </div>
      <Table className={packageStyle.packagesTable}>
        <Table.HeadController>
          <th>Name</th>
          <th>Date</th>
          <th>Destination</th>
          <th>Status</th>
          <th></th>
          <th>Edit</th>
          <th>Delete</th>
        </Table.HeadController>
        <Table.BodyController>
          {packages.map(pack => (
            <tr>
              <td>{pack.name}</td>
              <td>{pack.date}</td>
              <td>{pack.destination}</td>
              <td><span className={`${pack.status === status.ACTIVE ? packageStyle.active :
                pack.status === status.PENDING ? packageStyle.pending :
                  packageStyle.expire}`}>

                {pack.status}
                
              </span></td>
              <td></td>
              <td className="transitionAnimation"><BsPencilSquare /></td>
              <td className="transitionAnimation"><BsTrashFill /></td>
            </tr>
          ))}
        </Table.BodyController>
      </Table>
    </main>
  );
}

export default DashboardPackages