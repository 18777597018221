import { useState } from 'react';
import destinationStyle from "./dashboardDestinations.module.css"
import Pagination from './../../components/pagination/pagination';

import img1 from "../../../images/jordan.jpg";
import img2 from "../../../images/palestine.jfif";
import img3 from "../../../images/khalifa-dubai.jfif";
import img4 from "../../../images/BesseggenRidge.jpeg";
import img5 from "../../../images/ThailandDisneyLand.jpg";
import img6 from "../../../images/MarinaBaySandCity.jpg";
import img7 from "../../../images/NewZealand.jpg"
import DestinationCard from '../../components/destinationCard/destinationCard';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { api_url } from '../../../constants/base_url';
import Modal from '../../../components/shared/Modal/Modal';
import Form from '../../../components/shared/Form/Form';
import { useForm } from 'react-hook-form';
import Loading from '../../../components/shared/Loading/Loading';
import { notifyError, notifySuccess } from '../../../App';

const DashboardDestinations = () => {
  const [destinations, _] = useState([
    {
      id: 1,
      image: img4,
      country: "Norway",
      content: "Besseggen Ridge",
      rating: '60%',
    },
    {
      id: 2,
      image: img5,
      country: "Thailand",
      content: "Disney Land",
      rating: '20%',
    },
    {
      id: 3,
      image: img6,
      country: "Singapore",
      content: "Marina Bay Sand City",
      rating: '40%',
    },
    {
      id: 4,
      image: img7,
      country: "New Zealand",
      content: "Oxolotan City",
      rating: '80%',
    },
    {
      id: 5,
      image: img3,
      country: "Dubai",
      content: "khalifa tower",
      rating: '20%',
    },
    {
      id: 6,
      image: img2,
      country: "Palestine",
      content: "Al-Aqsa Mosque",
      rating: '100%',
    },
    {
      id: 7,
      image: img1,
      country: "Jordan",
      content: "jordan Capital",
      rating: '60%',
    },
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;



  const totalPages = Math.ceil(destinations.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['destinations'],
    queryFn: () =>
      axios.get(`${api_url}destinations`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
    onSuccess: (response) => {
      console.log("Data fetched successfully:", response.data);
    },
    onError: (err) => {
      console.error("Error fetching data:", err);
    }
  });


  return (
    <main className='mainContainer'>
      <div className={destinationStyle.addDestination}>
        <AddDestination refetch={refetch} />
        {/* <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        /> */}
      </div>
      {isLoading ?
        <div className={destinationStyle.loading}>
          <Loading />
        </div>
        :
        <div className={destinationStyle.packagesContainer}>
          {data?.data?.data?.map((destination) => (
            <DestinationCard
              key={destination.id}
              id={destination.id}
              image={`${api_url}${destination.image}`.replace("/api/", "/storage/")}
              country={destination.name}
              refetch={refetch}
            // content={destination.content}
            // rating={destination.rating}
            />
          ))}
        </div>
      }
    </main>
  );
}

export default DashboardDestinations



const AddDestination = ({refetch}) => {
  const [openAddDestinationBox, setOpenAddDestinationBox] = useState(false);

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const { mutate: addDestination, isLoading } = useMutation({
    mutationFn: (data) => {
      return axios.post(`${api_url}destinations`, data, {
        headers: {
          "Accept": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
    },
    onSuccess: (data) => {
      console.log("destination add successful", data);
      setOpenAddDestinationBox(false);
      notifySuccess("Destination Successfully Added");
      refetch();
    },
    onError: (error) => {
      console.error("add destination failed:", error);
      notifyError(error?.response?.data?.message);
    }
  });

  function submit(data) {
    console.log(data)
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("image", data.image);
    addDestination(formData)
  }


  return (
    <>
      <button className='button-primary' onClick={() => setOpenAddDestinationBox(true)}>Add Destination</button>
        <Modal isAppear={openAddDestinationBox} onClose={() => setOpenAddDestinationBox(false)}>
          <Form
            title={"Add Destination"}
            onSubmit={handleSubmit(submit)}
            className={destinationStyle.form}
          >
            <Form.TextController
              register={register}
              registername="name"
              errorMessage={errors?.name && errors?.name?.message}
              validationRules={{
                required: 'Destination name is required',
              }}
              placeholder={"Destination Name"}
            />
            <Form.DragAndDropController
              register={register}
              registername="image"
              setValue={setValue}
              errors={errors}
            />
            <Form.ButtonController type='submit' isLoading={isLoading}>Add</Form.ButtonController>
          </Form>
        </Modal>
    </>
  )
}