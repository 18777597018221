import React, { useState } from 'react'
import style from "./editItineraryCard.module.css"
import Loading from '../../../../components/shared/Loading/Loading';
import { IoTrash } from "react-icons/io5";
import { MdOutlineEdit } from "react-icons/md";

import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import Form from '../../../../components/shared/Form/Form';
import Modal from '../../../../components/shared/Modal/Modal';
import { useForm } from 'react-hook-form';
import { notifyError, notifySuccess } from '../../../../App';
import { useMutation,useQuery,useQueryClient  } from '@tanstack/react-query';
import { api_url } from '../../../../constants/base_url';
import axios from 'axios';
import { useParams } from 'react-router-dom';



const EditItineraryCard = ({tourId, itinerary, edit }) => {
    const [openAddDay, setOpenAddDay] = useState(false);

    const { data:tour_days  } = useQuery({
        queryKey: ['tour_days',tourId],
        queryFn: () =>
            axios.get(`${api_url}tourDay/${tourId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            }),
        onSuccess: (response) => {
            console.log("tour_days:", response.data);
        },
        onError: (err) => {
            console.error("Error fetching data:", err);
        }
    });
    return (
        <>
            <div className={style.itineraryCard}>
                <div className={style.map}>
                    <GoogleMapsComponent tour_days={tour_days?.data} />
                </div>
                <div className={`${style.daysContainer} ${edit && style.edit}`}>
                    {edit ?
                        <div className={style.editDaysContainer}>
                            <div>
                                <button className='button-primary' onClick={() => setOpenAddDay(!openAddDay)}>Add Day</button>
                            </div>
                            <div>
                                {tour_days?.data?.map((day) => <DaySection edit={edit} day={day}  />)}
                            </div>
                        </div>
                        :
                        <div>
                            {tour_days?.data?.map((day) => <DaySection day={day} />)}
                        </div>
                    }
                </div>
            </div>
            {openAddDay &&
                <Modal isAppear onClose={() => setOpenAddDay(false)}>
                    <AddDay />
                </Modal>
            }
        </>
    )
}

export default EditItineraryCard


const DaySection = ({ edit, day }) => {
    const { description, title, location ,id} = day
    const [editDay, setEditDay] = useState(false);

    const queryClient = useQueryClient();

    const { mutate} = useMutation({
        mutationFn: (data) => {
            return axios.delete(`${api_url}tourDay/${id}`,{}, {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            });
        },
        onSuccess: (data) => {
            console.log("delete day successful", data);
            notifySuccess("delete day successful");
            queryClient.refetchQueries(['tour_days']);
    
        },
        onError: (error) => {
            console.error("delete day failed:", error);
            notifyError(error?.response?.data?.message);
        }
    });
    const handleDelete = () => {
            mutate(id); 
    };

    return (
        <>
            <div className={style.daySectionContainer}>
                <div>
                    <h3>{title}</h3>
                    <ProgramDetailsCard programDetails={JSON.parse(description)} />
                    <h4>Meals:<span>{JSON.parse(description)?.meals}</span></h4>
                    <h4>visits:<span>{location}</span></h4>
                </div>
                {edit ? <div>
                    <div onClick={() => setEditDay(true)}>
                        <MdOutlineEdit />
                        </div>
                        <div onClick={handleDelete}>
                            <IoTrash />
                        </div>
                    </div> : <span></span>}
            </div>
            {editDay &&
                <Modal isAppear onClose={() => setEditDay(false)}>
                    <AddDay edit={day} />
                </Modal>
            }
        </>
    )
}


const ProgramDetailsCard = ({ programDetails }) => {
    const { details, summary } = programDetails;
    return (
        <div className={style.programDetailsContainer}>
            {details.map((item) => <p>- {item}</p>)}
            <h4>{summary}</h4>
        </div>
    )
}



const GoogleMapsComponent = ({ tour_days }) => {

  const containerStyle = {
      width: '100%',
      height: '100%'
  };

  const center = {
      lat: 30.0444, // Center near Cairo
      lng: 31.2357
  };

  const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: "AIzaSyDz45PchlXICnYE-3Q9Kz5vWHrR0nzzWkw"
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback((map) => {
      const bounds = new window.google.maps.LatLngBounds(center);
      map.fitBounds(bounds);
      setMap(map);
  }, []);

  const onUnmount = React.useCallback((map) => {
      setMap(null);
  }, []);

  // Function to convert location names to lat/lng
  const getLatLng = (location) => {
      const locations = {
          "cairo": { lat: 30.0444, lng: 31.2357 },
          "elgiza": { lat: 30.0131, lng: 31.2089 },
          // Add more locations as needed
      };
      return locations[location.toLowerCase()] || center; // Fallback to center if location not found
  };

  return isLoaded ? (
      <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={12} // Adjusted zoom level
          onLoad={onLoad}
          onUnmount={onUnmount}
      >
          {tour_days && tour_days.map((day) => {
              const { location } = day;
              const { lat, lng } = getLatLng(location); // Get latitude and longitude


              return (
                  <Marker
                      key={day.id}
                      position={{ lat, lng }}
                      title={day.title} // You can also add a title or description
                  />
              );
          })}
      </GoogleMap>
  ) : (
      <div className={style.loading}><Loading /></div>
  );
};




const AddDay = ({ edit }) => {
    const { tourId } = useParams();
  
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({
      defaultValues: edit
        ? {
            day_number: edit.day_order, // use day_order from edit
            label: edit.title, // use title from edit
            details: JSON.parse(edit.description).details || [], // parse description to get details
            summary: JSON.parse(edit.description).summary || '', // parse description to get summary
            meals: JSON.parse(edit.description).meals || '', // parse description to get meals
            visits: edit.location || '', // use location from edit
          }
        : {
            day_number: 1,
            label: '',
            details: [],
            summary: '',
            meals: '',
            visits: '',
          },
    });
  
    const [newDay, setNewDay] = useState(
      edit
        ? {
            label: edit.title,
            details: JSON.parse(edit.description).details || [],
            summary: JSON.parse(edit.description).summary || '',
            meals: JSON.parse(edit.description).meals || '',
            visits: edit.location || '',
            day_number: edit.day_order,
          }
        : { day_number: 1, label: '', details: [], summary: '', meals: '', visits: '' }
    );
  
    const queryClient = useQueryClient();


  
    const { mutate, isPending:isLoading } = useMutation({
    mutationFn: (data) => {
      // Check if edit prop exists to determine request type
      if (edit) {
        return axios.put(`${api_url}tourDay/${edit.id}`, data, {
          headers: {
            "Accept": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
      } else {
        return axios.post(`${api_url}tourDay/${tourId}`, data, {
          headers: {
            "Accept": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
      }
    },
    onSuccess: (data) => {
      console.log("Added/Updated day successful", data);
      notifySuccess(edit ? "Updated day successfully" : "Added day successfully");
      queryClient.refetchQueries(['tour_days']);
    },
    onError: (error) => {
      console.error("Add/Update day failed:", error);
      notifyError(error?.response?.data?.message);
    }
  });
  
    const onSubmit = (data) => {
      const formData = {
        title: data.label,
        day_order: data.day_number,
        location: data.visits,
        description: JSON.stringify({
          details: newDay.details,
          summary: data.summary,
          meals: data.meals,
        }),
      };
      console.log('Form Data:', formData);
      mutate(formData);
    };
  
    return (
      <div style={{ height: '70vh', width: '50vw', padding: '0 30px', overflowY: 'auto' }}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.TextController
            placeholder="Day Number"
            type="number"
            name="day_number"
            register={register}
            registername="day_number"
            errorMessage={errors?.day_number && errors?.day_number?.message}
            validationRules={{
              required: 'Day number is required',
            }}
          />
  
          <Form.TextController
            placeholder="Label"
            name="label"
            register={register}
            registername="label"
            errorMessage={errors?.label && errors?.label?.message}
            validationRules={{
              required: 'Label is required',
            }}
          />
  
          <Form.AddListController
            placeholder="Enter details to add"
            value={newDay.details}
            onChange={(updatedDetails) => setNewDay({ ...newDay, details: updatedDetails })}
          />
  
          <Form.TextController
            placeholder="Summary"
            name="summary"
            register={register}
            registername="summary"
            errorMessage={errors?.summary && errors?.summary?.message}
            validationRules={{
              required: 'Summary is required',
            }}
          />
  
          <Form.TextController
            placeholder="Meals"
            name="meals"
            register={register}
            registername="meals"
            errorMessage={errors?.meals && errors?.meals?.message}
            validationRules={{
              required: 'Meals are required',
            }}
          />
  
          <Form.TextController
            placeholder="Visits"
            name="visits"
            register={register}
            registername="visits"
            errorMessage={errors?.visits && errors?.visits?.message}
            validationRules={{
              required: 'Visits are required',
            }}
          />
  
          <Form.ButtonController type="submit" isLoading={isLoading}>{edit?"Edit Day":"Add Day"}</Form.ButtonController>
        </Form>
      </div>
    );
  };