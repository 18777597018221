import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useState } from 'react';
import { api_url } from '../../../../constants/base_url'
import DashboardLanguageItem from './DashboardLanguageItem'
import { notifyError, notifySuccess } from '../../../../App'
import { IoMdAddCircle } from "react-icons/io";
import home from "../dashboard.module.css"


const DashboardLanguages = () => {
    const [language, setLanguage] = useState("")
    const queryClient = useQueryClient()
    const {data, isPending} = useQuery({
        queryKey:["allLanguages"],
        queryFn:() => axios.get(`${api_url}language`,{
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        })
    })

    const {mutate, isPending: isCreating} = useMutation({
        mutationFn: (data) => axios.post(`${api_url}language`,data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        }),
        onSuccess: () => {
            notifySuccess("Language added successfully!")
            queryClient.invalidateQueries(["allLanguages"])
            setLanguage("")
        },
        onError: () => {
            notifyError("Something went wrong")
        }
    })
  return !isPending && (
    <div style={{width:"100%"}}>
        <div className={home.heading}>
            <h3>Languages</h3>
        </div>
        <div style={{display:"flex", alignItems:"center", justifyContent:"center", gap:"15px", width:"100%", height:"40px", margin:"10px 0"}}>
            <input type='text' placeholder='Add language' style={{width:"90%"}} maxLength={3} value={language} onChange={(e) => {
                setLanguage(e.target.value)
            }}/>
            <button disabled={!language || isCreating} style={{width:"10%", height:"40px", border:"none", outline:"none", fontSize:"1.6rem", backgroundColor:"#d51c29", display:"flex", alignItems:"center", justifyContent:"center", color:"white", borderRadius:"12px"}} onClick={() => {
                mutate({name:language})
            }}><IoMdAddCircle /></button>
        </div>
        <div style={{maxHeight:"200px", overflowY:"auto"}}>
        {data?.data?.data?.sort((a, b) => a.name === "en" ? -1 : b.name === "en" ? 1 : 0).map((item, index) => <DashboardLanguageItem key={index} lang={item}/>)}
        </div>
    </div>
  )
}

export default DashboardLanguages