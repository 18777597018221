import img8 from "../../images/img8.jpg";
import TabContainer from "./TabContainer";

const SingleTourContent = ({img,days,people,location}) => {
  return (
    <div className="single-tour-inner">
      <h2>EXPERIENCE THE NATURAL BEAUTY OF ISLAND</h2>
      <figure className="feature-image">
        <img src={img8} width="100%" alt="" />
        <div className="package-meta text-center">
          <ul>
            <li>
              <i className="far fa-clock"></i> 6 days / 5 night
            </li>
            <li>
              <i className="fas fa-user-friends"></i> People: 4
            </li>
            <li>
              <i className="fas fa-map-marked-alt"></i> Norway
            </li>
          </ul>
        </div>
      </figure>
      <TabContainer />
      {/* Add other content as needed */}
    </div>
  );
};

export default SingleTourContent;
