import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "./shared/Modal/Modal";


const formatDate = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return formattedDate;
};

const ArchiveCard = ({ id, imgSrc, title, date, comments, children, language }) => {
  const navigate = useNavigate();
  const [openBox, setOpenBox] = useState(false)
  const handleClick = (e) => {
    if (language === null) {
      e.preventDefault();
      setOpenBox(true);
    } else {
      navigate(`/blog/${id}`, { state: { language } });
    }
  };
  return (
    <>
      <article class="post">
        <figure class="feature-image">
          <a>
            <img src={imgSrc} alt="" />
          </a>
        </figure>
        <div class="entry-content">
          <h3>
            <a onClick={handleClick} style={{cursor:"pointer"}}>{title}</a>
          </h3>
          <div class="entry-meta">
            <span class="posted-on">
              <a >{formatDate(date)}</a>
            </span>
            <span class="comments-link">
              <a >{comments}</a>
            </span>
          </div>
          {/* <p>{children}...</p> */}
          {/* <a href="blog-single.html" class="button-text">
            ...
          </a> */}
        </div>
      </article>
      <Modal isAppear={openBox} onClose={() => setOpenBox(false)}>
        <h2 style={{textAlign: 'center'}}>Choose Language First</h2>
      </Modal>
    </>
  );
};

export default ArchiveCard;
