import { useState } from "react";
import img8 from "../images/img8.jpg";
import icon1 from "../images/icon1.png";
import icon2 from "../images/icon2.png";
import icon3 from "../images/icon3.png";
import icon4 from "../images/icon4.png";
import icon5 from "../images/icon5.png";
import video from "../images/Sequence.mp4";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

const CounterItem = ({ iconSrc, counter, text }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <div className="counter-item" ref={ref}>
      <div className="counter-icon">
        <img src={iconSrc} alt="" />
      </div>
      <div className="counter-content">
        <span className="counter-no">
          <span className="counter">
            {inView ? <CountUp end={counter} duration={2} /> : 0}
          </span>
          K+
        </span>
        <span className="counter-text">{text}</span>
      </div>
    </div>
  );
};

// SupportArea component
const SupportArea = ({ iconSrc, phone }) => (
  <div className="support-area">
    <div className="support-icon">
      <img src={iconSrc} alt="" />
    </div>
    <div className="support-content">
      <h4>Our 24/7 Emergency Phone Services</h4>
      <h3>
        <Link to="tel:+201033973047">Call: {phone}</Link>
      </h3>
    </div>
  </div>
);

// CallbackInner component
const CallbackInner = () => (
  <div className="callback-inner">
    <div className="section-heading section-heading-white">
      <h5 className="dash-style">CALLBACK FOR MORE</h5>
      <h2>GO TRAVEL. DISCOVER. REMEMBER US!!</h2>
      <p>
        We are committed to curate and flawlessly execute travel experiences that allow travelers to explore the world with ease and create memories that last a lifetime
      </p>
    </div>
    <div className="callback-counter-wrap">
      <CounterItem iconSrc={icon1} counter={500} text="Satisfied Clients" />
      <CounterItem iconSrc={icon2} counter={250} text="Satisfied Clients" />
      <CounterItem iconSrc={icon3} counter={15} text="Satisfied Clients" />
      <CounterItem iconSrc={icon4} counter={10} text="Satisfied Clients" />
    </div>
    <SupportArea iconSrc={icon5} phone="+201033973047" />
  </div>
);

// CallbackImg component
const CallbackImg = ({ backgroundImage }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = (id) => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };
  return (
    <>
      <div
        className="callback-img"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="video-button">
          <a
            id="video-container"
            data-video-id="IUN664s7N-c"
            onClick={() => handleShow("IUN664s7N-c")}
          >
            <i className="fas fa-play"></i>
          </a>
        </div>
        <Modal show={showModal} handleClose={handleClose} videoSrc={video} />
      </div>
    </>
  );
};

// Main CallbackSection component
const CallbackSection = () => (
  <section className="callback-section">
    <div className="container-fluid">
      <div className="row no-gutters align-items-center">
        <div className="col-lg-5">
          <CallbackImg backgroundImage={img8} />
        </div>
        <div className="col-lg-7">
          <CallbackInner />
        </div>
      </div>
    </div>
  </section>
);

export default CallbackSection;

const Modal = ({ show, handleClose, videoSrc }) => {
  const showHideClassName = show ? " display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <button onClick={handleClose} className="close-button p-3">
          X
        </button>
        <video width="100%" height="100%" controls>
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </section>
    </div>
  );
};
