import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { api_url } from '../../../../constants/base_url';
import { notifyError, notifySuccess } from '../../../../App';
import Modal from '../../../../components/shared/Modal/Modal';
import Form from '../../../../components/shared/Form/Form';
import style from "../dashboardBlogs.module.css"

const AddBlog = ({ refetch }) => {
    const [openAddBlogBox, setOpenAddBlogBox] = useState(false);

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const { mutate: addBlog, isLoading } = useMutation({
        mutationFn: (data) => {
            return axios.post(`${api_url}blog`, data, {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            });
        },
        onSuccess: (data) => {
            console.log("Blog add successful", data);
            setOpenAddBlogBox(false);
            notifySuccess("Blog Successfully Added");
            refetch();
        },
        onError: (error) => {
            console.error("add blog failed:", error);
            notifyError(error?.response?.data?.message);
        }
    });


    function submit(data) {
        const formData = new FormData();
        formData.append("title", data.title);

        addBlog(formData)
    }


    return (
        <>
            <button className='button-primary' onClick={() => setOpenAddBlogBox(true)}>Add Blog</button>
            <Modal isAppear={openAddBlogBox} onClose={() => setOpenAddBlogBox(false)}>
                <Form
                    title={"Add Blog"}
                    onSubmit={handleSubmit(submit)}
                    className={style.form}
                >
                    <Form.TextController
                        register={register}
                        registername="title"
                        placeholder={"Blog title"}
                        errorMessage={errors?.title && errors?.title?.message}
                        validationRules={{
                            required: 'Blog title is required',
                        }}
                    />
                    <Form.ButtonController type='submit' isLoading={isLoading}>Add</Form.ButtonController>
                </Form>
            </Modal>
        </>
    )
}

export default AddBlog