import img23 from "../images/img23.jpg";
import Slider from "react-slick";
import img20 from "../images/img20.jpg";
import img21 from "../images/img21.jpg";
import img22 from "../images/img22.jpg";

const TestimonialItem = ({ imgSrc, quote, name, company }) => (
  <div className="testimonial-item text-center">
    <figure className="testimonial-img">
      <img src={imgSrc} alt="" />
    </figure>
    <div className="testimonial-content">
      <p>{quote}</p>
      <cite>
        {name}
        <span className="company">{company}</span>
      </cite>
    </div>
  </div>
);

const TestimonialSection = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <div
      className="testimonial-section"
      style={{ backgroundImage: `url(${img23})` }}
    >
      <div className="container-fluid">
        <div className="row my-4">
          <div className="col-lg-10 offset-lg-1">
            <Slider
              className="testimonial-inner testimonial-slider"
              {...settings}
            >
              <TestimonialItem
                imgSrc={img20}
                quote="I had the most incredible experience with Album
                      Travel! From start to finish, their team was
                      professional, attentive, and went above and beyond
                      to ensure my trip was perfect. They customized an
                      itinerary that fit my interests and budget perfectly,
                      including unique local experiences that I never
                      would have found on my own. The accommodations
                      were top-notch, and their 24/7 support made me
                      feel secure throughout my travels."
                name="Lily Zhang"
                company="Travel Agent"
              />
              <TestimonialItem
                imgSrc={img21}
                quote="Album Travel made our dream vacation a reality! From the moment we contacted them, they provided exceptional service, listening to our preferences and creating a bespoke itinerary that exceeded our expectations. Every detail was meticulously planned, from transportation and hotels to excursions and dining reservations. Their insider knowledge and local connections ensured we had authentic experiences we would have missed otherwise."
                name="Mahmoud Attia"
                company="Travel Agent"
              />
              <TestimonialItem
                imgSrc={img22}
                quote="Our family trip organized by Album Travel was nothing short of fantastic. The agency's attention to detail and personalized approach made all the difference. They took care of everything, from flights and transfers to special activities tailored for our kids. The accommodations were comfortable and conveniently located, recommendations for restaurants and attractions were spot on. thanks to their expert planning."
                name="Tzu Po Kao"
                company="Travel Agent"
              />
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSection;
