import { useState } from 'react';
import { api_url } from '../../../../constants/base_url';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { notifyError, notifySuccess } from '../../../../App';
import Modal from '../../../../components/shared/Modal/Modal';
import Form from '../../../../components/shared/Form/Form';
import style from "../blogCard.module.css"
import { BsTrashFill } from 'react-icons/bs';

const DeleteBlog = ({ id, title, refetch }) => {
    const [deleteBox, setDeleteBox] = useState(false)
    const { mutate: deleteBlog, isLoading } = useMutation({
        mutationFn: () => {
            return axios.post(`${api_url}blog/${id}`, { _method: "DELETE" }, {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            });
        },
        onSuccess: (data) => {
            console.log("Blog Deleted successful", data);
            setDeleteBox(false);
            notifySuccess(`${title} Blog Deleted Successfully`);
            refetch();
        },
        onError: (error) => {
            console.error("Delete Blog failed:", error);
            notifyError(error?.response?.data?.message);
        }
    });

    return (
        <>
            <span className='transitionAnimation' onClick={() => setDeleteBox(true)}><BsTrashFill /> Delete</span>
            <Modal isAppear={deleteBox} onClose={() => setDeleteBox(false)}>
                <Form className={style.form}>
                    <h2 style={{ textAlign: "center" }}>{`Delete ${title} ?`}</h2>
                    <div className={style.subDiv}>
                        <Form.ButtonController onClick={deleteBlog} isLoading={isLoading} main red>Delete</Form.ButtonController>
                        <Form.ButtonController onClick={() => setDeleteBox(false)} sub red>Cancel</Form.ButtonController>
                    </div>
                </Form>
            </Modal>
        </>
    )
}

export default DeleteBlog