import { Link } from 'react-router-dom';

const WidgetSocial = ({type, icon, name, children,href}) => {
    return (
        <div class={`social-icon social-${type}`}>
            <Link to={href}>
                {children}
                {/* <i class={`fab fa-${icon}`}></i> */}
                <span>{name}</span>
            </Link>
        </div>
    )
}

export default WidgetSocial