import SpecialCard from "./specialCard";

import img7 from "../../images/img7.jpg";
import img8 from "../../images/gallery-8.jpg";
import img9 from "../../images/El-Moez-Street.jpg";
import camelTrip from "../../images/camel-trip.jpg";
import { api_url } from "../../constants/base_url";

const SpecialRow = ({data}) => {
  console.log(data)
  const packages = [
    {
      id: 1,
      image: img9,
      price: "$633,00",
      oldPrice: "$700,00",
      duration: "7D/6N",
      people: "5",
      location: "Egypt,Cairo",
      title: `Cairo Day tour 
    Starting point : Cairo or Giza Hotel 
    Ending point : Cairo or Giza Hotel 
    `,
      reviews: "25 reviews",
      rating: "60%",
      description: `Your Tour starts by pick-up from the Hotel by our Licensed and Experienced Tour Guide and our Modern Brand-New Private A/C Coach and then proceed to:
    
    1- Visit Egyptrian museum 
    2- Visit El Moez Street
    3- Visit khan khalili
    
    Meal : lunch 
    (Duration 4-5Hours)
    `,
    },
    {
      id: 2,
      image: img7,
      price: "$633,00",
      oldPrice: "$720,00",
      duration: "5D/4N",
      people: "8",
      location: "Egypt",
      title: `Day tour from Cairo  to Luxor  
    (BY Flight )
    Starting point : Cairo  Hotel 
    Ending point : Cairo Hotel
    `,
      reviews: "17 reviews",
      rating: "100%",
      description: `Pickup from Cairo Hotel to the Airport take Domestic Flight to Luxor 
    
    Visit Karnak Temples  
    Visit Luxor Temples ( from out side)
    Visit valley of the kings 
    Visit Colossi of Memnon
    
    Meal : Lunch 
    
    Then transfer back to Luxor Airport Fly back to Cairo.
    
    (Duration  about 10 Hours)
    `,
    },
    {
      id: 3,
      image: camelTrip,
      price: "$100,00",
      oldPrice: "$140,00",
      duration: "6D/5N",
      people: "6",
      location: "Egypt",
      title: `Day tour from Hurghada to Cairo 
    (BY Flight )
    Starting point : Hurghada Hotel 
    Ending point : Hurghada Hotel 
    
    `,
      reviews: "22 reviews",
      rating: "100%",
      description: `Pickup from Hurghada Hotel to the Airport take Domestic Flight to Cairo 
    
    Visit the Egyptian Museum 
    Visit the Pyramids of Giza
    Meals: Lunch 
    
    (Duration 9 Hours)
    
    After finishing the Tour.
     Then transfer back to Cairo  Airport Fly back to Hurghada.
    `,
    },
    {
      id: 4,
      image: img8,
      price: "$633,00",
      oldPrice: "$750,00",
      duration: "6D/5N",
      people: "6",
      location: `Egypt`,
      title: `Cairo Day tour 
    Starting point : Cairo or Giza Hotel 
    Ending point : Cairo or Giza Hotel 
    `,
      reviews: "22 reviews",
      rating: "100%",
      description: `Day tour to the Pyramids 
    Pickup From hotel 
    Visit Giza Pyramids and Sphinx.
    Visit Egyptian Museum
    Visit old Cairo 
    
    Meal : Lunch
    
    Your Tour starts by pick-up from the Hotel by our Licensed and Experienced Tour Guide and our Modern Brand-New Private A/C Coach and then proceed to:
    
     (Duration 8 Hours)
    `,
    },

    // Add other package details here...
  ];
  return (
    <>
      <div class="special-inner">
        <div class="row">
          {data?.map((item) => (
            <SpecialCard
              id={item.id}
              imgSrc={`${api_url.slice(0,-4)}${item.image?.slice(1)}`}
              discount={15}
              country={item.location}
              description={item.description}
              title={item.title}
              numOfPeople={item.number_of_people}
              days={item.duration_days}
              // oldPrice={1500}
              // price={1200}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default SpecialRow;
