import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';
import { api_url } from '../../../../constants/base_url';
import { notifyError, notifySuccess } from '../../../../App';
import { IoCloseCircle } from 'react-icons/io5';
import Modal from '../../../../components/shared/Modal/Modal';
import Form from '../../../../components/shared/Form/Form';
import style from "../../dashboardBlogPage/dashboardBlogPage.module.css"


const LangCard = ({ id, refetch, lang, title,setLang }) => {
    const [openDeleteBox, setOpenDeleteBox] = useState(false);
    
    const { mutate: deleteLang, isLoading } = useMutation({
        mutationFn: () => {
            return axios.delete(`${api_url}blogLanguage/${id}`, {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            });
        },
        onSuccess: (data) => {
            console.log("Language Deleted successful", data);
            setOpenDeleteBox(false);
            notifySuccess(`${lang.language} Language Deleted Successfully`);
            refetch();
        },
        onError: (error) => {
            console.error("Delete lang failed:", error);
            notifyError(error?.response?.data?.message);
        }
    });


    const subCards = Object.keys(lang)
        .filter(key => key.startsWith('sub_card_'))
        .map(key => lang[key]);


    return (
        <>
            <div className={style.langCard}>
                <div className={style.header}>
                    <p>{lang.language}</p>
                    <IoCloseCircle onClick={() => setOpenDeleteBox(true)} />
                </div>
                <img src={`${api_url}${lang?.main_card?.image[0]}`.replace("/api/", "/storage/")} alt=""  />
                <h2>{title}</h2>
                <p>{lang.main_card.content}</p>
                <button className='button-secondary' onClick={() => setLang(lang?._id)}>choose language</button>
            </div>
            <Modal isAppear={openDeleteBox} onClose={() => setOpenDeleteBox(false)}>
                <Form className={style.form}>
                    <h2 style={{ textAlign: "center" }}>{`Delete ${lang.language} Language ?`}</h2>
                    <div className={style.subDiv}>
                        <Form.ButtonController onClick={deleteLang} isLoading={isLoading} main red>Delete</Form.ButtonController>
                        <Form.ButtonController onClick={() => setOpenDeleteBox(false)} sub red>Cancel</Form.ButtonController>
                    </div>
                </Form>
            </Modal>
        </>
    )
}

export default LangCard