import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { api_url } from '../../constants/base_url';
import axios from 'axios';
import Loading from '../shared/Loading/Loading';

const getSubCards = (data, blogId, language) => {
    const subCards = Object.keys(data)
        .filter((key) => key.startsWith('sub_card_'))
        .map((key) => data[key]);

    return subCards;
    // return [];
};

const BlogSubcards = ({ blogId, language, languageId }) => {
    const { data, isLoading, isSuccess } = useQuery({
        queryKey: ['blogCard'],
        queryFn: () =>
            axios.get(`${api_url}blogLanguage/${languageId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            }),
        onSuccess: (response) => {
            console.log("All Languages:", response.data);
        },
        onError: (err) => {
            console.error("Error fetching data:", err);
        }
    });
    console.log(data)

    const [subCards, setSubCards] = useState([]);
    const [mainCard, setMainCard] = useState();

    useEffect(() => {
        if (isSuccess && data?.data?.data) {
            const subCardsData = getSubCards(data?.data?.data, blogId, language);
            setMainCard(data?.data?.data?.main_card)
            setSubCards(subCardsData);
        }
    }, [isSuccess, data, blogId, language]);


    return isLoading ? (
        <Loading />
    ) : (
        <ul style={{listStyle:"none", margin:"0", padding:"0"}}>
            <li>
                <figure className="post-thumb">
                    {mainCard?.image && mainCard?.image?.map((item,index) => (<a key={index} href="#">
                        <img
                            src={`${api_url}${item}`.replace("/api/", "/storage/")}
                            alt={mainCard?.title}
                            style={{marginBottom:"20px"}}
                        />
                    </a>))}
                </figure>
                <div className="post-content">
                    <h4>{mainCard?.title}</h4>
                    <p>{mainCard?.content}</p>
                </div>
            </li>
            {subCards.length > 0 ? (
                subCards.map((item, index) => (
                    <li key={index}>
                        <figure className="post-thumb">
                            {item.image && item.image.map((image,i) => (<a key={i} href="#">
                                <img
                                    src={`${api_url}${image}`.replace("/api/", "/storage/")}
                                    alt={item?.title}
                                    style={{marginBottom:"20px"}}
                                />
                            </a>))}
                        </figure>
                        <div className="post-content">
                            <h4>{item.title}</h4>
                            <p>{item.content}</p>
                        </div>
                    </li>
                ))
            ) : (
                <p>No sub-cards available.</p>
            )}
        </ul>
    );
};

export default BlogSubcards;
