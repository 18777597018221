import { useState } from 'react';
import "../../dashboardIndex.css"
import cardStyle from "./commentCard.module.css"

import { BsTrashFill } from "react-icons/bs";
import { HiMiniArrowUturnLeft } from "react-icons/hi2";
import { AiOutlineComment } from "react-icons/ai";


const CommentCard = ({ ownerImage, ownerName, date, commentedOn, comment, replies }) => {
    const [showReplies, setShowReplies] = useState(false);

    const toggleReplies = () => {
        setShowReplies(!showReplies);
    };

    return (
        <div className={cardStyle.card}>
            <div className={cardStyle.commentsOn}>
                <div><AiOutlineComment /></div>
                <div>
                    <h4>Commented on:</h4>
                    <p>{commentedOn}.</p>
                </div>
            </div>
            <div className={cardStyle.mainComment}>
                <div className={cardStyle.commentHeader}>
                    <div className={cardStyle.ownerData}>
                        <span className={cardStyle.img} style={{ backgroundImage: `url(${ownerImage})` }}></span>
                        <h5>{ownerName}</h5>
                    </div>

                    <div className={cardStyle.date}>{date}</div>
                </div>
                <div className={cardStyle.comment}>{comment}</div>
                <div className={cardStyle.commentFooter}>
                    <span onClick={toggleReplies} className='transitionAnimation'>{replies.length} Replies</span>
                    <div>
                        <span className='transitionAnimation'><HiMiniArrowUturnLeft /> Reply</span>
                        <span className='transitionAnimation'><BsTrashFill /> Remove</span>
                    </div>
                </div>
            </div>

            <div className={`${cardStyle.replies} ${!showReplies ? cardStyle.hide: ""}`}>
                {replies.map((reply) => (
                    <ReplyCard key={reply.id}
                        ownerImage={reply.ownerImage}
                        ownerName={reply.ownerName}
                        date={reply.date}
                        comment={reply.comment} />
                ))}
            </div>

        </div>

    )
}

export default CommentCard

const ReplyCard = ({ ownerImage, ownerName, date, comment }) => {
    return (
        <div className={cardStyle.mainComment}>
            <div className={cardStyle.commentHeader}>
                <div className={cardStyle.ownerData}>
                    <span className={cardStyle.img} style={{ backgroundImage: `url(${ownerImage})` }}></span>
                    <h6>{ownerName}</h6>
                </div>

                <div className={cardStyle.date}>{date}</div>
            </div>
            <div className={cardStyle.comment}>{comment}</div>
            <div className={cardStyle.commentFooter}>
                <span className='transitionAnimation'><HiMiniArrowUturnLeft /> Reply</span>
                <span className='transitionAnimation'><BsTrashFill /> Remove</span>
            </div>
        </div>
    )
}