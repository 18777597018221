import img7 from "../../images/img7.jpg";
import img8 from "../../images/img6.jpg";
import Slider from "react-slick";
const GallerySlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 3,
    arrows: false,
    margin: 20,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          margin: 10,
        },
      },
    ],
  };
  return (
    <div className="single-tour-gallery mt-5">
      <h3>GALLERY / PHOTOS</h3>
      <div className="single-tour-slider slider-container">
        <Slider {...settings}>
          <div className="single-tour-item">
            <figure className="feature-image">
              <img src={img7} alt="" width={"95%"} />
            </figure>
          </div>
          <div className="single-tour-item">
            <figure className="feature-image">
              <img src={img8} alt="" width={"95%"} />
            </figure>
          </div>
          <div className="single-tour-item">
            <figure className="feature-image">
              <img src={img7} alt="" width={"95%"} />
            </figure>
          </div>
          <div className="single-tour-item">
            <figure className="feature-image">
              <img src={img8} alt="" width={"95%"} />
            </figure>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default GallerySlider;
