import { useState } from "react";
import { FaCalendarAlt, FaStar, FaStarHalf } from "react-icons/fa";
import { Link } from "react-router-dom";

const SpecialCard = ({
  imgSrc,
  discount,
  country,
  description,
  // oldPrice,
  // price,
  id,
  title,
  numOfPeople,
  days,

}) => {
  console.log(imgSrc);
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const getShortDescription = (text) => {
    return text.length > 200 ? text.substring(0, 50) + "..." : text;
  };

  return (
    <>
      <div class="col-md-6 col-lg-4">
        <div class="special-item">
          <figure class="special-img">
            <img src={imgSrc} alt="" />
          </figure>
          <div class="badge-dis" >
            <span>
              Discount
            </span>
          </div>
          <div class="special-content">
            <div class="meta-cat">
              <Link to={`/tours/${id}`}>{title}</Link>
            </div>
            <h3>
              <Link to={`/tours/${id}`}>
                {showFullDescription
                  ? description
                  : getShortDescription(description)}
                {description.length > 100 && (
                  <button onClick={toggleDescription} className="toggle-button">
                    {showFullDescription ? "See Less" : "See More"}
                  </button>
                )}
              </Link>
            </h3>
            <div class="package-info" style={{display:"flex", alignItems:"center", gap:"15px", fontSize:"1rem"}}>
                <FaCalendarAlt />
                <span>{days} Days</span>
            </div>
            <div style={{display:"flex", alignItems:"center", gap:"15px"}}>
            <div style={{display:"flex", alignItems:"center", gap:"5px", color:"#FFD700"}}>
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStarHalf />
            </div>
            <span>{numOfPeople} People</span>
            </div>
            {/* <div class="package-price">
              Price:
              <del>{`$${oldPrice}`}</del>
              <ins>{`$${price}`}</ins>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecialCard;
