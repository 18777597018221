import React, { useEffect, useRef, useState } from 'react'
import SectionHeading from '../../../../components/sectionheading';
import CustomButton from '../../../../components/shared/customButton';
import { useHomeBlog } from '../../../../context/HomeBlogContext';

const DestinationsSection = () => {
  const {lang,blog, addSection, updateSection} = useHomeBlog()
  const [content, setContent] = useState("")
  const [title, setTitle] = useState("")
  const [buttonText, setButtonText] = useState("")

  useEffect(() => {
    if(blog?.sub_card_3){
      setContent(blog?.sub_card_3?.content?.split("-.-")[0])
      setTitle(blog?.sub_card_3?.title)
      setButtonText(blog?.sub_card_3?.content?.split("-.-")[1])
    }else{
      setContent("")
      setTitle("")
      setButtonText("")
    }
  },[blog, lang])
  const updateDestinationsSection = () => {
    if(blog){
      updateSection({
        blogLanguageId: lang.id,
        index: 3,
        content: `${content}-.-${buttonText}`,
        title: title,
        _method: "PUT"
      })
    }
  }
  const addDestinationsSections = () => {
    addSection({
      blogLanguageId: lang.id,
      title: "Popular Destinations",
      content: "Top Notch Deals-.-More Destinations",
    })
  }
  return (
    <div style={{width:"100%", outline:"4px dashed #D51C29", marginBottom:"20px", padding:"20px"}}>
            {blog?.sub_card_3?<>
              <SectionHeading title={title} contentEditable
              key={blog._id}
              onChange={(e) => {
                setTitle(e.target.value)
              }}
              textAlign="left"
              rowAlignment="align-items-end"
              containerTextsStyle="col-lg-7">
                <input key={blog._id} style={{width:"100%", textAlign:"left", border:"none", outline:"none", color:"#101F46", fontSize:"48px", fontWeight:"900"}} type="text" value={content} onChange={(e) => setContent(e.target.value)} />
              </SectionHeading>
              <CustomButton><input key={blog._id} style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "white",
                backgroundColor:"transparent",
                textAlign:"center",
                padding:"0",
                border:"none",
                outline:"none",
              }} type='text' value={buttonText} onChange={(e) => {
                setButtonText(e.target.value)
              }}/></CustomButton>
              <input
                type="submit"
                name="travel-search"
                value="Save"
                style={{margin:"auto"}}
                onClick={updateDestinationsSection}
              />
            </>:<input
                  type="submit"
                  name="travel-search"
                  value="Add Destinations Section"
                  style={{margin:"auto", display:"block",}}
                  onClick={addDestinationsSections}
                />}
            
      </div>
  )
}

export default DestinationsSection