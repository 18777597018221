import { useEffect, useState } from 'react';
import PageHeader from './../../components/pageHeader/pageHeader';
import CustomButton from '../../components/customButton/customButton';
import { FaChevronLeft, FaXmark } from 'react-icons/fa6';
import Table from './../../components/customTable/table';

import bookingStyle from "./dashboardReservations.module.css"
import "../../dashboardIndex.css"
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { api_url } from '../../../constants/base_url';
import ReactPaginate from 'react-paginate';
import {FaAngleDoubleLeft, FaAngleDoubleRight} from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import { notifyError, notifySuccess } from '../../../App';


const DashboardReservations = () => {
  const status = {
    APPROVE: "accepted",
    PENDING: "pending",
    REJECT: "rejected"
  }
  
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const {data: inquiries, refetch} = useQuery({
    queryKey: ["allReservations",page],
    queryFn: () => axios.get(`${api_url}reservation/adminShow?page=${page}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      }
    })
  })


    console.log(inquiries)
  const {mutate, isPending} = useMutation({
    mutationFn: (data) => axios.patch(`${api_url}inquiries/${data.id}`, {
      status: data.status
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      }
    }),
    onSuccess: () => {
      notifySuccess("Inquiry updated successfully")
      refetch()
    },
    onError: () => {
      notifyError("Something went wrong")
    }
  })

  useEffect(() => {
    if(inquiries?.data?.data){
      setTotalPages(inquiries?.data?.data?.last_page)
    }
  },[inquiries])

  return (
    <main className={bookingStyle.mainContainer}>
      <PageHeader>Reservations</PageHeader>
      <div style={{display:"flex", padding:"20px 0", alignItems:"center"}}>
        <ReactPaginate 
          onPageChange={(selectedItem) => {
            setPage(selectedItem.selected + 1); // Update the page state with the selected page number
          }}
          pageCount={totalPages}
          containerClassName='pagination'
          pageClassName='page-item'
          pageLinkClassName='page-link'
          nextClassName='page-item'
          nextLinkClassName='page-link'
          previousClassName='page-item'
          previousLinkClassName='page-link'
          breakClassName='page-item'
          breakLinkClassName='disabled page-link'
          activeClassName='active'
          nextLabel={<FaAngleDoubleRight />}
          previousLabel={<FaAngleDoubleLeft />}
        />
      </div>
      <Table className={bookingStyle.usersTable}>
        <Table.HeadController>
          <th>User</th>
          <th>Tour</th>
          <th>From</th>
          <th>Number of adults</th>
          <th>Number of children</th>
          <th>Total price</th>
          <th>Additional notes</th>
        </Table.HeadController>
        <Table.BodyController>
          {inquiries?.data?.data?.data?.length > 0 ? (
            inquiries.data.data.data.map(inquiry => (
              <tr key={inquiry.id}>
                <td>{inquiry.user_name}</td>
                <td>{inquiry.tour_name}</td>
                <td>{inquiry.reservation_date}</td>
                <td>{inquiry.number_of_adults}</td>
                <td>{inquiry.number_of_children}</td>
                <td>{inquiry.total_price}</td>
                <td>{inquiry.additional_notes || "-"}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" style={{ fontSize: "1.6rem", textAlign: "center" }}>No data available</td>
            </tr>
          )}
        </Table.BodyController>
      </Table>
    </main>
  )
}

export default DashboardReservations