import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { api_url } from "../constants/base_url";
import { notifySuccess, notifyError } from "../App";

const HomeBlogContext = createContext();

export const useHomeBlog = () => useContext(HomeBlogContext);

export const HomeBlogProvider = ({ children }) => {
    const queryClient = useQueryClient()
    const [langs, setLangs] = useState([])
    const [lang, setLang] = useState(null)
    const [blog, setBlog] = useState({})
    const {data, isLoading} = useQuery({
        queryKey: ["homeBlogLangs"],
        queryFn: () => axios.get(`${api_url}home`,{
            headers: {
                'Content-Type': 'application/json',
            },
        })
    })
    const {mutate: addLang, isLoading: isAddLangLoading} = useMutation({
        mutationFn: (data) => axios.post(`${api_url}home`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        }),
        onSuccess: () => {
            queryClient.invalidateQueries(["homeBlogLangs"])
            notifySuccess("Language added successfully")
        },
        onError: () => {
            notifyError("Failed to add language")
        }
    })
    const {mutate: updateLang, isLoading: isUpdateLangLoading} = useMutation({
        mutationFn: (data) => axios.post(`${api_url}home`, {...data, _method: "PUT"}, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        })
    })
    const {mutate: deleteLang, isLoading: isDeleteLangLoading} = useMutation({
        mutationFn: (langId) => axios.delete(`${api_url}home/${langId}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        }),
        onSuccess: () => {
            queryClient.invalidateQueries(["homeBlogLangs"])
            notifySuccess("Language deleted successfully")
        },
        onError: () => {
            notifyError("Failed to delete language")
        }
    })
    const {data: blogData, isLoading: isBlogLoading, refetch} = useQuery({
        queryKey: ["homeBlog", lang],
        queryFn: () => axios.get(`${api_url}home/${lang?.name}`,{
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        }),
        enabled: !!(lang)
    })
    const {mutate: addSection, isLoading: isAddSectionLoading} = useMutation({
        mutationFn: (data) => axios.post(`${api_url}home/subcard`, data, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        }),
        onSuccess: () => {
            refetch()
            notifySuccess("Section added successfully")
        },
        onError: () => {
            notifyError("Failed to add section")
        }
    })

    const {mutate: updateSection, isLoading: isUpdateSectionLoading} = useMutation({
        mutationFn: (data) => axios.post(`${api_url}home/subcard`, data, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        }),
        onSuccess: () => {
            refetch()
            notifySuccess("Section updated successfully")
        },
        onError: () => {
            notifyError("Failed to update section")
        }
    })

    const {data: allLanguages, isLoading: isAllLanguagesLoading} = useQuery({
        queryKey: ["languages"],
        queryFn: () => axios.get(`${api_url}language`,{
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        })
    })
    
    useEffect(()=>{
        if(data?.data?.data){
            setLangs(Object.keys(data?.data?.data).filter(item => item !== '_id' && item !== "updated_at").map(item => {return {name:item, id: data?.data?.data?.[item]}}).sort((a, b) => a.name === "en" ? -1 : b.name === "en" ? 1 : 0))
            setLang({
                name: "en",
                id: data?.data?.data?.en
            })
        }
    }, [data])
    useEffect(()=>{
        if(blogData?.data?.data){
            setBlog(blogData?.data?.data)
        }
    }, [blogData])
    return (
        <HomeBlogContext.Provider value={{langs, lang, setLang, isLangsLoading: isLoading,addLang, isAddLangLoading, updateLang, isUpdateLangLoading, deleteLang, isDeleteLangLoading, blog, isBlogLoading, addSection, isAddSectionLoading, updateSection, isUpdateSectionLoading, allLanguages: allLanguages?.data?.data?.map(item => item?.name)}}>
            {children}
        </HomeBlogContext.Provider>
    )
}