const SectionHeading = () => (
  <div className="section-heading text-center">
    <div className="row">
      <div className="col-lg-8 offset-lg-2">
        <h5 className="dash-style">TRAVEL BY ACTIVITY</h5>
        <h2>ADVENTURE & ACTIVITY</h2>
        <p>Mollit voluptatem perspiciatis convallis elementum corporis quo veritatis aliquid blandit, blandit torquent, odit placeat. Adipiscing repudiandae eius cursus? Nostrum magnis maxime curae placeat.</p>
      </div>
    </div>
  </div>
);

export default SectionHeading;
