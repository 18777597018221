import { useEffect, useState } from "react";
import style from "./TourDetails.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { IoHourglassOutline } from "react-icons/io5";
import { IoEarth } from "react-icons/io5";
import { IoPeople } from "react-icons/io5";
import { IoCalendarOutline } from "react-icons/io5";

import img7 from "../../images/img13.jpg";
import img8 from "../../images/img2 - Copy.jpg";
import img9 from "../../images/gallery-6.jpg";

import Modal from "../../components/shared/Modal/Modal";
import ImagesSlider from "./../../components/slider/imageslider";
import Form from "../../components/shared/Form/Form";
import PricesCard from "../../components/TourDetailsComponents/PricesCard/PricesCard";
import ItineraryCard from "./../../components/TourDetailsComponents/ItineraryCard/ItineraryCard";
import { api_url } from "../../constants/base_url";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useForm } from "react-hook-form";
import { countryCodes } from "../../constants/country_codes";
import { zodResolver } from "@hookform/resolvers/zod";
import { inquireSchema } from "../../Validations/bookings/inquire.schema";
import ErrorText from "../../components/shared/ErrorText";
import { notifyError, notifySuccess } from "../../App";
import { reservationSchema } from "../../Validations/bookings/reservation.schema";
import { useLanguage } from "../../context/LanguageContext";
import Loading from "../../components/shared/Loading/Loading";

const itineraries = [
  {
    title: "4 Days - Aswan / Luxor Every Friday",
    days: [
      {
        label: "Day 1: Embark Dahabiya",
        programDetails: {
          details: [
            "Embark in Aswan at 12:00 PM",
            "Visit Philae Temple",
            "Dinner on board",
          ],
          summary: "Sailing at 17:00 PM and overnight Herdiab Island.",
        },
        meals: "Lunch and Dinner",
        visits: "Aswan, Philae Temple",
      },
      {
        label: "Day 2: Kom Ombo",
        programDetails: {
          details: [
            "Breakfast on board and sailing to Kom Ombo",
            "Visit Kom Ombo Temple",
            "Sailing to Edfu and overnight",
          ],
          summary: "Overnight at Edfu.",
        },
        meals: "Breakfast, Lunch, and Dinner",
        visits: "Kom Ombo Temple",
      },
      {
        label: "Day 3: Sailing to Edfu",
        programDetails: {
          details: [
            "Enjoy early breakfast on board while sailing to Edfu",
            "Visit Edfu Temple",
            "Sailing to Esna and crossing Esna Lock while having Lunch",
          ],
          summary: "Overnight at Luxor.",
        },
        meals: "Breakfast, Lunch, and Dinner",
        visits: "Kom Ombo Temple",
      },
      {
        label: "Day 4: Luxor - Final Departure",
        programDetails: {
          details: [
            "Breakfast on board",
            "Disembark, then get transferred to visit Valley of the Kings and Karnak Temple",
            "Transfer to Luxor Airport for your final departure",
          ],
          summary: "",
        },
        meals: "Breakfast",
        visits: "Valley of the Kings, Karnak Temple",
      },
    ],
    NB: "Itineraries may be changed without prior notice due to navigational circumstances, maybe in sequences but not in visits",
  },
  {
    title: "5 Days - Luxor / Aswan Every Monday",
    days: [
      {
        label: "Day 1: Embark Dahabiya",
        programDetails: {
          details: [
            "Embark in Aswan at 12:00 PM",
            "Visit Philae Temple",
            "Dinner on board",
          ],
          summary: "Sailing at 17:00 PM and overnight Herdiab Island.",
        },
        meals: "Lunch and Dinner",
        visits: "Aswan, Philae Temple",
      },
      {
        label: "Day 2: Kom Ombo",
        programDetails: {
          details: [
            "Breakfast on board and sailing to Kom Ombo",
            "Visit Kom Ombo Temple",
            "Sailing to Edfu and overnight",
          ],
          summary: "Overnight at Edfu.",
        },
        meals: "Breakfast, Lunch, and Dinner",
        visits: "Kom Ombo Temple",
      },
      {
        label: "Day 3: Sailing to Edfu",
        programDetails: {
          details: [
            "Enjoy early breakfast on board while sailing to Edfu",
            "Visit Edfu Temple",
            "Sailing to Esna and crossing Esna Lock while having Lunch",
          ],
          summary: "Overnight at Luxor.",
        },
        meals: "Breakfast, Lunch, and Dinner",
        visits: "Kom Ombo Temple",
      },
      {
        label: "Day 4: Luxor - Final Departure",
        programDetails: {
          details: [
            "Breakfast on board",
            "Disembark, then get transferred to visit Valley of the Kings and Karnak Temple",
            "Transfer to Luxor Airport for your final departure",
          ],
          summary: "",
        },
        meals: "Breakfast",
        visits: "Valley of the Kings, Karnak Temple",
      },
    ],
    NB: "Itineraries may be changed without prior notice due to navigational circumstances, maybe in sequences but not in visits",
  },
  {
    title: "8 Days - Luxor / Luxor Every Monday",
    days: [
      {
        label: "Day 1: Embark Dahabiya",
        programDetails: {
          details: [
            "Embark in Aswan at 12:00 PM",
            "Visit Philae Temple",
            "Dinner on board",
          ],
          summary: "Sailing at 17:00 PM and overnight Herdiab Island.",
        },
        meals: "Lunch and Dinner",
        visits: "Aswan, Philae Temple",
      },
      {
        label: "Day 2: Kom Ombo",
        programDetails: {
          details: [
            "Breakfast on board and sailing to Kom Ombo",
            "Visit Kom Ombo Temple",
            "Sailing to Edfu and overnight",
          ],
          summary: "Overnight at Edfu.",
        },
        meals: "Breakfast, Lunch, and Dinner",
        visits: "Kom Ombo Temple",
      },
      {
        label: "Day 3: Sailing to Edfu",
        programDetails: {
          details: [
            "Enjoy early breakfast on board while sailing to Edfu",
            "Visit Edfu Temple",
            "Sailing to Esna and crossing Esna Lock while having Lunch",
          ],
          summary: "Overnight at Luxor.",
        },
        meals: "Breakfast, Lunch, and Dinner",
        visits: "Kom Ombo Temple",
      },
      {
        label: "Day 4: Luxor - Final Departure",
        programDetails: {
          details: [
            "Breakfast on board",
            "Disembark, then get transferred to visit Valley of the Kings and Karnak Temple",
            "Transfer to Luxor Airport for your final departure",
          ],
          summary: "",
        },
        meals: "Breakfast",
        visits: "Valley of the Kings, Karnak Temple",
      },
    ],
    NB: "Itineraries may be changed without prior notice due to navigational circumstances, maybe in sequences but not in visits",
  },
];

const TourDetails = () => {
  const { tourId } = useParams();
  const { language } = useLanguage();

  const [gallery, setGallery] = useState(null); // Store the matching object

  const [currentImage, setCurrentImage] = useState(
    gallery && gallery?.image[0]
  );
  const [openImageSlider, setOpenImageSlider] = useState(false);
  const [tab, setTab] = useState(itineraries[0].title);
  const [currentItineraries, setCurrentItineraries] = useState(itineraries[0]);
  const [openBook, setOpenBook] = useState(false);
  const cruise = itineraries.map((itinerary) => itinerary.title);

  const [cardData, setCardData] = useState(null);
  const [cruiseData, setCruiseData] = useState({});
  const [cabinData, setCabinData] = useState([]);
  const [includeExclude, setIncludeExclude] = useState([]);

  const [overviewCard, setOverviewCard] = useState(null);

  useEffect(() => {
    if (gallery?.image) {
      setCurrentImage(gallery?.image[0]);
    }
  }, [gallery?.image]);
  const { data } = useQuery({
    queryKey: ["tour", tourId, language],
    queryFn: () =>
      axios.get(`${api_url}tour/${tourId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
  });

  const { data: showBlog, isLoading } = useQuery({
    queryKey: ["showBlog", tourId, language],
    queryFn: () =>
      axios.get(
        `${api_url}blog/show?Blogid=${data?.data?.data?.blogId}&language=${language}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      ),
    enabled: !!data?.data?.data?.blogId,
  });
  const {
    data: subCard,
    isLoading: isSubCardLoading,
    isSuccess,
  } = useQuery({
    queryKey: ["subCard", language, showBlog?.data?.data?.language?.id],
    queryFn: () =>
      axios.get(
        `${api_url}blogLanguage/${showBlog?.data?.data?.language?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      ),
    enabled: !!showBlog?.data?.data?.language?.id,
  });

  useEffect(() => {
    if (subCard?.data?.data && !isLoading) {
      Object.entries(subCard.data.data).forEach(([key, value], index) => {
        if (key.startsWith("sub_") && value?.title === "cruise") {
          setCardData(JSON.parse(value?.content)); // Set the matching object in state
        }
      });
      Object.entries(subCard.data.data).forEach(([key, value], index) => {
        if (key.startsWith("sub_") && value?.title === "cabin") {
          setCabinData(JSON.parse(value?.content)); // Set the matching object in state
        }
      });
      Object.entries(subCard.data.data).forEach(([key, value], index) => {
        if (key.startsWith("sub_") && value?.title === "images") {
          setGallery(value); // Set the matching object in state
        }
      });
      Object.entries(subCard.data.data).forEach(([key, value], index) => {
        if (key.startsWith("sub_") && value?.title === "Overview") {
          setOverviewCard(JSON.parse(value?.content)); // Set the matching object in state
        }
      });
      Object.entries(subCard.data.data).forEach(([key, value], index) => {
        if (key.startsWith("sub_") && value?.title === "include&exclude") {
          setIncludeExclude(JSON.parse(value?.content)); // Set the matching object in state
        }
      });
    }
  }, [subCard?.data?.data, isSubCardLoading, isSuccess]);

  const { data: tour_days, isloading: tour_daysLoading } = useQuery({
    queryKey: ["tour_days", tourId],
    queryFn: () =>
      axios.get(`${api_url}tourDay/${tourId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
    onSuccess: (response) => {
      console.log("tour_days:", response.data);
    },
    onError: (err) => {
      console.error("Error fetching data:", err);
    },
  });

  const {
    data: pricing,
    error: pricingError,
    isLoading: isPricingLoading,
  } = useQuery({
    queryKey: ["pricing", tourId],
    queryFn: () =>
      axios.get(`${api_url}tourPricing/${tourId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
  });

  return (
    <>
      <div className={style.banner}></div>

      <div className={style.container}>
        <h1>{data?.data?.data?.title}</h1>
        <div className={style.details}>
          <div className={style.leftSection}>
            <div className={style.images}>
              <div
                style={{
                  backgroundImage: `url(${`${api_url}${currentImage}`.replace(
                    "/api/",
                    "/storage/"
                  )})`,
                }}
              ></div>

              {isSubCardLoading ? (
                <Loading />
              ) : (
                gallery?.image &&
                gallery?.image.map((image, index) => (
                  <div
                    key={index}
                    className={currentImage === image && style.active}
                    style={{
                      backgroundImage: `url(${`${api_url}${image}`.replace(
                        "/api/",
                        "/storage/"
                      )})`,
                    }}
                    onClick={() => setCurrentImage(image)}
                  ></div>
                ))
              )}
              {gallery?.image.length > 2 && (
                <div
                  style={{
                    backgroundImage: `url(${`${api_url}${gallery?.image[2]}`.replace(
                      "/api/",
                      "/storage/"
                    )})`,
                  }}
                  onClick={() => {
                    setCurrentImage(gallery?.image[2]);
                    setOpenImageSlider(true);
                  }}
                >
                  <p>+{gallery?.image.length - 2} Photos</p>
                </div>
              )}
            </div>
            <div className={style.overview}>
              <h2>Overview</h2>
              <div>
                <OverviewCard
                  icon={<IoHourglassOutline />}
                  text="Schedule"
                  subText={overviewCard?.scheduleDays}
                  details={overviewCard?.scheduleNights}
                />
                <OverviewCard
                  icon={<IoEarth />}
                  text="Countries"
                  subText={overviewCard?.numberOfCountries}
                  details={overviewCard?.numberOfCities}
                />
                <OverviewCard
                  icon={<IoPeople />}
                  text="Type"
                  subText="Small group tour"
                  details={`${overviewCard?.numberOfPersons || 0} : Persons`}
                />
                <OverviewCard
                  icon={<IoCalendarOutline />}
                  text="Run"
                  subText={overviewCard?.tourRunFrom}
                  details={overviewCard?.tourRunTo}
                />
              </div>
              <p>{overviewCard?.overviewDescription}</p>
            </div>
          </div>
          <a
            href="#bookForm"
            className={`button-primary ${style.bookButton}`}
            onClick={() => setOpenBook(!openBook)}
          >
            Book Now
          </a>
          <div
            id="bookForm"
            className={style.bookingForm}
            style={{ display: openBook && "block" }}
          >
            <BookingForm
              title={data?.data?.data?.title}
              cruise={cruise}
              tourId={tourId}
            />
          </div>
        </div>
        <div className={style.includeExclude}>
          <div className={style.include}>
            <h3>Included</h3>
            <ul>
              {includeExclude?.included &&
              includeExclude.included.length > 0 ? (
                includeExclude.included.map((item, index) => (
                  <li key={index}>{item}</li>
                ))
              ) : (
                <li>No included services available.</li>
              )}
            </ul>
          </div>
          <div className={style.exclude}>
            <h3>Exclude</h3>
            <ul>
              {includeExclude?.excluded &&
              includeExclude?.excluded.length > 0 ? (
                includeExclude?.excluded?.map((item, index) => (
                  <li key={index}>{item}</li>
                ))
              ) : (
                <li>No excluded services available.</li>
              )}
            </ul>
          </div>
        </div>
        <div className={style.nbSection}>
          <h3>N.B.:</h3>
          <ul>
            {includeExclude?.NB && includeExclude.NB.length > 0 ? (
              includeExclude?.NB.map((item, index) => (
                <li key={index}>{item}</li>
              ))
            ) : (
              <li>No notes available.</li>
            )}
          </ul>
        </div>
        <div className={style.itinerary}>
          <h2>Itinerary</h2>
          {/* <div className={style.tabs}>
            <ul>
              {tour_days?.data.map((itinerary, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setTab(itinerary.title);
                    setCurrentItineraries(itinerary);
                  }}
                  className={tab === itinerary.title && style.active}
                >
                  {itinerary.title}
                </li>
              ))}
            </ul>
          </div> */}
          <div className={style.page}>
            {tour_daysLoading ? (
              <Loading />
            ) : (
              <ItineraryCard itinerary={tour_days?.data} />
            )}
          </div>
        </div>
        {cardData && (
          <div className={style.cruise}>
            <h2>Cruise</h2>
            <p>{cardData?.description}</p>
            <h4>Number of Rooms</h4>
            <ul>
              {cardData?.NumOfRooms && cardData.NumOfRooms.length > 0 ? (
                cardData.NumOfRooms.map((room, index) => (
                  <li key={index}>{room}</li>
                ))
              ) : (
                <li>No rooms available.</li>
              )}
            </ul>
          </div>
        )}
        {cabinData?.newCabin && (
          <div className={style.cabin}>
            <h2>Cabin</h2>
            <ul>
              {cabinData?.newCabin && cabinData.newCabin.length > 0 ? (
                cabinData.newCabin.map((item, index) => (
                  <li key={index}>{item}</li>
                ))
              ) : (
                <li>No cabin features available.</li>
              )}
            </ul>
          </div>
        )}
        <div className={style.prices}>
          <h2>Prices</h2>
          <div className={style.PricesCardsContainer}>
            {pricing?.data?.data?.data && pricing.data.data.data.length > 0 ? (
              pricing.data.data.data.map((priceCard) => (
                <PricesCard key={priceCard.id} priceCard={priceCard} />
              ))
            ) : (
              <div>No pricing information available.</div> // Optional: Handle empty case
            )}
          </div>
        </div>
        <div className={style.reviews}>
          {/* <h2>Reviews</h2>
          <ReviewsSection reviews={reviews} /> */}
        </div>
      </div>

      {openImageSlider && (
        <Modal isAppear onClose={() => setOpenImageSlider(false)}>
          <ImagesSlider images={gallery?.image} />
        </Modal>
      )}
    </>
  );
};

export default TourDetails;

export const BookingForm = ({
  title,
  cruise,
  tourId,
  inquire,
  inquireData,
  children,
  isPending: inquirePending,
}) => {
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  //   setValue,
  //   getValues
  // } = useForm({
  //   defaultValues: {
  //     tour_id:tourId || "",

  //   },
  // });

  useEffect(() => {
    if (inquireData) {
      setValue("from_date", inquireData.from_date);
      setValue("destination_id", inquireData.destination_id);
    }
  }, [inquireData]);

  const inquireDefaultValues = {
    destination_id: "",
    nationality: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: inquire
      ? zodResolver(inquireSchema)
      : zodResolver(reservationSchema),
    defaultValues: inquire ? inquireDefaultValues : {},
  });
  const reservationDate = watch("reservation_date");
  const [searchPricings, setSearchPricings] = useState(false);

  const { data: pricings } = useQuery({
    queryKey: ["pricnings", searchPricings],
    queryFn: () =>
      axios.get(
        `${api_url}tourPricing/${tourId}?reservation_date=${getValues(
          "reservation_date"
        )}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      ),
    enabled: !!reservationDate,
  });

  useEffect(() => {
    setSearchPricings(!!(tourId && reservationDate));
  }, [tourId, reservationDate]);

  console.log("Pricings: ", pricings);
  const navigate = useNavigate()
  const { mutate, isPending } = useMutation({
    mutationFn: (data) =>
      axios.post(`${api_url}reservation`, data, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
        },
      }),
    onSuccess: (data) => {
      notifySuccess("You have successfully reserved this tour");
      // console.log("Data: ", data);
      navigate(`/my-reservations/${data?.data?.data?.id}`)
      // onClose()
    },
    onError: () => {
      notifyError("Something went wrong, please try again");
    },
  });

  const submit = (data) => {
    const selectedPricingId = getValues("pricing_id");

    if (inquireData) {
      let mutationData = { ...data, ...inquireData };
      if (!inquireData.from_date) {
        mutationData.from_date = data.from_date;
        inquireData.from_date = data.from_date;
        setValue("from_date", data.from_date);
      }
      const code = getValues("code");
      if (code) {
        const normalizedCode = code.replace("+", "");

        // Check if the contact number starts with the normalized code
        if (mutationData.contact_number.startsWith(normalizedCode)) {
          // Remove the code from the start of the contact number
          mutationData.contact_number = mutationData.contact_number.slice(
            normalizedCode.length
          );
        }
      }
      inquire(mutationData);
    } else {
      mutate({
        ...data,
        tour_id: tourId,
        pricing_id: selectedPricingId,
        number_of_children: getValues("number_of_children"),
        additional_notes: getValues("additional_notes"),
      });
    }
  };

  return (
    <Form title={`From ${title}`} onSubmit={handleSubmit(submit)}>
      {children}
      {inquire && (
        <>
          {errors.destination_id && (
            <ErrorText>{errors.destination_id.message}</ErrorText>
          )}
          {/* <Form.TextController
        placeholder="Name"
        selectPlaceholder="Title"
        options={["Mr.", "Ms.", "Mrs."]}
        register={register}
        registername="name"
      /> */}

          <Form.TextController
            placeholder="Email"
            register={register}
            registername="email"
          />
          {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
          {/* {cruise && (
        <Form.SelectController placeholder="Select cruise" options={cruise} />
      )} */}
          <Form.SelectController
            placeholder="Select your Nationality"
            options={[
              "Afghan",
              "Albanian",
              "Algerian",
              "American",
              "Andorran",
              "Angolan",
              "Argentinian",
              "Armenian",
              "Australian",
              "Austrian",
              "Azerbaijani",
              "Bahamian",
              "Bahraini",
              "Bangladeshi",
              "Barbadian",
              "Belarusian",
              "Belgian",
              "Belizean",
              "Beninese",
              "Bhutanese",
              "Bolivian",
              "Bosnian",
              "Botswanan",
              "Brazilian",
              "British",
              "Bruneian",
              "Bulgarian",
              "Burkinabe",
              "Burmese",
              "Burundian",
              "Cambodian",
              "Cameroonian",
              "Canadian",
              "Cape Verdean",
              "Central African",
              "Chadian",
              "Chilean",
              "Chinese",
              "Colombian",
              "Comoran",
              "Congolese (Congo-Brazzaville)",
              "Congolese (Congo-Kinshasa)",
              "Costa Rican",
              "Croatian",
              "Cuban",
              "Cypriot",
              "Czech",
              "Danish",
              "Djiboutian",
              "Dominican",
              "Dutch",
              "East Timorese",
              "Ecuadorian",
              "Egyptian",
              "Emirati",
              "English",
              "Equatorial Guinean",
              "Eritrean",
              "Estonian",
              "Ethiopian",
              "Fijian",
              "Finnish",
              "French",
              "Gabonese",
              "Gambian",
              "Georgian",
              "German",
              "Ghanaian",
              "Greek",
              "Grenadian",
              "Guatemalan",
              "Guinean",
              "Guinea-Bissauan",
              "Guyanese",
              "Haitian",
              "Honduran",
              "Hungarian",
              "Icelandic",
              "Indian",
              "Indonesian",
              "Iranian",
              "Iraqi",
              "Irish",
              "Israeli",
              "Italian",
              "Ivorian",
              "Jamaican",
              "Japanese",
              "Jordanian",
              "Kazakh",
              "Kenyan",
              "Kiribati",
              "Kuwaiti",
              "Kyrgyz",
              "Lao",
              "Latvian",
              "Lebanese",
              "Liberian",
              "Libyan",
              "Liechtenstein",
              "Lithuanian",
              "Luxembourgish",
              "Macedonian",
              "Malagasy",
              "Malawian",
              "Malaysian",
              "Maldivian",
              "Malian",
              "Maltese",
              "Marshallese",
              "Mauritanian",
              "Mauritian",
              "Mexican",
              "Micronesian",
              "Moldovan",
              "Monegasque",
              "Mongolian",
              "Montenegrin",
              "Moroccan",
              "Mozambican",
              "Namibian",
              "Nauruan",
              "Nepalese",
              "New Zealander",
              "Nicaraguan",
              "Nigerian",
              "Nigerien",
              "North Korean",
              "Norwegian",
              "Omani",
              "Pakistani",
              "Palauan",
              "Palestinian",
              "Panamanian",
              "Papua New Guinean",
              "Paraguayan",
              "Peruvian",
              "Philippine",
              "Polish",
              "Portuguese",
              "Qatari",
              "Romanian",
              "Russian",
              "Rwandan",
              "Saint Lucian",
              "Salvadoran",
              "Samoan",
              "San Marinese",
              "Sao Tomean",
              "Saudi Arabian",
              "Scottish",
              "Senegalese",
              "Serbian",
              "Seychellois",
              "Sierra Leonean",
              "Singaporean",
              "Slovak",
              "Slovenian",
              "Solomon Islander",
              "Somali",
              "South African",
              "South Korean",
              "Spanish",
              "Sri Lankan",
              "Sudanese",
              "Surinamese",
              "Swazi",
              "Swedish",
              "Swiss",
              "Syrian",
              "Taiwanese",
              "Tajik",
              "Tanzanian",
              "Thai",
              "Togolese",
              "Tongan",
              "Trinidadian/Tobagonian",
              "Tunisian",
              "Turkish",
              "Turkmen",
              "Tuvaluan",
              "Ugandan",
              "Ukrainian",
              "Uruguayan",
              "Uzbek",
              "Vanuatuan",
              "Vatican citizen",
              "Venezuelan",
              "Vietnamese",
              "Welsh",
              "Yemeni",
              "Zambian",
              "Zimbabwean",
            ]}
            onChange={(e) => {
              setValue("nationality", e.target.value);
            }}
          />
          {errors.nationality && (
            <ErrorText>{errors.nationality.message}</ErrorText>
          )}
          <Form.TextController
            placeholder="Mobile"
            selectPlaceholder="Code"
            register={register}
            registername="contact_number"
            options={countryCodes.map((item) => `${item.name} (${item.code})`)}
            values={countryCodes.map((item) => item.code)}
            setValue={setValue}
          />
          {errors.contact_number && (
            <ErrorText>{errors.contact_number.message}</ErrorText>
          )}
        </>
      )}
      {!inquire && (
        <>
          <Form.DateTimeController
            label="Reservation Date"
            register={register}
            registername="reservation_date"
          />
          {errors.reservation_date && (
            <ErrorText>{errors.reservation_date.message}</ErrorText>
          )}
        </>
      )}
      {inquire && (
        <div className={style.formSubContainer}>
          <Form.DateTimeController
            label="Start date"
            defaultValue={
              inquireData?.from_date || getValues("from_date") || ""
            }
            register={register}
            registername="from_date"
          />
          <Form.DateTimeController
            label="End date"
            register={register}
            registername="to_date"
          />
        </div>
      )}
      {inquire && (
        <div className={style.formSubContainer}>
          {errors.from_date ? (
            <ErrorText>{errors.from_date.message}</ErrorText>
          ) : (
            <div></div>
          )}
          {errors.to_date ? (
            <ErrorText>{errors.to_date.message}</ErrorText>
          ) : (
            <></>
          )}
        </div>
      )}

      <div
        style={{
          border: "1px solid var(--second-color)",
          borderWidth: "1px 0px",
          padding: "15px 0px",
          margin: "15px 0px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Form.CounterController
          initialValue={2}
          label={"No. of Adults"}
          helperText={"( + 12 years )"}
          minValue={1}
          setValue={setValue}
          registername={inquire ? "adult_count" : "number_of_adults"}
        />
        <Form.CounterController
          initialValue={0}
          label={"No. of Children"}
          helperText={"( 2 to 11 years )"}
          minValue={0}
          setValue={setValue}
          registername={inquire ? "child_count" : "number_of_children"}
        />
        <Form.CounterController
          initialValue={0}
          label={"No. of Infants"}
          helperText={"( 0 to 2 years )"}
          minValue={0}
          setValue={setValue}
          registername={inquire ? "baby_count" : "number_of_infants"}
        />
      </div>
      <Form.TextareaController
        register={register}
        registername="additional_notes"
        placeholder="Please advise your tour requirements"
      />

      <label>Select Pricing</label>
      <select
        {...register("pricing_id", {
          required: "Please select a pricing option",
        })}
        onChange={(e) => setValue("pricing_id", e.target.value)}
        defaultValue=""
      >
        <option value="" disabled>
          Select a Pricing Option
        </option>
        {pricings?.data?.data?.data?.map((price) => (
          <option key={price.id} value={price?.id}>
            {price.name}
          </option>
        ))}
      </select>

      {errors.pricing_id && <ErrorText>{errors.pricing_id.message}</ErrorText>}

      <Form.ButtonController
        type="submit"
        disabled={isPending || inquirePending}
      >
        Submit
      </Form.ButtonController>
    </Form>
  );
};

const OverviewCard = ({ icon, text, subText, details }) => {
  return (
    <div className={style.cardContainer}>
      <div className={style.icon}>{icon}</div>
      <p>{text}</p>
      <h3>{subText}</h3>
      <p>{details}</p>
    </div>
  );
};

const ReviewsSection = ({ reviews }) => {
  const [expandReviews, setExpandReviews] = useState(false);
  return (
    <div className={style.reviewsContainer}>
      <div>
        <div className={style.mainReview}>
          <h1>5</h1>
          <div className={style.ratingStart} title="Rated 5 out of 5">
            <span style={{ width: "100%" }}></span>
          </div>
          <p>(6 Reviews)</p>
        </div>
        <div>
          <RatePrecentage
            totalReviews={6}
            reviews={{ five: 6, four: 4, three: 2, two: 1, one: 0 }}
          />
        </div>
      </div>
      <div>
        {reviews.slice(0, expandReviews ? reviews.length : 3).map((review) => (
          <ReviewCard review={review} />
        ))}
        <button type="button" onClick={() => setExpandReviews(!expandReviews)}>
          {expandReviews ? "See less" : "See all"}
        </button>
      </div>
    </div>
  );
};

const RatePrecentage = ({ totalReviews, reviews }) => {
  return (
    <div className={style.ratePrecentageContainer}>
      <div>
        <p>5 Stars</p>
        <div className={style.rate}>
          <div
            style={{ width: `${(reviews.five / totalReviews) * 100}%` }}
          ></div>
        </div>
        <p>{reviews.five}</p>
      </div>
      <div>
        <p>4 Stars</p>
        <div className={style.rate}>
          <div
            style={{ width: `${(reviews.four / totalReviews) * 100}%` }}
          ></div>
        </div>
        <p>{reviews.four}</p>
      </div>
      <div>
        <p>3 Stars</p>
        <div className={style.rate}>
          <div
            style={{ width: `${(reviews.three / totalReviews) * 100}%` }}
          ></div>
        </div>
        <p>{reviews.three}</p>
      </div>
      <div>
        <p>2 Stars</p>
        <div className={style.rate}>
          <div
            style={{ width: `${(reviews.two / totalReviews) * 100}%` }}
          ></div>
        </div>
        <p>{reviews.two}</p>
      </div>
      <div>
        <p>1 Stars</p>
        <div className={style.rate}>
          <div
            style={{ width: `${(reviews.one / totalReviews) * 100}%` }}
          ></div>
        </div>
        <p>{reviews.one}</p>
      </div>
    </div>
  );
};

const ReviewCard = ({ review }) => {
  const { rating, title, userName, date, comment } = review;
  return (
    <div className={style.reviewCardContainer}>
      <div className={style.ratingStart} title="Rated 5 out of 5">
        <span style={{ width: rating }}></span>
      </div>
      <h3>{title}</h3>
      <div className={style.userData}>
        <p>{userName}</p>
        <p>{date}</p>
      </div>
      <p>{comment}</p>
    </div>
  );
};
