import IneerBanner from "../components/IneerBanner";
import SectionHeading from "../components/AboutComponents/SectionHeading";
import AboutService from "../components/AboutComponents/AboutService";
import AboutVideo from "../components/AboutComponents/AboutVideo";
import ClientSection from "../components/AboutComponents/ClientSection";
import CallbackSection from "../components/AboutComponents/CallbackSection";

import icon15 from "../images/icon15.png";
import icon16 from "../images/icon16.png";
import icon17 from "../images/icon17.png";
import img25 from "../images/img25.jpg";

const services = [
  {
    icon: icon15,
    title: "AFFORDABLE PRICE",
    description:
      "Discover Egypt with our budget-friendly travel packages, offering modern transport, expert guides, and 24/7 support.",
  },
  {
    icon: icon16,
    title: "BEST DESTINATION",
    description:
      "Discover the beauty of Egypt with our customizable travel packages. Enjoy top-notch services and unforgettable experiences, all within your budget.",
  },
  {
    icon: icon17,
    title: "PERSONAL SERVICE",
    description:
      "Enjoy personalized travel experiences with expert guides and 24/7 support.",
  },
];

const About = () => {
  return (
    <>
      <IneerBanner bannerName={"About us"} />
      <section className="about-section about-page-section">
        <div className="about-service-wrap">
          <div className="container">
            <SectionHeading
              heading="Why Album Travel"
              subheading="We are committed to curate and flawlessly execute travel experiences."
              description1="Established in 2006, Album Travel started its journey with just one goal: to offer remarkable travel experiences that help people create unforgettable memories that will last forever. Since then, Album Travel has made it its mission to bring curious travelers from all over the world to experience, "
              description2="connect and explore the beauty of the Middle East. Initially established to cater to the travelers in the Far East region, we have successfully expanded our travel packages to encompass the global market. Our exceptional attention to detail, unrivaled expertise, and unwavering commitment to customer satisfaction make us the preferred choice for those seeking extraordinary journeys."
            />
            <div className="about-service-container">
              <div className="row">
                {services.map((service, index) => (
                  <div className="col-lg-4" key={index}>
                    <AboutService {...service} />
                  </div>
                ))}
              </div>
            </div>
            <AboutVideo
              backgroundImage={img25}
              videoId="IUN664s7N-c"
              videoUrl={""}
            />
          </div>
        </div>
        <ClientSection />
        <CallbackSection />
      </section>
    </>
  );
};

export default About;
