import { useEffect, useRef, useState } from "react";
import { RiImageAddFill } from "react-icons/ri";
import { IoCloseCircle } from "react-icons/io5";
import { api_url } from "../../../../constants/base_url";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { notifyError, notifySuccess } from "../../../../App";

const EditTourImages = ({ data, register, registername, errors ,lang}) => {
  const inputRef = useRef();
  const [selectedImages, setSelectedImages] = useState([]);
  const [subCardData, setSubCardData] = useState(null); // Store the matching object
  const [cardIndex, setCardIndex] = useState(null);


  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImages((prev) => [...prev, ...files]);
  };

  const handleRemoveImage = (index) => {
    setSelectedImages((prev) => prev.filter((_, i) => i !== index));
  };

  const handleRemoveImageSubCard = (index) => {
    const formData = new FormData();
    if (subCardData) {
      formData.append("blogLanguageId", lang);
      formData.append( '_method', "PUT")
      formData.append( 'index', cardIndex)
      formData.append(`Delete`,index); 
      mutate(formData)
    }
  };

const { mutate,  isPending } = useMutation({
  mutationFn: (data) => {
    const url = subCardData
    ? `${api_url}blogLanguage/updateSubCard`
    : `${api_url}blogLanguage/subCard`;

  return axios.post(url, data, {
    headers: {
      "Accept": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
},
    onSuccess: (data) => {
        console.log("sub card add successful", data);
        notifySuccess("sub card add successful")
        refetch()

    },
    onError: (error) => {
        console.error("add lang failed:", error);
        notifyError(error?.response?.data?.message);
    }
});

  const { data:subCard, isLoading ,refetch} = useQuery({
    queryKey: ["subCard", lang],
    queryFn: () =>
      axios.get(`${api_url}blogLanguage/${lang}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
  });
 

  useEffect(() => {
    if (subCard?.data?.data) {
      Object.entries(subCard.data.data).forEach(([key, value], index) => {
        if (key.startsWith('sub_') && value?.title === 'images') {
          setSubCardData(value); // Set the matching object in state
          setCardIndex(key.slice(-1)); // Set the index in state
        }
      });
    }
  }, [subCard, isLoading]);


  const handleUpload = () => {
    const formData = new FormData();
   
    formData.append("blogLanguageId", lang); // Add any other necessary fields
    formData.append("title", "images");

    if (subCardData) {
      formData.append( '_method', "PUT")
      formData.append( 'index', cardIndex)
      selectedImages.forEach((file, index) => {
        formData.append(`images[${subCardData?.image?.length}]`, file); // Append each image with dynamic key
      });
    }else{
      selectedImages.forEach((file, index) => {
        formData.append(`images[${index}]`, file); // Append each image with dynamic key
      });
    }

    mutate(formData); // Send the formData via mutate
  };
  

//   useEffect(() => {
//       register(registername, {
//           require: "Image file is required",
//       });
//   }, [register, registername]);


 console.log(selectedImages,'selectedImages');
 console.log(subCardData?.image?.length,'subCardData?.image');
  return (
    <>
    
       {/* Render Uploaded Images */}
       {subCardData?.image?.map((imgUrl, index) => (
        <span key={`uploaded-${index}`}>
          <IoCloseCircle
            title="Remove Image"
            onClick={() => handleRemoveImageSubCard(index)} // Optional: Handle removing uploaded images
          />
          <img src={`${api_url}${imgUrl}`.replace("/api/", "/storage/")} alt={`Uploaded Preview ${index}`} />
        </span>
      ))}

      {/* Render Selected Images */}
      {selectedImages.map((image, index) => (
        <span key={`selected-${index}`}>
          <IoCloseCircle
            title="Remove Image"
            onClick={() => handleRemoveImage(index, true)}
          />
          <img src={URL.createObjectURL(image)} alt={`Selected Preview ${index}`} />
        </span>
      ))}

      <input
        type="file"
        onChange={handleFileChange}
        hidden
        ref={inputRef}
        accept="image/png, image/jpeg, image/jpg, image/gif"
        multiple // Allow multiple file selection
      />
      <button type="button" onClick={() => inputRef.current.click()}>
        <RiImageAddFill />
      </button>
      <button type="button" onClick={handleUpload} disabled={isPending}>
        {isPending ? "Uploading..." : "Upload Images"}
      </button>
    </>
  );
};

export default EditTourImages;
