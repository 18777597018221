import IneerBanner from "../components/IneerBanner";
import ContactSection from "../components/ContactComponents/ContactSection";

export default function Contact() {
  return (
    <>
      <IneerBanner bannerName={"Contact us"} />
      <ContactSection />
    </>
  );
}
