import { useEffect, useState } from 'react';
import PageHeader from './../../components/pageHeader/pageHeader';
import CustomButton from '../../components/customButton/customButton';
import { FaChevronLeft, FaXmark } from 'react-icons/fa6';
import Table from './../../components/customTable/table';

import bookingStyle from "./dashboardBookingAndEnquiry.module.css"
import "../../dashboardIndex.css"
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { api_url } from '../../../constants/base_url';
import ReactPaginate from 'react-paginate';
import {FaAngleDoubleLeft, FaAngleDoubleRight} from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import { notifyError, notifySuccess } from '../../../App';


const DashboardBookingAndEnquiry = () => {
  const status = {
    APPROVE: "accepted",
    PENDING: "pending",
    REJECT: "rejected"
  }
  
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState('all');
  const [showDropdown, setShowDropdown] = useState(false);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setShowDropdown(false);
  };
  const {data: inquiries, refetch} = useQuery({
    queryKey: ["allInquiries",page, filter],
    queryFn: () => axios.get(`${api_url}inquiries?page=${page}${filter === "seen" ? "&is_seen=1": filter === "unseen"?"&is_seen=0":""}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      }
    })
  })

  const {mutate, isPending} = useMutation({
    mutationFn: (data) => axios.patch(`${api_url}inquiries/${data.id}`, {
      status: data.status
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      }
    }),
    onSuccess: () => {
      notifySuccess("Inquiry updated successfully")
      refetch()
    },
    onError: () => {
      notifyError("Something went wrong")
    }
  })

  useEffect(() => {
    if(inquiries?.data?.data){
      setTotalPages(inquiries?.data?.data?.last_page)
    }
  },[inquiries])

  return (
    <main className={bookingStyle.mainContainer}>
      <PageHeader>Inquires</PageHeader>
      <div style={{display:"flex", padding:"20px 0", alignItems:"center", justifyContent:"space-between"}}>
        <ReactPaginate 
          onPageChange={(selectedItem) => {
            setPage(selectedItem.selected + 1); // Update the page state with the selected page number
          }}
          pageCount={totalPages}
          containerClassName='pagination'
          pageClassName='page-item'
          pageLinkClassName='page-link'
          nextClassName='page-item'
          nextLinkClassName='page-link'
          previousClassName='page-item'
          previousLinkClassName='page-link'
          breakClassName='page-item'
          breakLinkClassName='disabled page-link'
          activeClassName='active'
          nextLabel={<FaAngleDoubleRight />}
          previousLabel={<FaAngleDoubleLeft />}
        />
        <div style={{position:"relative", textTransform:"capitalize"}} onClick={() => {
            setShowDropdown(!showDropdown)
          }} className={bookingStyle.buttons}>
          <ul style={{ display: showDropdown ? 'block' : 'none', position:"absolute", top:-10, left:"-150px", backgroundColor:"white", zIndex:100 }}>
            <li style={{cursor:"pointer"}} className="dropdown-item" onClick={() => handleFilterChange('all')}>All</li>
            <li style={{cursor:"pointer"}} className="dropdown-item" onClick={() => handleFilterChange('seen')}>Seen</li>
            <li style={{cursor:"pointer"}} className="dropdown-item" onClick={() => handleFilterChange('unseen')}>Unseen</li>
          </ul>
          <CustomButton icon={<FaChevronLeft />} >{filter}</CustomButton>
        </div>
      </div>
      <Table className={bookingStyle.usersTable}>
        <Table.HeadController>
          <th>Email</th>
          <th>Phone</th>
          <th>Nationality</th>
          <th>Destination</th>
          <th>From</th>
          <th>To</th>
          <th>Number of adults</th>
          <th>Number of children</th>
          <th>Number of babies</th>
          <th>Status</th>
          <th>Controls</th>
        </Table.HeadController>
        <Table.BodyController>
          {inquiries?.data?.data?.data?.length > 0 ? (
            inquiries.data.data.data.map(inquiry => (
              <tr key={inquiry.id}>
                <td>{inquiry.email}</td>
                <td>{inquiry.contact_number}</td>
                <td>{inquiry.nationality}</td>
                <td>{inquiry.destination_name}</td>
                <td>{inquiry.from_date}</td>
                <td>{inquiry.to_date}</td>
                <td>{inquiry.adult_count}</td>
                <td>{inquiry.child_count}</td>
                <td>{inquiry.baby_count}</td>
                <td><span className={`${inquiry.status === status.APPROVE ? bookingStyle.approve :
                  inquiry.status === status.PENDING ? bookingStyle.pending :
                  bookingStyle.reject}`}>{inquiry.status}</span></td>
                <td style={{display:"flex", alignItems:"center", justifyContent:"center", padding:"10px 0", gap:"20px"}} className="transitionAnimation">
                  <button onClick={() => {
                    mutate({
                      id: inquiry.id,
                      status: status.APPROVE
                    })
                  }} disabled={isPending}><FaCheck /></button>
                  <button onClick={() => {
                    mutate({
                      id: inquiry.id,
                      status: status.REJECT
                    })
                  }} disabled={isPending}><FaXmark /></button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="11" style={{ textAlign: 'center', fontSize: '1.6rem' }}>No data available</td>
            </tr>
          )}
        </Table.BodyController>
      </Table>
    </main>
  )
}

export default DashboardBookingAndEnquiry