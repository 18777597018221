import { Link } from 'react-router-dom'

const DistiItem = ({imgSrc, country, content,id}) => {
    return (
        <Link to={`/tours/destination/${id}`}>

        <div className="desti-item overlay-desti-item">
            <figure className="desti-image">
                <img src={imgSrc} alt={country}/>
            </figure>
            <div className="meta-cat bg-meta-cat">
                <a>{country}</a>
            </div>

            {/* <div className="desti-content">
                <h3>
                    <a href="#">{content}</a>
                </h3>
                <div className="rating-start" title="Rated 5 out of 4">
                    <span style={{width: '53%'}}></span>
                </div>
            </div> */}
        </div>
        </Link>
    )
}

export default DistiItem