import { useEffect, useState } from 'react';
import home from "../dashboard.module.css"
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { api_url } from '../../../../constants/base_url';
import CustomParagraph from '../../../components/customParagraph/customParagraph';
import { ImBlog } from "react-icons/im";
import { FaCalendarCheck } from "react-icons/fa";
import { MdTour } from "react-icons/md";
import { PiTranslateFill } from "react-icons/pi";
import { RiUserLocationFill } from "react-icons/ri";
import { BiSolidDetail } from "react-icons/bi";





const months = [
    { name: "January", value: "01" },
    { name: "February", value: "02" },
    { name: "March", value: "03" },
    { name: "April", value: "04" },
    { name: "May", value: "05" },
    { name: "June", value: "06" },
    { name: "July", value: "07" },
    { name: "August", value: "08" },
    { name: "September", value: "09" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" }
];

const icons = {
  blogs: <ImBlog />,
  reservations: <FaCalendarCheck />,
  tours: <MdTour />,
  translations: <PiTranslateFill />,
  customers: <RiUserLocationFill />,
};
const DashboardStats = () => {
    const [years, setYears] = useState([]);

    const [selectedDate, setSelectedDate] = useState(
        `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}`
    );
    

    useEffect(() => {
        // Get the current year
        const currentYears = [new Date().getFullYear().toString()];
        // Fill the years array with the current year and all previous years until 2020
        for (let year = currentYears[0] - 1; year >= 2020; year--) {
            currentYears.push(year.toString());
        }
        setYears(currentYears);
    },[])
    
    const {data, isPending} = useQuery({
        queryKey:["stats"],
        queryFn: () => axios.get(`${api_url}stats`,{
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        }),
    })
    console.log(data)
  return (
    data && <div style={{margin:"10px 0", height:"fit-content"}}>
        <div className={home.heading}>
            <h3>Stats</h3>
        </div>
        <div style={{display:"flex", alignItems:"center", justifyContent:"center", gap:"10px", marginBottom:"10px"}}>
        <select
            onChange={(e) => {
                setSelectedDate((prevValue) => {
                    const vals = prevValue.split("-");
                    vals[0] = e.target.value; // Update the year
                    return vals.join("-");
                });
            }}
            style={{width:"50%"}}
        >
            {years.map((year, index) => (
                <option value={year} key={index}>{year}</option>
            ))}
        </select>

        <select
            onChange={(e) => {
                setSelectedDate((prevValue) => {
                    const vals = prevValue.split("-");
                    vals[1] = e.target.value; // Update the month
                    return vals.join("-");
                });
            }}
            style={{width:"50%"}}
        >
            {months.map(({ name, value }, index) => (
                <option value={value} key={index} selected={String(new Date().getMonth() + 1).padStart(2, '0') === value}>{name}</option>
            ))}
        </select>
        </div>
        <section className={home.viewSection}>
        {isPending ? "" : data && 
            Object.keys(data?.data).map((key) => {
                const stat = data?.data?.[key].find((x) => x.month === selectedDate);
                return (
                    <div key={key}>
                        <div className={home.icon}>{icons[key] || <BiSolidDetail />}</div>
                        <div>
                            <CustomParagraph>{key}</CustomParagraph>
                            <p>{stat?.count || "0"}</p>
                        </div>
                    </div>
                );
            })
        }

      </section>
    </div>
  )
}



export default DashboardStats