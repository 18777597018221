import IneerBanner from "../components/IneerBanner";
import SingleTour from "../components/PackageDetailComponents/SingleTour";
import SubscribeSection from "../components/subscribeSection";

export default function PackageDetail() {
  return (
    <>
      <IneerBanner bannerName={"Package Detail"} />
      <SingleTour />
      <SubscribeSection />
    </>
  );
}
