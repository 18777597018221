import { Link } from 'react-router-dom'

const DestinationCard = ({imgSrc,title,desc,id}) => {
    return (
        <>
            <div class="col-md-4">
                <div class="desti-item overlay-desti-item">
                    <figure class="desti-image">
                        <img src={imgSrc} alt={title} />
                    </figure>
                    <div class="meta-cat bg-meta-cat">
                        <div class="desti-content">
                            <h3>
                                <Link to={`/tours/destination/${id}`}>{title}</Link>
                            </h3>
                            <p>{desc}</p>
                        </div>
                        <Link to={`/tours/destination/${id}`}>See rewards</Link>
                    </div>

                </div>
            </div>
        </>
    )
}

export default DestinationCard