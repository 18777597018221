import { Tab, Tabs } from "react-bootstrap";

const TabContainer = () => {
  return (
    <Tabs
      defaultActiveKey="DESCRIPTION"
      id="uncontrolled-tab-example"
      className="mb-3 tab-container"
    >
      <Tab eventKey="DESCRIPTION" title="DESCRIPTION" className="nav nav-tabs ">
        <div class="overview-content">
          <p>
            Occaecat pariatur! Quaerat ligula, ab, consequuntur orci mus
            ultricies praesent aute blandit beatae nisl aut, totam mauris
            rhoncus? Tellus netus fringilla class auctor dui. Dolores excepteur,
            doloribus, blanditiis aliquip nisl. Occaecat iusto? Provident sociis
            rerum. Amet, asperiores molestie varius eos! Libero, fermentum
            fermentum totam! Sunt praesentium, totam. Excepteur platea nisl.
            Convallis aliquam? Iaculis erat ipsa molestie, quod, vestibulum
            reiciendis, maxime nostra, integer unde officiis quo integer unde
            officiis quo.
          </p>
          <p>
            Occaecat pariatur! Quaerat ligula, ab, consequuntur orci mus
            ultricies praesent aute blandit beatae nisl aut, totam mauris
            rhoncus? Tellus netus fringilla class auctor dui. Dolores excepteur,
            doloribus, blanditiis aliquip nisl..
          </p>
          <ul>
            <li>- Travel cancellation insurance</li>
            <li>- Breakfast and dinner included</li>
            <li>- Health care included</li>
            <li>- Transfer to the airport and return to the agency</li>
            <li>- Lorem ipsum dolor sit amet, consectetur adipiscing</li>
          </ul>
        </div>
      </Tab>
      <Tab eventKey="PROGRAM" title="PROGRAM" className="nav nav-tabs">
        <div className="itinerary-content">
          <h3>
            Program <span>( 4 days )</span>
          </h3>
          <p>
            Dolores maiores dicta dolore. Natoque placeat libero sunt sagittis
            debitis? Egestas non non qui quos, semper aperiam lacinia eum nam!
            Pede beatae. Soluta, convallis irure accusamus voluptatum ornare
            saepe cupidatat.
          </p>
        </div>
        <div className="itinerary-timeline-wrap">
          <ul>
            <li>
              <div className="timeline-content">
                <div className="day-count">
                  Day <span>1</span>
                </div>
                <h4>Ancient Rome Visit</h4>
                <p>
                  Nostra semper ultricies eu leo eros orci porta provident,
                  fugit? Pariatur interdum assumenda, qui aliquip ipsa! Dictum
                  natus potenti pretium.
                </p>
              </div>
            </li>
            <li>
              <div className="timeline-content">
                <div className="day-count">
                  Day <span>2</span>
                </div>
                <h4>Classic Rome Sightseeing</h4>
                <p>
                  Nostra semper ultricies eu leo eros orci porta provident,
                  fugit? Pariatur interdum assumenda, qui aliquip ipsa! Dictum
                  natus potenti pretium.
                </p>
              </div>
            </li>
            <li>
              <div className="timeline-content">
                <div className="day-count">
                  Day <span>3</span>
                </div>
                <h4>Vatican City Visit</h4>
                <p>
                  Nostra semper ultricies eu leo eros orci porta provident,
                  fugit? Pariatur interdum assumenda, qui aliquip ipsa! Dictum
                  natus potenti pretium.
                </p>
              </div>
            </li>
            <li>
              <div className="timeline-content">
                <div className="day-count">
                  Day <span>4</span>
                </div>
                <h4>Italian Food Tour</h4>
                <p>
                  Nostra semper ultricies eu leo eros orci porta provident,
                  fugit? Pariatur interdum assumenda, qui aliquip ipsa! Dictum
                  natus potenti pretium.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </Tab>
      <Tab eventKey="REVIEW" title="REVIEW" className="nav nav-tabs ">
        <div className="summary-review ">
          <div className="review-score">
            <span>4.9</span>
          </div>
          <div className="review-score-content">
            <h3>
              Excellent
              <span>( Based on 24 reviews )</span>
            </h3>
            <p>
              Tincidunt iaculis pede mus lobortis hendrerit eveniet impedit
              aenean mauris qui, pharetra rem doloremque laboris euismod
              deserunt non, cupiditate, vestibulum.
            </p>
          </div>
          {/* Review comment area */}
          <div className="comment-area">
            <h3 className="comment-title">3 Reviews</h3>
            <div className="comment-area-inner">
              <ol>
                {/* First review */}
                <li>
                  <div className="comment-content">
                    <div className="comment-header">
                      <h5 className="author-name">Tom Sawyer</h5>
                      <span className="post-on">Jana 10 2020</span>
                      <div className="rating-wrap">
                        <div className="rating-start" title="Rated 5 out of 5">
                          <span style={{ width: "90%" }}></span>
                        </div>
                      </div>
                    </div>
                    <p>
                      Officia amet posuere voluptates, mollit montes eaque
                      accusamus laboriosam quisque cupidatat dolor pariatur,
                      pariatur auctor.
                    </p>
                    <a href="#" className="reply">
                      <i className="fas fa-reply"></i>Reply
                    </a>
                  </div>
                </li>
                {/* Second review */}
                <li>
                  <ol>
                    <li>
                      <div className="comment-content">
                        <div className="comment-header">
                          <h5 className="author-name">John Doe</h5>
                          <span className="post-on">Jana 10 2020</span>
                          <div className="rating-wrap">
                            <div
                              className="rating-start"
                              title="Rated 5 out of 5"
                            >
                              <span style={{ width: "90%" }}></span>
                            </div>
                          </div>
                        </div>
                        <p>
                          Officia amet posuere voluptates, mollit montes eaque
                          accusamus laboriosam quisque cupidatat dolor pariatur,
                          pariatur auctor.
                        </p>
                        <a href="#" className="reply">
                          <i className="fas fa-reply"></i>Reply
                        </a>
                      </div>
                    </li>
                  </ol>
                </li>
                {/* Third review */}
                <li>
                  <div className="comment-content">
                    <div className="comment-header">
                      <h5 className="author-name">Jaan Smith</h5>
                      <span className="post-on">Jana 10 2020</span>
                      <div className="rating-wrap">
                        <div className="rating-start" title="Rated 5 out of 5">
                          <span></span>
                        </div>
                      </div>
                    </div>
                    <p>
                      Officia amet posuere voluptates, mollit montes eaque
                      accusamus laboriosam quisque cupidatat dolor pariatur,
                      pariatur auctor.
                    </p>
                    <a href="#" className="reply">
                      <i className="fas fa-reply"></i>Reply
                    </a>
                  </div>
                </li>
              </ol>
            </div>
            {/* Review form */}
            <div className="comment-form-wrap">
              <h3 className="comment-title">Leave a Review</h3>
              <form className="comment-form">
                <div className="full-width rate-wrap">
                  <label>Your rating</label>
                  <div className="procduct-rate">
                    <span></span>
                  </div>
                </div>
                <p>
                  <input type="text" name="name" placeholder="Name" />
                </p>
                <p>
                  <input type="text" name="name" placeholder="Last name" />
                </p>
                <p>
                  <input type="email" name="email" placeholder="Email" />
                </p>
                <p>
                  <input type="text" name="subject" placeholder="Subject" />
                </p>
                <p>
                  <textarea rows="6" placeholder="Your review"></textarea>
                </p>
                <p>
                  <input type="submit" name="submit" value="Submit" />
                </p>
              </form>
            </div>
          </div>
        </div>
      </Tab>
      <Tab eventKey="Map" title="Map" className="nav nav-tabs">
        <div className="map-area">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60998820.06503915!2d95.3386452160086!3d-21.069765827214972!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2b2bfd076787c5df%3A0x538267a1955b1352!2sAustralia!5e0!3m2!1sen!2snp!4v1579777829477!5m2!1sen!2snp"
            height="450"
            allowfullscreen=""
          ></iframe>
        </div>
      </Tab>
    </Tabs>
  );
};

export default TabContainer;
