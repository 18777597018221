import { useState } from 'react';
import style from "./usersTableRow.module.css";
import "../../dashboardIndex.css";

import { BsPencilSquare, BsTrashFill } from "react-icons/bs";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { api_url } from '../../../constants/base_url';
import { notifySuccess, notifyError } from '../../../App';
import Modal from '../../../components/shared/Modal/Modal';
import EditUserForm from './editUserData/EditUserForm';

const UsersTableRow = ({
    id,
    userName,
    phone,
    email,
}) => {

    const queryClient = useQueryClient()
    const {mutate:deleteUser, isPending} = useMutation({
        mutationFn: (data) => axios.delete(`${api_url}users/${id}`,data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        }),
        onSuccess: () => {
            notifySuccess("User deleted successfully!")
            queryClient.invalidateQueries(["allUsers"])
        },
        onError: () => {
            notifyError("Something went wrong")
        }
    })
    const [open, setOpen] = useState(false);
    return (
        <tr className={style.row}>
            
            <td>{userName || "-"}</td>
            <td>{phone || "-"}</td>
            <td>{email || "-"}</td>
            
            <td className="transitionAnimation" onClick={() => {
                setOpen(true)
            }}><BsPencilSquare /></td>
            <td className="transitionAnimation" onClick={() => {
                !isPending && deleteUser()
            }}><BsTrashFill /></td>
            <Modal isAppear={open} onClose={() => setOpen(false)}>
                <EditUserForm setOpen={setOpen} user={{name:userName, email, phone, id}} />
            </Modal>
        </tr>
    );
};

export default UsersTableRow;
