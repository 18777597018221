import SectionHeading from "./sectionheading";
import DestinationRow from './destinations/destinationRow';

const TopNotchDeals = () => {
    
    return (
        <>
            <SectionHeading title="POPULAR DESTINATION" rowAlignment="align-items-end" containerTextsStyle="col-lg-7">
                <h2>TOP NOTCH Deals</h2>
            </SectionHeading>

            <DestinationRow/>
        </>
    )
}

export default TopNotchDeals