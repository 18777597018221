import { useState } from "react";
import { Link } from "react-router-dom";
import { api_url } from "../../constants/base_url";
const PackageWrap = ({
  image,
  price,
  availability_days,
  number_of_people,
  location,
  title,
  number_of_reviews,
  rating,
  description,
  id,
  HasOffer
}) => {
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const getShortDescription = (text) => {
    return text.length > 200 ? text.substring(0, 200) + "..." : text;
  };

  return (
    <div className="package-wrap">
      <figure className="feature-image">
        <Link to={`/tours/${id}`}>
          <img
            src={`${api_url}${image}`.replace("/api/", "/")}
            alt={title}
            height={200}
            style={{ height: 350, objectFit: "cover" }}
          />
        </Link>
      </figure>
       {HasOffer?(
      <div className="package-price"  style={{ color: "#f1f1f1",display:"flex", justifyContent:"center",alignItems:"center" }}>

          <p style={{color:"white"}}>Discount</p>
      </div>
       ):null}
      <div className="package-content-wrap">
        <div className="package-meta text-center">
          <ul>
            <li>
              <i className="far fa-clock"></i>
              {availability_days}
            </li>
            <li>
              <i className="fas fa-user-friends"></i>
              People: {number_of_people}
            </li>
            <li>
              <i className="fas fa-map-marker-alt"></i>
              {location}
            </li>
          </ul>
        </div>
        <div className="package-content">
          <h3>
            <Link to={`/tours/${id}`}>{title}</Link>
          </h3>
          <div className="review-area">
            <span className="review-text">({number_of_reviews})</span>
            <div className="rating-start" title="Rated 5 out of 5">
              <span style={{ width: number_of_reviews }}></span>
            </div>
          </div>
          <p>
            {showFullDescription
              ? description
              : getShortDescription(description)}
            {description.length > 100 && (
              <button onClick={toggleDescription} className="toggle-button">
                {showFullDescription ? "See Less" : "See More"}
              </button>
            )}
          </p>
          <div className="btn-wrap">
            <Link
              to={`/tours/${id}`}
              className="button-text width-6"
            >
              Book Now<i className="fas fa-arrow-right"></i>
            </Link>
            <Link
              to={`/tours/${id}`}
              className="button-text width-6"
            >
              Wish List<i className="far fa-heart"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageWrap;
