import { Link } from 'react-router-dom';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    return formattedDate;
};

const PostThumb = ({ id, imgSrc, title, date, comments }) => {
    return (
        <li>
            <figure class="post-thumb">
                <a><img src={imgSrc} alt="" /></a>
            </figure>
            <div class="post-content">
                <h5>
                    <Link to={`/blog/${id}`}>{title}</Link>
                </h5>
                <div class="entry-meta">
                    <span class="posted-on">
                        <a href="#">{formatDate(date)}</a>
                    </span>
                    <span class="comments-link">
                        <a href="#">{comments}</a>
                    </span>
                </div>
            </div>
        </li>
    )
}

export default PostThumb