import { useEffect, useRef, useState } from 'react';
import PageHeader from './../../components/pageHeader/pageHeader';
import userStyle from "./dashboardUsers.module.css";
import "../../dashboardIndex.css";

import UsersTableRow from '../../components/usersTableRow/usersTableRow';
import Table from '../../components/customTable/table';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { api_url } from '../../../constants/base_url';
import { FaAngleDoubleRight, FaSearch } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import { FaAngleDoubleLeft } from "react-icons/fa";


const DashboardUsers = () => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const searchRef = useRef()
  const [searchKeyword, setSearchKeyword] = useState("")
  const isValidEmail = (email) => {
    // Regular expression to validate the email format
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  
  const isPhoneNumber = (keyword) => {
    // Regular expression to check if the keyword consists of only digits or starts with a "+"
    return /^[\d+]+$/.test(keyword);
  };
  
  const getQuerySearch = () => {
    if (searchKeyword) {
      // Check if the searchKeyword is a valid email or a phone number
      if (isValidEmail(searchKeyword)) {
        return `&email=${searchKeyword}`;
      } else if (isPhoneNumber(searchKeyword)) {
        return `&phone=${searchKeyword}`;
      }
    }
    return ''; // Return an empty string if searchKeyword is not valid
  };
  
  // Use the getQuerySearch function to construct the query URL
  const { data } = useQuery({
    queryKey: ["allUsers", page, searchKeyword],
    queryFn: () => axios.get(`${api_url}users?page=${page}${getQuerySearch()}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    }),
    retry:false
  });


  useEffect(() => {
    if(data?.data?.data){
      setTotalPages(data?.data?.data?.last_page)
    }
  },[data])

  return (
    <main className={userStyle.mainContainer}>
      <PageHeader>User Details</PageHeader>
      <div style={{ display: "flex", alignItems: "center", gap: "10px", width: "30%" }}>
        <input
          ref={searchRef}
          style={{ flexGrow: 1 }}
          type="text"
          placeholder="Search using email or phone"
          onChange={(e) => {
            if (!e.target.value) {
              setSearchKeyword("");
            }
          }}
        />
        <button
          onClick={() => {
            setSearchKeyword(searchRef?.current?.value);
          }}
          style={{
            width: "10%",
            flexGrow: 0,
            height: "40px",
            border: "none",
            outline: "none",
            fontSize: "1.2rem",
            backgroundColor: "#d51c29",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
          }}
        >
          <FaSearch />
        </button>
      </div>
      <div style={{ display: "flex", padding: "20px 0" }}>
        <ReactPaginate
          onPageChange={(selectedItem) => {
            setPage(selectedItem.selected + 1); // Update the page state with the selected page number
          }}
          pageCount={totalPages}
          containerClassName="pagination"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="disabled page-link"
          activeClassName="active"
          nextLabel={<FaAngleDoubleRight />}
          previousLabel={<FaAngleDoubleLeft />}
        />
      </div>
      <Table className={userStyle.usersTable}>
        <Table.HeadController>
          <th>Name</th>
          <th>Phone</th>
          <th>Email</th>
          <th>Edit</th>
          <th>Delete</th>
        </Table.HeadController>
        <Table.BodyController>
          {data?.data?.data?.data?.length > 0 ? (
            data.data.data.data.map((user) => (
              <UsersTableRow
                key={user.id}
                id={user.id}
                userName={user.name}
                phone={user.phone}
                email={user.email}
              />
            ))
          ) : (
            <tr>
              <td colSpan="5" style={{ textAlign: "center", fontSize:"1.6rem" }}>
                No data available
              </td>
            </tr>
          )}
        </Table.BodyController>
      </Table>
    </main>
  );
};

export default DashboardUsers;

